import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Dashboards',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboards/Dashboards.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reports/Reports.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/system_preferences',
    name: 'SystemPreferences',
    component: () => import(/* webpackChunkName: "about" */ '../views/SystemPreferences/SystemPreferences.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/user_roles',
    name: 'UserRoles',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserRoles/UserRoles.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import(/* webpackChunkName: "about" */ '../views/Rules/Rules.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/support_center',
    name: 'SupportCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/SupportCenter/SupportCenter.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  
  {
    path: '/super_admin',
    name: 'SuperAdmin',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuperAdmins/SuperAdmin.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  {
    path: '/multi_reservations',
    name: 'MultiReservations',
    component: () => import(/* webpackChunkName: "about" */ '../views/MultiReservations/MultiReservations.vue'),
    meta: {
      authRequired: 'true',
    },
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
