<template >
	<div class="layout-menu-container">
		<AppSubMenu :items="menuItems" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
	<div class="layout-menu-container">
		<AppSubMenu :items="downMenuItems" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
	</div>
</template>

<script>
import AppSubMenu from './AppSubMenu';
import { ref } from "vue";
import rolesJson from "./roles/roles.json";

const dashboardsLabel = ref("");
const reportsLabel = ref("");
const systemPreferencesLabel = ref("");
const rulesLabel = ref("");
const userRolesLabel = ref("");
const supportCenterLabel = ref("")
const superAdminLabel =  ref("")
const multiReservationsLabel = ref("")

export default {

	watch: {
		'$keycloak.token'() {
			this.menuItems = [];
			this.downMenuItems = [];
			if (rolesJson['dashboardReports'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: dashboardsLabel, icon: 'pi pi-fw pi-chart-bar', to: '/'}
					]
                });
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: reportsLabel, icon: 'pi pi-fw pi-file', to: '/reports'}
					]
                });
			}

			if (rolesJson['systemPreferences'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: systemPreferencesLabel, icon: 'pi pi-fw pi-share-alt', to: '/system_preferences'}
					]
                });
			}

			if (rolesJson['rules'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: rulesLabel, icon: 'pi pi-fw pi-sliders-v', to: '/rules'}
					]
                });
			}

			if (rolesJson['userRole'] != null || rolesJson['userAuthorization'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: userRolesLabel, icon: 'pi pi-fw pi-user-edit', to: '/user_roles'}
					]
                });
			}
			if(rolesJson['superAdmin'] != null){
				this.menuItems.push({
					label:'',
					items: [
						{ label: superAdminLabel, icon: 'pi pi-fw pi-users', to: '/super_admin' }
					]
				})
			}

			if(rolesJson['supportCenter'] != null){
				this.downMenuItems.push({
					label:'',
					items: [
						{ label: supportCenterLabel, icon: 'pi pi-cog', to: '/support_center' }
					]
				})
			}

			if(rolesJson['multiReservations'] != null){
				this.menuItems.push({
					label:'',
					items: [
						{ label: multiReservationsLabel, icon: 'pi pi-cog', to: '/multi_reservations' }
					]
				})
			}
			
			

		},
		'$i18n.locale'() {
			dashboardsLabel.value = this.$t('dashboards');
			reportsLabel.value = this.$t('reports');
			systemPreferencesLabel.value = this.$t('systemPreferences');
			rulesLabel.value = this.$t('rules');
			userRolesLabel.value = this.$t('userRoles');
			supportCenterLabel.value = this.$t('supportCenter');
			superAdminLabel.value = this.$t('superAdmin')
			multiReservationsLabel.value = this.$t('multiReservations')

			this.menuItems = [];
			this.downMenuItems = [];

			if (rolesJson['dashboardReports'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: dashboardsLabel, icon: 'pi pi-fw pi-chart-bar', to: '/'}
					]
                });
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: reportsLabel, icon: 'pi pi-fw pi-file', to: '/reports'}
					]
                });
			}

			if (rolesJson['systemPreferences'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: systemPreferencesLabel, icon: 'pi pi-fw pi-share-alt', to: '/system_preferences'}
					]
                });
			}

			if (rolesJson['rules'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: rulesLabel, icon: 'pi pi-fw pi-sliders-v', to: '/rules'}
					]
                });
			}

			if (rolesJson['userRole'] != null || rolesJson['userAuthorization'] != null){
				this.menuItems.push({
                    label: '',
                    items: [
						{ label: userRolesLabel, icon: 'pi pi-fw pi-user-edit', to: '/user_roles'}
					]
                });
			}
			if(rolesJson['superAdmin'] != null){
				this.menuItems.push({
					label:'',
					items: [
						{ label: superAdminLabel, icon: 'pi pi-fw pi-users', to: '/super_admin' }
					]
				})
			}
			
			if(rolesJson['supportCenter'] != null){
				this.downMenuItems.push({
					label:'',
					items: [
						{ label: supportCenterLabel, icon: 'pi pi-cog', to: '/support_center' }
					]
				})
			}

			if(rolesJson['multiReservations'] != null){
				this.menuItems.push({
					label:'',
					items: [
						{ label: multiReservationsLabel, icon: 'pi pi-fw pi-users', to: '/multi_reservations' }
					]
				})
			}
			
			
		},
	},
		
	data() {
		return {
			// dashboardsLabel: this.$t('dashboards'),
			// reportsLabel:{},
			// systemPreferencesLabel:{},

			menuItems: [],
			downMenuItems: []

		}
	},
	
    methods: {

        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		}
    },
	components: {
		'AppSubMenu': AppSubMenu
	}
}
</script>