<template>
	<div class="layout-topbar">		
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="./images/hybee_logo.png" />
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li style="display:flex; margin-right:7px;">
				<Dropdown v-model="selectedLanguage" :options="languages" optionLabel="language" :placeholder="$t('selectALanguage')" @change="changeLanguage">
					<template #value="slotProps">
						<div class="country-item country-item-value" v-if="slotProps.value">
							<!-- <img :alt="slotProps.value.locale" :src="slotProps.value.imgUrl" /> -->
							<div style="display:inline-block;">{{slotProps.value.language}}</div>
						</div>
						<span v-else>
							{{slotProps.placeholder}}
						</span>
					</template>
					<template #option="slotProps">
						<div class="country-item">
							<!-- <img :alt="slotProps.option.locale" :src="slotProps.option.imgUrl" /> -->
							<div style="display:inline-block;">{{slotProps.option.language}}</div>
						</div>
					</template>
				</Dropdown>
			</li>
			<li style="display:flex; margin-right:7px;">
				<Menubar :model="items">
					<template #item="{item}">
						<i style="cursor:pointer;padding-left:5px;" class="menu-icon" :class="item.icon"></i>
						<a style="cursor:pointer; padding-left:10px; color:black; font-weight:500" :href="item.url" >{{item.label}}</a>
					</template>
				</Menubar> 
				<div style="display:grid">
				<span style="cursor: context-menu; font-weight:bold; color:#0E1F79;">{{$keycloak.fullName}}</span>
				<span style="color:#0E1F79; cursor:context-menu;">{{ this.$t("adminAccount") }}</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import  axios  from 'axios'
import { ref } from "vue";
import { UserApi } from '@/metadata/hybee-web-api'
import { createApiForWebAPI } from "@/api"


const logOutLabel = ref("");
const userApi = createApiForWebAPI(UserApi);


export default {

	watch: {
		'$i18n.locale'() {
			this.selectedLanguage = this.$i18n.locale;
			if (this.$i18n.locale == 'tr') {
				this.selectedLanguage = {language: 'Türkçe', locale: 'tr', imgUrl:require("./images/tr.png")};
			}
			else {
				this.selectedLanguage = {language: 'English', locale: 'en', imgUrl:require("./images/en.png")};
			}
			logOutLabel.value = this.$t('logOut');
		},
	},

	data() {
        return {
			apiHeader:null,

			selectedLanguage: null,
			languages: [
				{language: 'English', locale: 'en', imgUrl:require("./images/en.png")},
				{language: 'Türkçe', locale: 'tr', imgUrl:require("./images/tr.png")},
			],
            items: [
                {
					// label:'Menu',  
					icon:'pi pi-user',
					items:[
						{
							label: logOutLabel,
							icon:'pi pi-sign-out',
							command: () => {
								// console.log(this.$keycloak.createLogoutUrl());
								// console.log(this.$keycloak.idToken)
								// axios.get('https://hybee-test-auth.d-teknoloji.com.tr/realms/Hybee-Web/protocol/openid-connect/logout?id_token_hint=' + this.$keycloak.idToken + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.href));
								window.location.assign(process.env.IDENTITY_SERVER_URL + 'realms/Hybee-Web/protocol/openid-connect/logout?id_token_hint=' + this.$keycloak.idToken + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.href));
								// this.$keycloak.logout();
								// window.location.reload();
								// axios.post(keycloak.endpoints.logout() + '?id_token_hint=' + keycloak.idToken + '&post_logout_redirect_uri=' + encodeURIComponent(window.location.href));
								// this.$keycloak.logoutFn({post_logout_redirect_url: window.location.href})
							}
							
						}
						// {
						// 	separator:true
						// },
						// {
						// 	label:'Export',
						// 	icon:'pi pi-fw pi-external-link'
						// }
					]
                }
            ]
        }
    }, 
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		changeLanguage() {
			this.$i18n.locale = this.selectedLanguage.locale;

			this.apiHeader = { headers: { Authorization: 'Bearer ' + this.$keycloak.token } }

			userApi.webapiUserUpdateUserLanguagePut(this.selectedLanguage.locale, this.apiHeader).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdateLanguage'), life: 3000 });
            });

		}
    }
	
}
</script>

<style lang="scss" scoped>
.p-dropdown {
    width: 14rem;
}

.country-item {
    img {
        width: 17px;
        margin-right: 0.5rem;
    }
}
</style>