/* tslint:disable */
/* eslint-disable */
/**
 * Hybee.Web.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessControlSystem
 */
export interface AccessControlSystem {
    /**
     * 
     * @type {number}
     * @memberof AccessControlSystem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessControlSystem
     */
    'buildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystem
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystem
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystem
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystem
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystem
     */
    'authUrl'?: string;
}
/**
 * 
 * @export
 * @interface AccessControlSystemResponse
 */
export interface AccessControlSystemResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof AccessControlSystemResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof AccessControlSystemResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AccessControlSystem}
     * @memberof AccessControlSystemResponse
     */
    'data'?: AccessControlSystem;
}
/**
 * 
 * @export
 * @interface AddNewTenantDto
 */
export interface AddNewTenantDto {
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'tenantAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'tenantEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'tenantTimezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'firstUserName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'firstUserSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'firstUserEmail'?: string;
    /**
     * 
     * @type {TenantAccountTypes}
     * @memberof AddNewTenantDto
     */
    'accountType'?: TenantAccountTypes;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'accountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'accountEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'premiumAccountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'premiumAccountEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'deskSelectFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'meetingRoomFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'friendFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'diningHallMenuFeature'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddNewTenantDto
     */
    'defaultLanguage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddNewTenantDto
     */
    'isUsingCheckMobileAppVersion'?: boolean;
}
/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'qrGuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingSpontaneousOfficeDay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'dayCountOfEarliestReservation'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'dayCountOfLatestReservation'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'daysBeforeReservationCanBeEditable'?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Building
     */
    'lastHourForReservations'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUseCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'checkinFlowType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'reservationFailIfNoCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'reservationFailsAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'numberOfNotification'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'notificationFrequency'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'numberOfNotificationCheckout'?: number;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'checkoutNotificationFrequency'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingCheckout'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'workTimeWhenAutoCheckedOut'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingAccessControlSystem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingRoute'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'deskSelectFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'meetingRoomFeature'?: boolean;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Building
     */
    'onComingReservationSendTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isActiveOnComingReservation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isUsingAutoCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'userAuthIsActive'?: boolean;
}
/**
 * 
 * @export
 * @interface BuildingIpList
 */
export interface BuildingIpList {
    /**
     * 
     * @type {number}
     * @memberof BuildingIpList
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingIpList
     */
    'buildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingIpList
     */
    'ipAddress'?: string;
}
/**
 * 
 * @export
 * @interface BuildingListDto
 */
export interface BuildingListDto {
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingListDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingListDto
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingListDto
     */
    'qrGuid'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingSpontaneousOfficeDay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'dayCountOfEarliestReservation'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'dayCountOfLatestReservation'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'daysBeforeReservationCanBeEditable'?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof BuildingListDto
     */
    'lastHourForReservations'?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof BuildingListDto
     */
    'lastHourForReservationsForRequest'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUseCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'checkinFlowType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'reservationFailIfNoCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'reservationFailsAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'numberOfNotification'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'notificationFrequency'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'numberOfNotificationCheckout'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'checkoutNotificationFrequency'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingCheckout'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildingListDto
     */
    'workTimeWhenAutoCheckedOut'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingAccessControlSystem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingRoute'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isUsingAutoCheckOut'?: boolean;
    /**
     * 
     * @type {TimeSpan}
     * @memberof BuildingListDto
     */
    'onComingReservationSendTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingListDto
     */
    'isActiveOnComingReservation'?: boolean;
}
/**
 * 
 * @export
 * @interface BuildingResponse
 */
export interface BuildingResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof BuildingResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof BuildingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Building}
     * @memberof BuildingResponse
     */
    'data'?: Building;
}
/**
 * 
 * @export
 * @interface BuildingServicesPreferencesDto
 */
export interface BuildingServicesPreferencesDto {
    /**
     * 
     * @type {number}
     * @memberof BuildingServicesPreferencesDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingServicesPreferencesDto
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingServicesPreferencesDto
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingServicesPreferencesDto
     */
    'userAuthIsActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingServicesPreferencesDto
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingServicesPreferencesDto
     */
    'isUsingShuttleRoute'?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarAddOrUpdateDto
 */
export interface CalendarAddOrUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof CalendarAddOrUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CalendarAddOrUpdateDto
     */
    'workingDays'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CalendarAddOrUpdateDto
     */
    'openHours'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarAddOrUpdateDto
     */
    'closedHours'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAddOrUpdateDto
     */
    'reservationOutOfWorkingDays'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAddOrUpdateDto
     */
    'reservationOnHolidays'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarAddOrUpdateDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarAddOrUpdateDto
     */
    'reservationEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAddOrUpdateDto
     */
    'hasNoEndingDate'?: boolean;
}
/**
 * 
 * @export
 * @interface Calender
 */
export interface Calender {
    /**
     * 
     * @type {number}
     * @memberof Calender
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Calender
     */
    'workingDays'?: Array<number>;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Calender
     */
    'openHours'?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof Calender
     */
    'closedHours'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof Calender
     */
    'reservationOutOfWorkingDays'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Calender
     */
    'reservationOnHolidays'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Calender
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Calender
     */
    'reservationEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Calender
     */
    'hasNoEndingDate'?: boolean;
}
/**
 * 
 * @export
 * @interface CalenderHoliday
 */
export interface CalenderHoliday {
    /**
     * 
     * @type {number}
     * @memberof CalenderHoliday
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalenderHoliday
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalenderHoliday
     */
    'startingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalenderHoliday
     */
    'endingDate'?: string;
}
/**
 * 
 * @export
 * @interface CalenderHolidayResponse
 */
export interface CalenderHolidayResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CalenderHolidayResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CalenderHolidayResponse
     */
    'message'?: string;
    /**
     * 
     * @type {CalenderHoliday}
     * @memberof CalenderHolidayResponse
     */
    'data'?: CalenderHoliday;
}
/**
 * 
 * @export
 * @interface CalenderListResponse
 */
export interface CalenderListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CalenderListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CalenderListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<Calender>}
     * @memberof CalenderListResponse
     */
    'data'?: Array<Calender>;
}
/**
 * 
 * @export
 * @interface CalenderResponse
 */
export interface CalenderResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CalenderResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CalenderResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Calender}
     * @memberof CalenderResponse
     */
    'data'?: Calender;
}
/**
 * 
 * @export
 * @interface CarPark
 */
export interface CarPark {
    /**
     * 
     * @type {number}
     * @memberof CarPark
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarPark
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarPark
     */
    'capacity'?: number;
}
/**
 * 
 * @export
 * @interface CarParkListDto
 */
export interface CarParkListDto {
    /**
     * 
     * @type {number}
     * @memberof CarParkListDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarParkListDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {FloorBuildingDto}
     * @memberof CarParkListDto
     */
    'building'?: FloorBuildingDto;
}
/**
 * 
 * @export
 * @interface CarParkListResponse
 */
export interface CarParkListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CarParkListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CarParkListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<CarPark>}
     * @memberof CarParkListResponse
     */
    'data'?: Array<CarPark>;
}
/**
 * 
 * @export
 * @interface CarParkResponse
 */
export interface CarParkResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CarParkResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CarParkResponse
     */
    'message'?: string;
    /**
     * 
     * @type {CarPark}
     * @memberof CarParkResponse
     */
    'data'?: CarPark;
}
/**
 * 
 * @export
 * @interface CarParkUserAuthorization
 */
export interface CarParkUserAuthorization {
    /**
     * 
     * @type {number}
     * @memberof CarParkUserAuthorization
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarParkUserAuthorization
     */
    'carParkId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarParkUserAuthorization
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CarParkUserAuthorization
     */
    'buildingId'?: number;
}
/**
 * 
 * @export
 * @interface CarParkUserAuthorizationListDto
 */
export interface CarParkUserAuthorizationListDto {
    /**
     * 
     * @type {number}
     * @memberof CarParkUserAuthorizationListDto
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CarParkUserAuthorizationListDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface CarParkUserAuthorizationListDtoListResponse
 */
export interface CarParkUserAuthorizationListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof CarParkUserAuthorizationListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof CarParkUserAuthorizationListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<CarParkUserAuthorizationListDto>}
     * @memberof CarParkUserAuthorizationListDtoListResponse
     */
    'data'?: Array<CarParkUserAuthorizationListDto>;
}
/**
 * 
 * @export
 * @interface CheckinAddOrUpdateDto
 */
export interface CheckinAddOrUpdateDto {
    /**
     * 
     * @type {boolean}
     * @memberof CheckinAddOrUpdateDto
     */
    'isUseCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckinAddOrUpdateDto
     */
    'checkinFlowType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CheckinAddOrUpdateDto
     */
    'reservationFailIfNoCheckin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckinAddOrUpdateDto
     */
    'reservationFailsAfter'?: number;
}
/**
 * 
 * @export
 * @interface CreateSupportReportDto
 */
export interface CreateSupportReportDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSupportReportDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportReportDto
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportReportDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportReportDto
     */
    'fileBase64'?: string;
}
/**
 * 
 * @export
 * @interface DashboardCarParkCardResponseDto
 */
export interface DashboardCarParkCardResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardCarParkCardResponseDto
     */
    'averagePercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardCarParkCardResponseDto
     */
    'mostPreferredDayOfWeek'?: string;
}
/**
 * 
 * @export
 * @interface DashboardCardsRequestDto
 */
export interface DashboardCardsRequestDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardCardsRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCardsRequestDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCardsRequestDto
     */
    'roomId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCardsRequestDto
     */
    'diningHallCardBuildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCardsRequestDto
     */
    'carParkCardBuildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardCardsRequestDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardCardsRequestDto
     */
    'reservationEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DashboardCardsResponseDto
 */
export interface DashboardCardsResponseDto {
    /**
     * 
     * @type {DashboardMeetingRoomCardResponseDto}
     * @memberof DashboardCardsResponseDto
     */
    'dashboardMeetingRoomCardResponseDto'?: DashboardMeetingRoomCardResponseDto;
    /**
     * 
     * @type {DashboardDiningHallCardResponseDto}
     * @memberof DashboardCardsResponseDto
     */
    'dashboardDiningHallCardResponseDto'?: DashboardDiningHallCardResponseDto;
    /**
     * 
     * @type {DashboardCarParkCardResponseDto}
     * @memberof DashboardCardsResponseDto
     */
    'dashboardCarParkCardResponseDto'?: DashboardCarParkCardResponseDto;
    /**
     * 
     * @type {DashboardStudyDeskCardResponseDto}
     * @memberof DashboardCardsResponseDto
     */
    'dashboardStudyDeskCardResponseDto'?: DashboardStudyDeskCardResponseDto;
}
/**
 * 
 * @export
 * @interface DashboardChartHoverDto
 */
export interface DashboardChartHoverDto {
    /**
     * 
     * @type {string}
     * @memberof DashboardChartHoverDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardChartHoverDto
     */
    'average'?: number;
}
/**
 * 
 * @export
 * @interface DashboardChartLabelDto
 */
export interface DashboardChartLabelDto {
    /**
     * 
     * @type {string}
     * @memberof DashboardChartLabelDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardChartLabelDto
     */
    'average'?: number;
    /**
     * 
     * @type {Array<DashboardChartHoverDto>}
     * @memberof DashboardChartLabelDto
     */
    'hovers'?: Array<DashboardChartHoverDto>;
}
/**
 * 
 * @export
 * @interface DashboardChartRequestDto
 */
export interface DashboardChartRequestDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardChartRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardChartRequestDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardChartRequestDto
     */
    'reservationEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DashboardChartResponseDto
 */
export interface DashboardChartResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardChartResponseDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {Array<DashboardChartLabelDto>}
     * @memberof DashboardChartResponseDto
     */
    'dashboardChartWeeklyBars'?: Array<DashboardChartLabelDto>;
    /**
     * 
     * @type {Array<DashboardChartLabelDto>}
     * @memberof DashboardChartResponseDto
     */
    'dashboardChartYearlyBars'?: Array<DashboardChartLabelDto>;
}
/**
 * 
 * @export
 * @interface DashboardCheckInRequestDto
 */
export interface DashboardCheckInRequestDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInRequestDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardCheckInRequestDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardCheckInRequestDto
     */
    'reservationEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DashboardCheckInResponseDto
 */
export interface DashboardCheckInResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'comingUp'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'waiting'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'notCheckedIn'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'cancelled'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'failed'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'checkedIn'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'spontaneous'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'checkout'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardCheckInResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DashboardDiningHallCardResponseDto
 */
export interface DashboardDiningHallCardResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardDiningHallCardResponseDto
     */
    'averagePercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDiningHallCardResponseDto
     */
    'mostPreferredDayOfWeek'?: string;
}
/**
 * 
 * @export
 * @interface DashboardMeetingRoomCardResponseDto
 */
export interface DashboardMeetingRoomCardResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardMeetingRoomCardResponseDto
     */
    'averagePercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardMeetingRoomCardResponseDto
     */
    'mostPreferredTimeRange'?: string;
}
/**
 * 
 * @export
 * @interface DashboardPieChartResponseDto
 */
export interface DashboardPieChartResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardPieChartResponseDto
     */
    'departmentNameList'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardPieChartResponseDto
     */
    'reservationCountList'?: Array<number>;
}
/**
 * 
 * @export
 * @interface DashboardRoomListDto
 */
export interface DashboardRoomListDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardRoomListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardRoomListDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DashboardRoomListDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardRoomListDto
     */
    'floorId'?: number;
}
/**
 * 
 * @export
 * @interface DashboardRoomListDtoListResponse
 */
export interface DashboardRoomListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof DashboardRoomListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof DashboardRoomListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DashboardRoomListDto>}
     * @memberof DashboardRoomListDtoListResponse
     */
    'data'?: Array<DashboardRoomListDto>;
}
/**
 * 
 * @export
 * @interface DashboardStudyDeskCardResponseDto
 */
export interface DashboardStudyDeskCardResponseDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardStudyDeskCardResponseDto
     */
    'averagePercentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardStudyDeskCardResponseDto
     */
    'mostPrefferedWeekday'?: string;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentListDto
 */
export interface DepartmentListDto {
    /**
     * 
     * @type {number}
     * @memberof DepartmentListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentListDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentListDtoListResponse
 */
export interface DepartmentListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof DepartmentListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof DepartmentListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DepartmentListDto>}
     * @memberof DepartmentListDtoListResponse
     */
    'data'?: Array<DepartmentListDto>;
}
/**
 * 
 * @export
 * @interface DeskReportFilter
 */
export interface DeskReportFilter {
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'filterName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'workingAreaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'subCompanyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportFilter
     */
    'titleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportFilter
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportFilter
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportFilter
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'sourceShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'destinationShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'userSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilter
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeskReportFilter
     */
    'checkInStatuses'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportFilter
     */
    'isCheckedOutBySystem'?: boolean;
}
/**
 * 
 * @export
 * @interface DeskReportFilterListResponse
 */
export interface DeskReportFilterListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof DeskReportFilterListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof DeskReportFilterListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DeskReportFilter>}
     * @memberof DeskReportFilterListResponse
     */
    'data'?: Array<DeskReportFilter>;
}
/**
 * 
 * @export
 * @interface DeskReportListDto
 */
export interface DeskReportListDto {
    /**
     * 
     * @type {number}
     * @memberof DeskReportListDto
     */
    'deskReservasionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'reservationEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'desk'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'workingAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'floorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'subCompanyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'titleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'userSurname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListDto
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListDto
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListDto
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'sourceShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'destinationShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'buildingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'employeeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'checkinTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListDto
     */
    'checkoutTime'?: string;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DeskReportListDto
     */
    'workingTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListDto
     */
    'isCheckedOutBySystem'?: boolean;
}
/**
 * 
 * @export
 * @interface DeskReportListRequestDto
 */
export interface DeskReportListRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'reservationEndDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'workingAreaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'titleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListRequestDto
     */
    'isUsingDiningHall'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListRequestDto
     */
    'isUsingShuttle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListRequestDto
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'sourceShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'destinationShuttleRoute'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'userSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeskReportListRequestDto
     */
    'licensePlate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeskReportListRequestDto
     */
    'checkInStatuses'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof DeskReportListRequestDto
     */
    'isCheckedOutBySystem'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeskReportListRequestDto
     */
    'subCompanyId'?: number;
}
/**
 * 
 * @export
 * @interface DiningHall
 */
export interface DiningHall {
    /**
     * 
     * @type {number}
     * @memberof DiningHall
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHall
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHall
     */
    'capacity'?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DiningHall
     */
    'openHours'?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DiningHall
     */
    'closeHours'?: TimeSpan;
}
/**
 * 
 * @export
 * @interface DiningHallDto
 */
export interface DiningHallDto {
    /**
     * 
     * @type {number}
     * @memberof DiningHallDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHallDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHallDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiningHallDto
     */
    'openHours'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallDto
     */
    'closeHours'?: string;
}
/**
 * 
 * @export
 * @interface DiningHallListDto
 */
export interface DiningHallListDto {
    /**
     * 
     * @type {number}
     * @memberof DiningHallListDto
     */
    'id'?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DiningHallListDto
     */
    'openHours'?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DiningHallListDto
     */
    'closeHours'?: TimeSpan;
    /**
     * 
     * @type {number}
     * @memberof DiningHallListDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {FloorBuildingDto}
     * @memberof DiningHallListDto
     */
    'building'?: FloorBuildingDto;
}
/**
 * 
 * @export
 * @interface DiningHallMenuDto
 */
export interface DiningHallMenuDto {
    /**
     * 
     * @type {number}
     * @memberof DiningHallMenuDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHallMenuDto
     */
    'diningHallId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuDto
     */
    'fileBase64'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuDto
     */
    'validityStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuDto
     */
    'validityEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DiningHallMenuListDto
 */
export interface DiningHallMenuListDto {
    /**
     * 
     * @type {number}
     * @memberof DiningHallMenuListDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiningHallMenuListDto
     */
    'diningHallId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuListDto
     */
    'menuFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuListDto
     */
    'menuFileDownloadLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuListDto
     */
    'validityStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuListDto
     */
    'validityEndDate'?: string;
}
/**
 * 
 * @export
 * @interface DiningHallMenuListDtoListResponse
 */
export interface DiningHallMenuListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof DiningHallMenuListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof DiningHallMenuListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DiningHallMenuListDto>}
     * @memberof DiningHallMenuListDtoListResponse
     */
    'data'?: Array<DiningHallMenuListDto>;
}
/**
 * 
 * @export
 * @interface DiningHallResponse
 */
export interface DiningHallResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof DiningHallResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof DiningHallResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DiningHall}
     * @memberof DiningHallResponse
     */
    'data'?: DiningHall;
}
/**
 * 
 * @export
 * @interface EmpthyAndErrorFieldsDto
 */
export interface EmpthyAndErrorFieldsDto {
    /**
     * 
     * @type {number}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'nameEmpty'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'surnameEmpty'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'titleEmpty'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'departmentEmpty'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'phoneNumberEmpty'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'titleError'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'departmentError'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmpthyAndErrorFieldsDto
     */
    'emailError'?: Array<string>;
}
/**
 * 
 * @export
 * @interface EmpthyAndErrorFieldsDtoResponse
 */
export interface EmpthyAndErrorFieldsDtoResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof EmpthyAndErrorFieldsDtoResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof EmpthyAndErrorFieldsDtoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {EmpthyAndErrorFieldsDto}
     * @memberof EmpthyAndErrorFieldsDtoResponse
     */
    'data'?: EmpthyAndErrorFieldsDto;
}
/**
 * 
 * @export
 * @interface Floor
 */
export interface Floor {
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'capacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'floorPlanImageFileName'?: string;
}
/**
 * 
 * @export
 * @interface FloorAndWorkingAreaDesksDto
 */
export interface FloorAndWorkingAreaDesksDto {
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'buildingName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'floorName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'workingAreaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'qrGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'deskNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDto
     */
    'chairNumber'?: string;
}
/**
 * 
 * @export
 * @interface FloorAndWorkingAreaDesksDtoListResponse
 */
export interface FloorAndWorkingAreaDesksDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof FloorAndWorkingAreaDesksDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDesksDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<FloorAndWorkingAreaDesksDto>}
     * @memberof FloorAndWorkingAreaDesksDtoListResponse
     */
    'data'?: Array<FloorAndWorkingAreaDesksDto>;
}
/**
 * 
 * @export
 * @interface FloorAndWorkingAreaDesksFilterDto
 */
export interface FloorAndWorkingAreaDesksFilterDto {
    /**
     * 
     * @type {number}
     * @memberof FloorAndWorkingAreaDesksFilterDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorAndWorkingAreaDesksFilterDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorAndWorkingAreaDesksFilterDto
     */
    'workingAreaId'?: number;
}
/**
 * 
 * @export
 * @interface FloorAndWorkingAreaDto
 */
export interface FloorAndWorkingAreaDto {
    /**
     * 
     * @type {Floor}
     * @memberof FloorAndWorkingAreaDto
     */
    'floor'?: Floor;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorAndWorkingAreaDto
     */
    'fileBase64'?: string;
    /**
     * 
     * @type {Array<WorkingAreaAddDto>}
     * @memberof FloorAndWorkingAreaDto
     */
    'workingAreas'?: Array<WorkingAreaAddDto>;
}
/**
 * 
 * @export
 * @interface FloorBuildingDto
 */
export interface FloorBuildingDto {
    /**
     * 
     * @type {number}
     * @memberof FloorBuildingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorBuildingDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FloorDesk
 */
export interface FloorDesk {
    /**
     * 
     * @type {number}
     * @memberof FloorDesk
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorDesk
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorDesk
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorDesk
     */
    'deskNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorDesk
     */
    'chairNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorDesk
     */
    'qrGuid'?: string;
}
/**
 * 
 * @export
 * @interface FloorDeskAddWithRangeDto
 */
export interface FloorDeskAddWithRangeDto {
    /**
     * 
     * @type {number}
     * @memberof FloorDeskAddWithRangeDto
     */
    'tenantId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorDeskAddWithRangeDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorDeskAddWithRangeDto
     */
    'deskNumberStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof FloorDeskAddWithRangeDto
     */
    'deskNumberEnd'?: number;
}
/**
 * 
 * @export
 * @interface FloorDeskChair
 */
export interface FloorDeskChair {
    /**
     * 
     * @type {string}
     * @memberof FloorDeskChair
     */
    'deskNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FloorDeskChair
     */
    'chairNumbers'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FloorDeskDto
 */
export interface FloorDeskDto {
    /**
     * 
     * @type {string}
     * @memberof FloorDeskDto
     */
    'imagePath'?: string;
    /**
     * 
     * @type {Array<FloorDeskChair>}
     * @memberof FloorDeskDto
     */
    'floorDeskChairs'?: Array<FloorDeskChair>;
}
/**
 * 
 * @export
 * @interface FloorDeskResponse
 */
export interface FloorDeskResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof FloorDeskResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof FloorDeskResponse
     */
    'message'?: string;
    /**
     * 
     * @type {FloorDesk}
     * @memberof FloorDeskResponse
     */
    'data'?: FloorDesk;
}
/**
 * 
 * @export
 * @interface FloorListDto
 */
export interface FloorListDto {
    /**
     * 
     * @type {number}
     * @memberof FloorListDto
     */
    'id'?: number;
    /**
     * 
     * @type {FloorBuildingDto}
     * @memberof FloorListDto
     */
    'building'?: FloorBuildingDto;
    /**
     * 
     * @type {string}
     * @memberof FloorListDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FloorListDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorListDto
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorListDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorListDto
     */
    'fileBase64'?: string;
    /**
     * 
     * @type {Array<WorkingArea>}
     * @memberof FloorListDto
     */
    'workingAreas'?: Array<WorkingArea>;
}
/**
 * 
 * @export
 * @interface Int32Response
 */
export interface Int32Response {
    /**
     * 
     * @type {ResponseCode}
     * @memberof Int32Response
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof Int32Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof Int32Response
     */
    'data'?: number;
}
/**
 * 
 * @export
 * @interface KeycloakUserListDto
 */
export interface KeycloakUserListDto {
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserListDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserListDto
     */
    'roleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserListDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakUserListDto
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface MeetingRoomReportListDto
 */
export interface MeetingRoomReportListDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'userSurname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingRoomReportListDto
     */
    'isHybridMeeting'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'meetingTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'roomName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListDto
     */
    'employeeId'?: string;
}
/**
 * 
 * @export
 * @interface MeetingRoomReportListRequestDto
 */
export interface MeetingRoomReportListRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingRoomReportListRequestDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MeetingRoomReportListRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingRoomReportListRequestDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MeetingRoomReportListRequestDto
     */
    'roomId'?: number;
}
/**
 * 
 * @export
 * @interface MobileAppVersion
 */
export interface MobileAppVersion {
    /**
     * 
     * @type {number}
     * @memberof MobileAppVersion
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MobileAppVersion
     */
    'ios'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileAppVersion
     */
    'android'?: string;
    /**
     * 
     * @type {string}
     * @memberof MobileAppVersion
     */
    'createDate'?: string;
}
/**
 * 
 * @export
 * @interface MultiReservationDto
 */
export interface MultiReservationDto {
    /**
     * 
     * @type {number}
     * @memberof MultiReservationDto
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MultiReservationDto
     */
    'userIdList'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MultiReservationDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiReservationDto
     */
    'workingAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MultiReservationDto
     */
    'reservationStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultiReservationDto
     */
    'reservationEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MultiReservationDto
     */
    'isUsingCarPark'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MultiReservationDto
     */
    'isUsingDiningHall'?: boolean;
}
/**
 * 
 * @export
 * @interface OnComingReservationOptionDto
 */
export interface OnComingReservationOptionDto {
    /**
     * 
     * @type {string}
     * @memberof OnComingReservationOptionDto
     */
    'sendTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof OnComingReservationOptionDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OnComingReservationOptionDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PolicyGetDto
 */
export interface PolicyGetDto {
    /**
     * 
     * @type {number}
     * @memberof PolicyGetDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyGetDto
     */
    'fileNameForBrowser'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyGetDto
     */
    'fileNameForBucket'?: string;
    /**
     * 
     * @type {number}
     * @memberof PolicyGetDto
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyGetDto
     */
    'dowloandLink'?: string;
}
/**
 * 
 * @export
 * @interface PolicyGetDtoResponse
 */
export interface PolicyGetDtoResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof PolicyGetDtoResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof PolicyGetDtoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PolicyGetDto}
     * @memberof PolicyGetDtoResponse
     */
    'data'?: PolicyGetDto;
}
/**
 * 
 * @export
 * @interface ReportMailRequestDto
 */
export interface ReportMailRequestDto {
    /**
     * 
     * @type {number}
     * @memberof ReportMailRequestDto
     */
    'tenantId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportMailRequestDto
     */
    'recieverEmailAddress'?: Array<string>;
    /**
     * 
     * @type {TimeSpan}
     * @memberof ReportMailRequestDto
     */
    'sendTime'?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof ReportMailRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMailRequestDto
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface ReportOptionsDto
 */
export interface ReportOptionsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportOptionsDto
     */
    'recieverEmailAddress'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReportOptionsDto
     */
    'sendTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportOptionsDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionsDto
     */
    'reportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionsDto
     */
    'carParkExcelFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportOptionsDto
     */
    'diningHallExcelFontSize'?: number;
}
/**
 * 
 * @export
 * @interface Response
 */
export interface Response {
    /**
     * 
     * @type {ResponseCode}
     * @memberof Response
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResponseCode = {
    NUMBER_200: 200,
    NUMBER_204: 204,
    NUMBER_400: 400,
    NUMBER_401: 401,
    NUMBER_404: 404,
    NUMBER_409: 409,
    NUMBER_500: 500
} as const;

export type ResponseCode = typeof ResponseCode[keyof typeof ResponseCode];


/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    'buildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'roomName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    'capacity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Room
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'roomEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    'meetingRoomImageFileName'?: string;
}
/**
 * 
 * @export
 * @interface RoomAddOrUpdateDto
 */
export interface RoomAddOrUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof RoomAddOrUpdateDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomAddOrUpdateDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomAddOrUpdateDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'roomName'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomAddOrUpdateDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoomAddOrUpdateDto
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'roomEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'meetingRoomImageFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomAddOrUpdateDto
     */
    'fileBase64'?: string;
}
/**
 * 
 * @export
 * @interface RoomListDto
 */
export interface RoomListDto {
    /**
     * 
     * @type {number}
     * @memberof RoomListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomListDto
     */
    'roomName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomListDto
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomListDto
     */
    'roomEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomListDto
     */
    'imagePath'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomListDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {FloorListDto}
     * @memberof RoomListDto
     */
    'floor'?: FloorListDto;
    /**
     * 
     * @type {string}
     * @memberof RoomListDto
     */
    'servicesText'?: string;
    /**
     * 
     * @type {Array<RoomService>}
     * @memberof RoomListDto
     */
    'services'?: Array<RoomService>;
}
/**
 * 
 * @export
 * @interface RoomListDtoListResponse
 */
export interface RoomListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RoomListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RoomListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<RoomListDto>}
     * @memberof RoomListDtoListResponse
     */
    'data'?: Array<RoomListDto>;
}
/**
 * 
 * @export
 * @interface RoomListRequestDto
 */
export interface RoomListRequestDto {
    /**
     * 
     * @type {number}
     * @memberof RoomListRequestDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomListRequestDto
     */
    'floorId'?: number;
}
/**
 * 
 * @export
 * @interface RoomReservationManagement
 */
export interface RoomReservationManagement {
    /**
     * 
     * @type {number}
     * @memberof RoomReservationManagement
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomReservationManagement
     */
    'roomId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomReservationManagement
     */
    'floorReservationManagementId'?: number;
}
/**
 * 
 * @export
 * @interface RoomReservationManagementListResponse
 */
export interface RoomReservationManagementListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RoomReservationManagementListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RoomReservationManagementListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<RoomReservationManagement>}
     * @memberof RoomReservationManagementListResponse
     */
    'data'?: Array<RoomReservationManagement>;
}
/**
 * 
 * @export
 * @interface RoomResponse
 */
export interface RoomResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RoomResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RoomResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Room}
     * @memberof RoomResponse
     */
    'data'?: Room;
}
/**
 * 
 * @export
 * @interface RoomService
 */
export interface RoomService {
    /**
     * 
     * @type {number}
     * @memberof RoomService
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomService
     */
    'service'?: string;
}
/**
 * 
 * @export
 * @interface RoomServiceListResponse
 */
export interface RoomServiceListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RoomServiceListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RoomServiceListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<RoomService>}
     * @memberof RoomServiceListResponse
     */
    'data'?: Array<RoomService>;
}
/**
 * 
 * @export
 * @interface RoomServiceResponse
 */
export interface RoomServiceResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RoomServiceResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RoomServiceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {RoomService}
     * @memberof RoomServiceResponse
     */
    'data'?: RoomService;
}
/**
 * 
 * @export
 * @interface RulesRequestDto
 */
export interface RulesRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RulesRequestDto
     */
    'carParkReportEmail'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RulesRequestDto
     */
    'carParkReportSendTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RulesRequestDto
     */
    'isActiveCarParkReport'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RulesRequestDto
     */
    'diningHallReportEmail'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RulesRequestDto
     */
    'diningHallReportSendTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RulesRequestDto
     */
    'isActiveDiningHallReport'?: boolean;
    /**
     * 
     * @type {Array<BuildingListDto>}
     * @memberof RulesRequestDto
     */
    'buildings'?: Array<BuildingListDto>;
    /**
     * 
     * @type {number}
     * @memberof RulesRequestDto
     */
    'carParkReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesRequestDto
     */
    'diningHallReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RulesRequestDto
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RulesRequestDto
     */
    'maxDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesRequestDto
     */
    'carParkExcelFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesRequestDto
     */
    'diningHallExcelFontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RulesRequestDto
     */
    'isReservationMailWillSend'?: boolean;
}
/**
 * 
 * @export
 * @interface RulesResponseDto
 */
export interface RulesResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RulesResponseDto
     */
    'diningHallReportEmail'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RulesResponseDto
     */
    'carParkReportEmail'?: Array<string>;
    /**
     * 
     * @type {TimeSpan}
     * @memberof RulesResponseDto
     */
    'diningHallReportSendTime'?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof RulesResponseDto
     */
    'carParkReportSendTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof RulesResponseDto
     */
    'isActiveCarParkReport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RulesResponseDto
     */
    'isActiveDiningHallReport'?: boolean;
    /**
     * 
     * @type {Array<BuildingListDto>}
     * @memberof RulesResponseDto
     */
    'buildings'?: Array<BuildingListDto>;
    /**
     * 
     * @type {number}
     * @memberof RulesResponseDto
     */
    'carParkReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesResponseDto
     */
    'diningHallReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RulesResponseDto
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RulesResponseDto
     */
    'maxDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesResponseDto
     */
    'carParkExcelFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof RulesResponseDto
     */
    'diningHallExcelFontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RulesResponseDto
     */
    'isReservationMailWillSend'?: boolean;
}
/**
 * 
 * @export
 * @interface RulesResponseDtoResponse
 */
export interface RulesResponseDtoResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof RulesResponseDtoResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof RulesResponseDtoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {RulesResponseDto}
     * @memberof RulesResponseDtoResponse
     */
    'data'?: RulesResponseDto;
}
/**
 * 
 * @export
 * @interface SessionAddDto
 */
export interface SessionAddDto {
    /**
     * 
     * @type {string}
     * @memberof SessionAddDto
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionAddDto
     */
    'createdByIp'?: string;
}
/**
 * 
 * @export
 * @interface Shuttle
 */
export interface Shuttle {
    /**
     * 
     * @type {number}
     * @memberof Shuttle
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Shuttle
     */
    'buildingId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Shuttle
     */
    'routeName'?: string;
}
/**
 * 
 * @export
 * @interface ShuttleListDto
 */
export interface ShuttleListDto {
    /**
     * 
     * @type {number}
     * @memberof ShuttleListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShuttleListDto
     */
    'routeName'?: string;
    /**
     * 
     * @type {FloorBuildingDto}
     * @memberof ShuttleListDto
     */
    'building'?: FloorBuildingDto;
}
/**
 * 
 * @export
 * @interface ShuttleResponse
 */
export interface ShuttleResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof ShuttleResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof ShuttleResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Shuttle}
     * @memberof ShuttleResponse
     */
    'data'?: Shuttle;
}
/**
 * 
 * @export
 * @interface StringListResponse
 */
export interface StringListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof StringListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof StringListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StringListResponse
     */
    'data'?: Array<string>;
}
/**
 * 
 * @export
 * @interface StringResponse
 */
export interface StringResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof StringResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof StringResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof StringResponse
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface SubCompany
 */
export interface SubCompany {
    /**
     * 
     * @type {number}
     * @memberof SubCompany
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubCompany
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SubCompanyListResponse
 */
export interface SubCompanyListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof SubCompanyListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof SubCompanyListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<SubCompany>}
     * @memberof SubCompanyListResponse
     */
    'data'?: Array<SubCompany>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TenantAccountTypes = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type TenantAccountTypes = typeof TenantAccountTypes[keyof typeof TenantAccountTypes];


/**
 * 
 * @export
 * @interface TenantAzureADParameter
 */
export interface TenantAzureADParameter {
    /**
     * 
     * @type {number}
     * @memberof TenantAzureADParameter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'azureClientID'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'azureTenantID'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'azureClientSecret'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'filter'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'emailFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameter
     */
    'companyFilter'?: string;
}
/**
 * 
 * @export
 * @interface TenantAzureADParameterResponse
 */
export interface TenantAzureADParameterResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof TenantAzureADParameterResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof TenantAzureADParameterResponse
     */
    'message'?: string;
    /**
     * 
     * @type {TenantAzureADParameter}
     * @memberof TenantAzureADParameterResponse
     */
    'data'?: TenantAzureADParameter;
}
/**
 * 
 * @export
 * @interface TenantGoogleWorkspaceParameter
 */
export interface TenantGoogleWorkspaceParameter {
    /**
     * 
     * @type {number}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'admin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'privateKeyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'privateKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'clientEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'clientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'client_x509_CertUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'filter'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameter
     */
    'domain'?: string;
}
/**
 * 
 * @export
 * @interface TenantGoogleWorkspaceParameterResponse
 */
export interface TenantGoogleWorkspaceParameterResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof TenantGoogleWorkspaceParameterResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof TenantGoogleWorkspaceParameterResponse
     */
    'message'?: string;
    /**
     * 
     * @type {TenantGoogleWorkspaceParameter}
     * @memberof TenantGoogleWorkspaceParameterResponse
     */
    'data'?: TenantGoogleWorkspaceParameter;
}
/**
 * 
 * @export
 * @interface TenantListDto
 */
export interface TenantListDto {
    /**
     * 
     * @type {number}
     * @memberof TenantListDto
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'tenantAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'tenantEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'tenantTimezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'firstUserEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantListDto
     */
    'accountType'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'accountTypeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'accountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'accountEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'premiumAccountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'premiumAccountEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'deskSelectFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'meetingRoomFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'friendFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'diningHallMenuFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantListDto
     */
    'defaultLanguage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantListDto
     */
    'isUsingCheckMobileAppVersion'?: boolean;
}
/**
 * 
 * @export
 * @interface TenantParameter
 */
export interface TenantParameter {
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'id'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantParameter
     */
    'diningHallReportEmail'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantParameter
     */
    'carParkReportEmail'?: Array<string>;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TenantParameter
     */
    'diningHallReportSendTime'?: TimeSpan;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TenantParameter
     */
    'carParkReportSendTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'isActiveCarParkReport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'isActiveDiningHallReport'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'carParkReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'diningHallReportMailDayNextDayCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'isManagerAuto'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'maxDayCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'multipleDayIsActiveForBackOffice'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'carParkExcelFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantParameter
     */
    'diningHallExcelFontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'isReservationMailWillSend'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantParameter
     */
    'isUsingCreateDeskReservationByAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface TenantParameterResponse
 */
export interface TenantParameterResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof TenantParameterResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof TenantParameterResponse
     */
    'message'?: string;
    /**
     * 
     * @type {TenantParameter}
     * @memberof TenantParameterResponse
     */
    'data'?: TenantParameter;
}
/**
 * 
 * @export
 * @interface TenantReservationNotificationParameter
 */
export interface TenantReservationNotificationParameter {
    /**
     * 
     * @type {number}
     * @memberof TenantReservationNotificationParameter
     */
    'id'?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof TenantReservationNotificationParameter
     */
    'onComingReservationSendTime'?: TimeSpan;
    /**
     * 
     * @type {boolean}
     * @memberof TenantReservationNotificationParameter
     */
    'isActiveOnComingReservation'?: boolean;
}
/**
 * 
 * @export
 * @interface TenantReservationNotificationParameterResponse
 */
export interface TenantReservationNotificationParameterResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof TenantReservationNotificationParameterResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof TenantReservationNotificationParameterResponse
     */
    'message'?: string;
    /**
     * 
     * @type {TenantReservationNotificationParameter}
     * @memberof TenantReservationNotificationParameterResponse
     */
    'data'?: TenantReservationNotificationParameter;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'ticks'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'hours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'milliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalHours'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMilliseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    'totalSeconds'?: number;
}
/**
 * 
 * @export
 * @interface Title
 */
export interface Title {
    /**
     * 
     * @type {number}
     * @memberof Title
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Title
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TitleListDto
 */
export interface TitleListDto {
    /**
     * 
     * @type {number}
     * @memberof TitleListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TitleListDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TitleListDtoListResponse
 */
export interface TitleListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof TitleListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof TitleListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<TitleListDto>}
     * @memberof TitleListDtoListResponse
     */
    'data'?: Array<TitleListDto>;
}
/**
 * 
 * @export
 * @interface UpdateTenantDto
 */
export interface UpdateTenantDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateTenantDto
     */
    'tenantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'tenantName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'tenantAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'tenantEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'tenantTimezone'?: string;
    /**
     * 
     * @type {TenantAccountTypes}
     * @memberof UpdateTenantDto
     */
    'accountType'?: TenantAccountTypes;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'accountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'accountEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'premiumAccountStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'premiumAccountEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'deskSelectFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'meetingRoomFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'friendFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'diningHallMenuFeature'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'multipleDayIsActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTenantDto
     */
    'defaultLanguage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTenantDto
     */
    'isUsingCheckMobileAppVersion'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserRoleRequestDto
 */
export interface UpdateUserRoleRequestDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserRoleRequestDto
     */
    'userRoleDetailId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'dashboardReports'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'deleteReservations'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'systemPreferences'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'rules'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'userRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'userAuthorization'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'supportCenter'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'roleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRoleRequestDto
     */
    'oldRoleName'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'surname'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'title'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'department'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isAddedManual'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'backOfficeLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'appLanguage'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'managerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'employeeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'subCompany'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'licensePlate'?: string;
}
/**
 * 
 * @export
 * @interface UserListDto
 */
export interface UserListDto {
    /**
     * 
     * @type {number}
     * @memberof UserListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'surname'?: string;
    /**
     * 
     * @type {TitleListDto}
     * @memberof UserListDto
     */
    'title'?: TitleListDto;
    /**
     * 
     * @type {DepartmentListDto}
     * @memberof UserListDto
     */
    'department'?: DepartmentListDto;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListDto
     */
    'employeeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserListDto
     */
    'isAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface UserListDtoListResponse
 */
export interface UserListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof UserListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof UserListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<UserListDto>}
     * @memberof UserListDtoListResponse
     */
    'data'?: Array<UserListDto>;
}
/**
 * 
 * @export
 * @interface UserListForRoomReservationDto
 */
export interface UserListForRoomReservationDto {
    /**
     * 
     * @type {string}
     * @memberof UserListForRoomReservationDto
     */
    'nameSurname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserListForRoomReservationDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof UserListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof UserListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserListResponse
     */
    'data'?: Array<User>;
}
/**
 * 
 * @export
 * @interface UserRoleDetail
 */
export interface UserRoleDetail {
    /**
     * 
     * @type {number}
     * @memberof UserRoleDetail
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'dashboardReports'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'deleteReservations'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'systemPreferences'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'rules'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'userRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'userAuthorization'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'supportCenter'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetail
     */
    'roleName'?: string;
}
/**
 * 
 * @export
 * @interface UserRoleDetailListResponse
 */
export interface UserRoleDetailListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof UserRoleDetailListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDetailListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<UserRoleDetail>}
     * @memberof UserRoleDetailListResponse
     */
    'data'?: Array<UserRoleDetail>;
}
/**
 * 
 * @export
 * @interface UsersHaveCarListDto
 */
export interface UsersHaveCarListDto {
    /**
     * 
     * @type {number}
     * @memberof UsersHaveCarListDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'surname'?: string;
    /**
     * 
     * @type {TitleListDto}
     * @memberof UsersHaveCarListDto
     */
    'title'?: TitleListDto;
    /**
     * 
     * @type {DepartmentListDto}
     * @memberof UsersHaveCarListDto
     */
    'department'?: DepartmentListDto;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'employeeId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersHaveCarListDto
     */
    'isAdmin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDto
     */
    'licensePlate'?: string;
}
/**
 * 
 * @export
 * @interface UsersHaveCarListDtoListResponse
 */
export interface UsersHaveCarListDtoListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof UsersHaveCarListDtoListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof UsersHaveCarListDtoListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<UsersHaveCarListDto>}
     * @memberof UsersHaveCarListDtoListResponse
     */
    'data'?: Array<UsersHaveCarListDto>;
}
/**
 * 
 * @export
 * @interface WorkingArea
 */
export interface WorkingArea {
    /**
     * 
     * @type {number}
     * @memberof WorkingArea
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingArea
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkingArea
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkingArea
     */
    'capacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkingArea
     */
    'imagePath'?: string;
}
/**
 * 
 * @export
 * @interface WorkingAreaAddDto
 */
export interface WorkingAreaAddDto {
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaAddDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaAddDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaAddDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaAddDto
     */
    'capacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaAddDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaAddDto
     */
    'fileBase64'?: string;
}
/**
 * 
 * @export
 * @interface WorkingAreaDesk
 */
export interface WorkingAreaDesk {
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaDesk
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaDesk
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaDesk
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkingAreaDesk
     */
    'workingAreaId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaDesk
     */
    'deskNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaDesk
     */
    'chairNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaDesk
     */
    'qrGuid'?: string;
}
/**
 * 
 * @export
 * @interface WorkingAreaDeskListResponse
 */
export interface WorkingAreaDeskListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof WorkingAreaDeskListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaDeskListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<WorkingAreaDesk>}
     * @memberof WorkingAreaDeskListResponse
     */
    'data'?: Array<WorkingAreaDesk>;
}
/**
 * 
 * @export
 * @interface WorkingAreaListResponse
 */
export interface WorkingAreaListResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof WorkingAreaListResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {Array<WorkingArea>}
     * @memberof WorkingAreaListResponse
     */
    'data'?: Array<WorkingArea>;
}
/**
 * 
 * @export
 * @interface WorkingAreaResponse
 */
export interface WorkingAreaResponse {
    /**
     * 
     * @type {ResponseCode}
     * @memberof WorkingAreaResponse
     */
    'responseCode'?: ResponseCode;
    /**
     * 
     * @type {string}
     * @memberof WorkingAreaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {WorkingArea}
     * @memberof WorkingAreaResponse
     */
    'data'?: WorkingArea;
}

/**
 * AccessControlSystemApi - axios parameter creator
 * @export
 */
export const AccessControlSystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemAddOrUpdateAsyncPost: async (body?: AccessControlSystem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/AccessControlSystem/AddOrUpdateAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/AccessControlSystem/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemGetByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/AccessControlSystem/GetByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemUpdatePost: async (body?: AccessControlSystem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/AccessControlSystem/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessControlSystemApi - functional programming interface
 * @export
 */
export const AccessControlSystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessControlSystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiAccessControlSystemAddOrUpdateAsyncPost(body?: AccessControlSystem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiAccessControlSystemAddOrUpdateAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiAccessControlSystemDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiAccessControlSystemDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessControlSystemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiAccessControlSystemUpdatePost(body?: AccessControlSystem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiAccessControlSystemUpdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessControlSystemApi - factory interface
 * @export
 */
export const AccessControlSystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessControlSystemApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemAddOrUpdateAsyncPost(body?: AccessControlSystem, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiAccessControlSystemAddOrUpdateAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiAccessControlSystemDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<AccessControlSystemResponse> {
            return localVarFp.webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessControlSystem} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiAccessControlSystemUpdatePost(body?: AccessControlSystem, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiAccessControlSystemUpdatePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessControlSystemApi - object-oriented interface
 * @export
 * @class AccessControlSystemApi
 * @extends {BaseAPI}
 */
export class AccessControlSystemApi extends BaseAPI {
    /**
     * 
     * @param {AccessControlSystem} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlSystemApi
     */
    public webapiAccessControlSystemAddOrUpdateAsyncPost(body?: AccessControlSystem, options?: AxiosRequestConfig) {
        return AccessControlSystemApiFp(this.configuration).webapiAccessControlSystemAddOrUpdateAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlSystemApi
     */
    public webapiAccessControlSystemDeletePost(body?: number, options?: AxiosRequestConfig) {
        return AccessControlSystemApiFp(this.configuration).webapiAccessControlSystemDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlSystemApi
     */
    public webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return AccessControlSystemApiFp(this.configuration).webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessControlSystem} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlSystemApi
     */
    public webapiAccessControlSystemUpdatePost(body?: AccessControlSystem, options?: AxiosRequestConfig) {
        return AccessControlSystemApiFp(this.configuration).webapiAccessControlSystemUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingApi - axios parameter creator
 * @export
 */
export const BuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingAddPost: async (body?: Building, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Building/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingDeleteAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Building/DeleteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingGetByIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Building/GetByIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Building/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingUpdatePost: async (body?: Building, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Building/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingAddPost(body?: Building, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingDeleteAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingDeleteAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingGetByIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Building>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingGetByIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingUpdatePost(body?: Building, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingUpdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingApiFp(configuration)
    return {
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingAddPost(body?: Building, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingDeleteAsyncPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingDeleteAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingGetByIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<Building> {
            return localVarFp.webapiBuildingGetByIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingListAsyncGet(options?: any): AxiosPromise<Array<BuildingListDto>> {
            return localVarFp.webapiBuildingListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Building} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingUpdatePost(body?: Building, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingUpdatePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * 
     * @param {Building} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public webapiBuildingAddPost(body?: Building, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).webapiBuildingAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public webapiBuildingDeleteAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).webapiBuildingDeleteAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public webapiBuildingGetByIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).webapiBuildingGetByIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public webapiBuildingListAsyncGet(options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).webapiBuildingListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Building} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public webapiBuildingUpdatePost(body?: Building, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).webapiBuildingUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingIpApi - axios parameter creator
 * @export
 */
export const BuildingIpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpAddPost: async (body?: BuildingIpList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/BuildingIp/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpDeleteAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/BuildingIp/DeleteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpGetByIdAsyncGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/BuildingIp/GetByIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpListAsyncGet: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/BuildingIp/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpUpdatePost: async (body?: BuildingIpList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/BuildingIp/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingIpApi - functional programming interface
 * @export
 */
export const BuildingIpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingIpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingIpAddPost(body?: BuildingIpList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingIpAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingIpDeleteAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingIpDeleteAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingIpGetByIdAsyncGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingIpList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingIpGetByIdAsyncGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingIpListAsyncGet(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingIpList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingIpListAsyncGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiBuildingIpUpdatePost(body?: BuildingIpList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiBuildingIpUpdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingIpApi - factory interface
 * @export
 */
export const BuildingIpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingIpApiFp(configuration)
    return {
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpAddPost(body?: BuildingIpList, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingIpAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpDeleteAsyncPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingIpDeleteAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpGetByIdAsyncGet(body?: number, options?: any): AxiosPromise<BuildingIpList> {
            return localVarFp.webapiBuildingIpGetByIdAsyncGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpListAsyncGet(id?: number, options?: any): AxiosPromise<Array<BuildingIpList>> {
            return localVarFp.webapiBuildingIpListAsyncGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BuildingIpList} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiBuildingIpUpdatePost(body?: BuildingIpList, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiBuildingIpUpdatePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuildingIpApi - object-oriented interface
 * @export
 * @class BuildingIpApi
 * @extends {BaseAPI}
 */
export class BuildingIpApi extends BaseAPI {
    /**
     * 
     * @param {BuildingIpList} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingIpApi
     */
    public webapiBuildingIpAddPost(body?: BuildingIpList, options?: AxiosRequestConfig) {
        return BuildingIpApiFp(this.configuration).webapiBuildingIpAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingIpApi
     */
    public webapiBuildingIpDeleteAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return BuildingIpApiFp(this.configuration).webapiBuildingIpDeleteAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingIpApi
     */
    public webapiBuildingIpGetByIdAsyncGet(body?: number, options?: AxiosRequestConfig) {
        return BuildingIpApiFp(this.configuration).webapiBuildingIpGetByIdAsyncGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingIpApi
     */
    public webapiBuildingIpListAsyncGet(id?: number, options?: AxiosRequestConfig) {
        return BuildingIpApiFp(this.configuration).webapiBuildingIpListAsyncGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuildingIpList} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingIpApi
     */
    public webapiBuildingIpUpdatePost(body?: BuildingIpList, options?: AxiosRequestConfig) {
        return BuildingIpApiFp(this.configuration).webapiBuildingIpUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalenderApi - axios parameter creator
 * @export
 */
export const CalenderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalendarAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderAddOrUpdatePost: async (body?: CalendarAddOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/AddOrUpdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderAddPost: async (body?: Calender, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderGetListByTenantIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/GetListByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderUpdatePut: async (body?: Calender, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Calender/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalenderApi - functional programming interface
 * @export
 */
export const CalenderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalenderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalendarAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderAddOrUpdatePost(body?: CalendarAddOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalenderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderAddOrUpdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderAddPost(body?: Calender, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalenderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderGetListByTenantIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalenderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderGetListByTenantIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderUpdatePut(body?: Calender, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalenderApi - factory interface
 * @export
 */
export const CalenderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalenderApiFp(configuration)
    return {
        /**
         * 
         * @param {CalendarAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderAddOrUpdatePost(body?: CalendarAddOrUpdateDto, options?: any): AxiosPromise<CalenderResponse> {
            return localVarFp.webapiCalenderAddOrUpdatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderAddPost(body?: Calender, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderGetByIdGet(body?: number, options?: any): AxiosPromise<CalenderResponse> {
            return localVarFp.webapiCalenderGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderGetListByTenantIdGet(options?: any): AxiosPromise<CalenderListResponse> {
            return localVarFp.webapiCalenderGetListByTenantIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Calender} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderUpdatePut(body?: Calender, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalenderApi - object-oriented interface
 * @export
 * @class CalenderApi
 * @extends {BaseAPI}
 */
export class CalenderApi extends BaseAPI {
    /**
     * 
     * @param {CalendarAddOrUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderAddOrUpdatePost(body?: CalendarAddOrUpdateDto, options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderAddOrUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Calender} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderAddPost(body?: Calender, options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderGetListByTenantIdGet(options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderGetListByTenantIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Calender} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderApi
     */
    public webapiCalenderUpdatePut(body?: Calender, options?: AxiosRequestConfig) {
        return CalenderApiFp(this.configuration).webapiCalenderUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalenderHolidayApi - axios parameter creator
 * @export
 */
export const CalenderHolidayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayAddAsyncPost: async (body?: CalenderHoliday, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CalenderHoliday/AddAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayDeleteAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CalenderHoliday/DeleteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CalenderHoliday/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CalenderHoliday/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayUpdateAsyncPost: async (body?: CalenderHoliday, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CalenderHoliday/UpdateAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalenderHolidayApi - functional programming interface
 * @export
 */
export const CalenderHolidayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalenderHolidayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderHolidayAddAsyncPost(body?: CalenderHoliday, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderHolidayAddAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderHolidayDeleteAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderHolidayDeleteAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderHolidayGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalenderHolidayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderHolidayGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderHolidayListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalenderHoliday>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderHolidayListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCalenderHolidayUpdateAsyncPost(body?: CalenderHoliday, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCalenderHolidayUpdateAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalenderHolidayApi - factory interface
 * @export
 */
export const CalenderHolidayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalenderHolidayApiFp(configuration)
    return {
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayAddAsyncPost(body?: CalenderHoliday, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderHolidayAddAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayDeleteAsyncPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderHolidayDeleteAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayGetByIdGet(body?: number, options?: any): AxiosPromise<CalenderHolidayResponse> {
            return localVarFp.webapiCalenderHolidayGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayListAsyncGet(options?: any): AxiosPromise<Array<CalenderHoliday>> {
            return localVarFp.webapiCalenderHolidayListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalenderHoliday} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCalenderHolidayUpdateAsyncPost(body?: CalenderHoliday, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCalenderHolidayUpdateAsyncPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalenderHolidayApi - object-oriented interface
 * @export
 * @class CalenderHolidayApi
 * @extends {BaseAPI}
 */
export class CalenderHolidayApi extends BaseAPI {
    /**
     * 
     * @param {CalenderHoliday} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderHolidayApi
     */
    public webapiCalenderHolidayAddAsyncPost(body?: CalenderHoliday, options?: AxiosRequestConfig) {
        return CalenderHolidayApiFp(this.configuration).webapiCalenderHolidayAddAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderHolidayApi
     */
    public webapiCalenderHolidayDeleteAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return CalenderHolidayApiFp(this.configuration).webapiCalenderHolidayDeleteAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderHolidayApi
     */
    public webapiCalenderHolidayGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return CalenderHolidayApiFp(this.configuration).webapiCalenderHolidayGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderHolidayApi
     */
    public webapiCalenderHolidayListAsyncGet(options?: AxiosRequestConfig) {
        return CalenderHolidayApiFp(this.configuration).webapiCalenderHolidayListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalenderHoliday} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalenderHolidayApi
     */
    public webapiCalenderHolidayUpdateAsyncPost(body?: CalenderHoliday, options?: AxiosRequestConfig) {
        return CalenderHolidayApiFp(this.configuration).webapiCalenderHolidayUpdateAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarParkApi - axios parameter creator
 * @export
 */
export const CarParkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkAddPost: async (body?: CarPark, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkGetListByBuildingIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/GetListByBuildingId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkListByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/ListByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUpdatePut: async (body?: CarPark, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarPark/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarParkApi - functional programming interface
 * @export
 */
export const CarParkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarParkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkAddPost(body?: CarPark, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarParkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkGetListByBuildingIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarParkListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkGetListByBuildingIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarParkListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarPark>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkListByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkUpdatePut(body?: CarPark, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarParkApi - factory interface
 * @export
 */
export const CarParkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarParkApiFp(configuration)
    return {
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkAddPost(body?: CarPark, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCarParkAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCarParkDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkGetByIdGet(body?: number, options?: any): AxiosPromise<CarParkResponse> {
            return localVarFp.webapiCarParkGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkGetListByBuildingIdGet(body?: number, options?: any): AxiosPromise<CarParkListResponse> {
            return localVarFp.webapiCarParkGetListByBuildingIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkListAsyncGet(options?: any): AxiosPromise<Array<CarParkListDto>> {
            return localVarFp.webapiCarParkListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkListByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<Array<CarPark>> {
            return localVarFp.webapiCarParkListByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarPark} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUpdatePut(body?: CarPark, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCarParkUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarParkApi - object-oriented interface
 * @export
 * @class CarParkApi
 * @extends {BaseAPI}
 */
export class CarParkApi extends BaseAPI {
    /**
     * 
     * @param {CarPark} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkAddPost(body?: CarPark, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkGetListByBuildingIdGet(body?: number, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkGetListByBuildingIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkListAsyncGet(options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkListByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarPark} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkApi
     */
    public webapiCarParkUpdatePut(body?: CarPark, options?: AxiosRequestConfig) {
        return CarParkApiFp(this.configuration).webapiCarParkUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarParkUserAuthorizationApi - axios parameter creator
 * @export
 */
export const CarParkUserAuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CarParkUserAuthorization} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationAddPost: async (body?: CarParkUserAuthorization, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarParkUserAuthorization/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarParkUserAuthorization/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationGetListByBuildingIdGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/CarParkUserAuthorization/GetListByBuildingId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarParkUserAuthorizationApi - functional programming interface
 * @export
 */
export const CarParkUserAuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarParkUserAuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CarParkUserAuthorization} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkUserAuthorizationAddPost(body?: CarParkUserAuthorization, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkUserAuthorizationAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkUserAuthorizationDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkUserAuthorizationDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarParkUserAuthorizationListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarParkUserAuthorizationApi - factory interface
 * @export
 */
export const CarParkUserAuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarParkUserAuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {CarParkUserAuthorization} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationAddPost(body?: CarParkUserAuthorization, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCarParkUserAuthorizationAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiCarParkUserAuthorizationDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId?: number, options?: any): AxiosPromise<CarParkUserAuthorizationListDtoListResponse> {
            return localVarFp.webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CarParkUserAuthorizationApi - object-oriented interface
 * @export
 * @class CarParkUserAuthorizationApi
 * @extends {BaseAPI}
 */
export class CarParkUserAuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {CarParkUserAuthorization} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkUserAuthorizationApi
     */
    public webapiCarParkUserAuthorizationAddPost(body?: CarParkUserAuthorization, options?: AxiosRequestConfig) {
        return CarParkUserAuthorizationApiFp(this.configuration).webapiCarParkUserAuthorizationAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkUserAuthorizationApi
     */
    public webapiCarParkUserAuthorizationDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return CarParkUserAuthorizationApiFp(this.configuration).webapiCarParkUserAuthorizationDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarParkUserAuthorizationApi
     */
    public webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId?: number, options?: AxiosRequestConfig) {
        return CarParkUserAuthorizationApiFp(this.configuration).webapiCarParkUserAuthorizationGetListByBuildingIdGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DashboardCardsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardCardsPost: async (body?: DashboardCardsRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Dashboard/GetDashboardCards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardChartPost: async (body?: DashboardChartRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Dashboard/GetDashboardChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardCheckInRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardCheckInChartPost: async (body?: DashboardCheckInRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Dashboard/GetDashboardCheckInChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardPieChartPost: async (body?: DashboardChartRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Dashboard/GetDashboardPieChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DashboardCardsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDashboardGetDashboardCardsPost(body?: DashboardCardsRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardCardsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDashboardGetDashboardCardsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDashboardGetDashboardChartPost(body?: DashboardChartRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDashboardGetDashboardChartPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardCheckInRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDashboardGetDashboardCheckInChartPost(body?: DashboardCheckInRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardCheckInResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDashboardGetDashboardCheckInChartPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDashboardGetDashboardPieChartPost(body?: DashboardChartRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardPieChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDashboardGetDashboardPieChartPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {DashboardCardsRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardCardsPost(body?: DashboardCardsRequestDto, options?: any): AxiosPromise<DashboardCardsResponseDto> {
            return localVarFp.webapiDashboardGetDashboardCardsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardChartPost(body?: DashboardChartRequestDto, options?: any): AxiosPromise<DashboardChartResponseDto> {
            return localVarFp.webapiDashboardGetDashboardChartPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardCheckInRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardCheckInChartPost(body?: DashboardCheckInRequestDto, options?: any): AxiosPromise<DashboardCheckInResponseDto> {
            return localVarFp.webapiDashboardGetDashboardCheckInChartPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DashboardChartRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDashboardGetDashboardPieChartPost(body?: DashboardChartRequestDto, options?: any): AxiosPromise<DashboardPieChartResponseDto> {
            return localVarFp.webapiDashboardGetDashboardPieChartPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {DashboardCardsRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public webapiDashboardGetDashboardCardsPost(body?: DashboardCardsRequestDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).webapiDashboardGetDashboardCardsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardChartRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public webapiDashboardGetDashboardChartPost(body?: DashboardChartRequestDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).webapiDashboardGetDashboardChartPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardCheckInRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public webapiDashboardGetDashboardCheckInChartPost(body?: DashboardCheckInRequestDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).webapiDashboardGetDashboardCheckInChartPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DashboardChartRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public webapiDashboardGetDashboardPieChartPost(body?: DashboardChartRequestDto, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).webapiDashboardGetDashboardPieChartPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiningHallApi - axios parameter creator
 * @export
 */
export const DiningHallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallAddPost: async (body?: DiningHallDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallGetByBuildingIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/GetByBuildingId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallListByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/ListByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallUpdatePut: async (body?: DiningHallDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHall/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiningHallApi - functional programming interface
 * @export
 */
export const DiningHallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiningHallApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallAddPost(body?: DiningHallDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallGetByBuildingIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiningHallResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallGetByBuildingIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiningHallListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiningHall>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallListByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallUpdatePut(body?: DiningHallDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiningHallApi - factory interface
 * @export
 */
export const DiningHallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiningHallApiFp(configuration)
    return {
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallAddPost(body?: DiningHallDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallGetByBuildingIdGet(body?: number, options?: any): AxiosPromise<DiningHallResponse> {
            return localVarFp.webapiDiningHallGetByBuildingIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallListAsyncGet(options?: any): AxiosPromise<Array<DiningHallListDto>> {
            return localVarFp.webapiDiningHallListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallListByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<Array<DiningHall>> {
            return localVarFp.webapiDiningHallListByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DiningHallDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallUpdatePut(body?: DiningHallDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiningHallApi - object-oriented interface
 * @export
 * @class DiningHallApi
 * @extends {BaseAPI}
 */
export class DiningHallApi extends BaseAPI {
    /**
     * 
     * @param {DiningHallDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallAddPost(body?: DiningHallDto, options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallGetByBuildingIdGet(body?: number, options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallGetByBuildingIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallListAsyncGet(options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallListByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DiningHallDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallApi
     */
    public webapiDiningHallUpdatePut(body?: DiningHallDto, options?: AxiosRequestConfig) {
        return DiningHallApiFp(this.configuration).webapiDiningHallUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiningHallMenuApi - axios parameter creator
 * @export
 */
export const DiningHallMenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuAddPost: async (body?: DiningHallMenuDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHallMenu/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHallMenu/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [diningHallId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet: async (diningHallId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHallMenu/GetDiningHallMenuByDiningHallId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (diningHallId !== undefined) {
                localVarQueryParameter['diningHallId'] = diningHallId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHallMenu/ListDiningHallsByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuUpdatePut: async (body?: DiningHallMenuDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/DiningHallMenu/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiningHallMenuApi - functional programming interface
 * @export
 */
export const DiningHallMenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiningHallMenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallMenuAddPost(body?: DiningHallMenuDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallMenuAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallMenuDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallMenuDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [diningHallId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiningHallMenuListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiningHallResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiDiningHallMenuUpdatePut(body?: DiningHallMenuDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiDiningHallMenuUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiningHallMenuApi - factory interface
 * @export
 */
export const DiningHallMenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiningHallMenuApiFp(configuration)
    return {
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuAddPost(body?: DiningHallMenuDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallMenuAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallMenuDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [diningHallId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId?: number, options?: any): AxiosPromise<DiningHallMenuListDtoListResponse> {
            return localVarFp.webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<DiningHallResponse> {
            return localVarFp.webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DiningHallMenuDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiDiningHallMenuUpdatePut(body?: DiningHallMenuDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiDiningHallMenuUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiningHallMenuApi - object-oriented interface
 * @export
 * @class DiningHallMenuApi
 * @extends {BaseAPI}
 */
export class DiningHallMenuApi extends BaseAPI {
    /**
     * 
     * @param {DiningHallMenuDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallMenuApi
     */
    public webapiDiningHallMenuAddPost(body?: DiningHallMenuDto, options?: AxiosRequestConfig) {
        return DiningHallMenuApiFp(this.configuration).webapiDiningHallMenuAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallMenuApi
     */
    public webapiDiningHallMenuDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return DiningHallMenuApiFp(this.configuration).webapiDiningHallMenuDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [diningHallId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallMenuApi
     */
    public webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId?: number, options?: AxiosRequestConfig) {
        return DiningHallMenuApiFp(this.configuration).webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(diningHallId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallMenuApi
     */
    public webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return DiningHallMenuApiFp(this.configuration).webapiDiningHallMenuListDiningHallsByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DiningHallMenuDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiningHallMenuApi
     */
    public webapiDiningHallMenuUpdatePut(body?: DiningHallMenuDto, options?: AxiosRequestConfig) {
        return DiningHallMenuApiFp(this.configuration).webapiDiningHallMenuUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FloorApi - axios parameter creator
 * @export
 */
export const FloorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Floor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorAddPost: async (body?: Floor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorAddV2Post: async (body?: FloorAndWorkingAreaDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/AddV2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeleteAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/DeleteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorListByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/ListByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorListDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorUpdatePut: async (body?: FloorListDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Floor/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorApi - functional programming interface
 * @export
 */
export const FloorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Floor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorAddPost(body?: Floor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorAddV2Post(body?: FloorAndWorkingAreaDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorAddV2Post(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeleteAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeleteAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FloorListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FloorBuildingDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorListByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorListDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorUpdatePut(body?: FloorListDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloorApi - factory interface
 * @export
 */
export const FloorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorApiFp(configuration)
    return {
        /**
         * 
         * @param {Floor} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorAddPost(body?: Floor, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorAddV2Post(body?: FloorAndWorkingAreaDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorAddV2Post(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeleteAsyncPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorDeleteAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorListAsyncGet(options?: any): AxiosPromise<Array<FloorListDto>> {
            return localVarFp.webapiFloorListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorListByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<Array<FloorBuildingDto>> {
            return localVarFp.webapiFloorListByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorListDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorUpdatePut(body?: FloorListDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloorApi - object-oriented interface
 * @export
 * @class FloorApi
 * @extends {BaseAPI}
 */
export class FloorApi extends BaseAPI {
    /**
     * 
     * @param {Floor} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorAddPost(body?: Floor, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorAndWorkingAreaDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorAddV2Post(body?: FloorAndWorkingAreaDto, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorAddV2Post(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorDeleteAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorDeleteAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorListAsyncGet(options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorListByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorListDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public webapiFloorUpdatePut(body?: FloorListDto, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).webapiFloorUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FloorDeskApi - axios parameter creator
 * @export
 */
export const FloorDeskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskAddPost: async (body?: FloorDesk, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorDeskAddWithRangeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskAddWithRangePost: async (body?: FloorDeskAddWithRangeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/AddWithRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskFillTheNullQrGuidGet: async (tenantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/FillTheNullQrGuid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDesksFilterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetDeskListPost: async (body?: FloorAndWorkingAreaDesksFilterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/GetDeskList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [floorPlanImageFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetFileUrlAsyncGet: async (floorPlanImageFileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/GetFileUrlAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorPlanImageFileName !== undefined) {
                localVarQueryParameter['floorPlanImageFileName'] = floorPlanImageFileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetListByFloorIdAsyncGet: async (floorId?: number, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/GetListByFloorIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskUpdatePut: async (body?: FloorDesk, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/FloorDesk/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorDeskApi - functional programming interface
 * @export
 */
export const FloorDeskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorDeskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskAddPost(body?: FloorDesk, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorDeskAddWithRangeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskAddWithRangePost(body?: FloorDeskAddWithRangeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskAddWithRangePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskFillTheNullQrGuidGet(tenantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskFillTheNullQrGuidGet(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDeskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDesksFilterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskGetDeskListPost(body?: FloorAndWorkingAreaDesksFilterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorAndWorkingAreaDesksDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskGetDeskListPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [floorPlanImageFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskGetListByFloorIdAsyncGet(floorId?: number, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDeskDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskGetListByFloorIdAsyncGet(floorId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiFloorDeskUpdatePut(body?: FloorDesk, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiFloorDeskUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloorDeskApi - factory interface
 * @export
 */
export const FloorDeskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorDeskApiFp(configuration)
    return {
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskAddPost(body?: FloorDesk, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorDeskAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorDeskAddWithRangeDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskAddWithRangePost(body?: FloorDeskAddWithRangeDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorDeskAddWithRangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorDeskDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskFillTheNullQrGuidGet(tenantId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.webapiFloorDeskFillTheNullQrGuidGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetByIdGet(body?: number, options?: any): AxiosPromise<FloorDeskResponse> {
            return localVarFp.webapiFloorDeskGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorAndWorkingAreaDesksFilterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetDeskListPost(body?: FloorAndWorkingAreaDesksFilterDto, options?: any): AxiosPromise<FloorAndWorkingAreaDesksDtoListResponse> {
            return localVarFp.webapiFloorDeskGetDeskListPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [floorPlanImageFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskGetListByFloorIdAsyncGet(floorId?: number, date?: string, options?: any): AxiosPromise<FloorDeskDto> {
            return localVarFp.webapiFloorDeskGetListByFloorIdAsyncGet(floorId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiFloorDeskUpdatePut(body?: FloorDesk, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiFloorDeskUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FloorDeskApi - object-oriented interface
 * @export
 * @class FloorDeskApi
 * @extends {BaseAPI}
 */
export class FloorDeskApi extends BaseAPI {
    /**
     * 
     * @param {FloorDesk} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskAddPost(body?: FloorDesk, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorDeskAddWithRangeDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskAddWithRangePost(body?: FloorDeskAddWithRangeDto, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskAddWithRangePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskFillTheNullQrGuidGet(tenantId?: number, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskFillTheNullQrGuidGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorAndWorkingAreaDesksFilterDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskGetDeskListPost(body?: FloorAndWorkingAreaDesksFilterDto, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskGetDeskListPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [floorPlanImageFileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName?: string, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskGetFileUrlAsyncGet(floorPlanImageFileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [floorId] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskGetListByFloorIdAsyncGet(floorId?: number, date?: string, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskGetListByFloorIdAsyncGet(floorId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorDesk} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorDeskApi
     */
    public webapiFloorDeskUpdatePut(body?: FloorDesk, options?: AxiosRequestConfig) {
        return FloorDeskApiFp(this.configuration).webapiFloorDeskUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationDownloadIntegrationInfoPdfPost: async (integrationType?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/DownloadIntegrationInfoPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (integrationType !== undefined) {
                localVarQueryParameter['IntegrationType'] = integrationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetAzureParametersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/GetAzureParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetGoogleWorkspaceParametersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/GetGoogleWorkspaceParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetIntegrationTypeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/GetIntegrationType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationRemoveAzureParametersPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/RemoveAzureParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationRemoveGoogleWorkSpaceParametersPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/RemoveGoogleWorkSpaceParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantAzureADParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveAzureParametersPost: async (body?: TenantAzureADParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/SaveAzureParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantGoogleWorkspaceParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveGoogleWorkSpaceParametersPost: async (body?: TenantGoogleWorkspaceParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/SaveGoogleWorkSpaceParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveIntegrationTypeNonePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Integration/SaveIntegrationTypeNone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationGetAzureParametersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAzureADParameterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationGetAzureParametersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationGetGoogleWorkspaceParametersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantGoogleWorkspaceParameterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationGetGoogleWorkspaceParametersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationGetIntegrationTypeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationGetIntegrationTypeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationRemoveAzureParametersPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationRemoveAzureParametersPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TenantAzureADParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationSaveAzureParametersPost(body?: TenantAzureADParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationSaveAzureParametersPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TenantGoogleWorkspaceParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationSaveGoogleWorkSpaceParametersPost(body?: TenantGoogleWorkspaceParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationSaveGoogleWorkSpaceParametersPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiIntegrationSaveIntegrationTypeNonePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiIntegrationSaveIntegrationTypeNonePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [integrationType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType?: number, options?: any): AxiosPromise<string> {
            return localVarFp.webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetAzureParametersGet(options?: any): AxiosPromise<TenantAzureADParameterResponse> {
            return localVarFp.webapiIntegrationGetAzureParametersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetGoogleWorkspaceParametersGet(options?: any): AxiosPromise<TenantGoogleWorkspaceParameterResponse> {
            return localVarFp.webapiIntegrationGetGoogleWorkspaceParametersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationGetIntegrationTypeGet(options?: any): AxiosPromise<Int32Response> {
            return localVarFp.webapiIntegrationGetIntegrationTypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationRemoveAzureParametersPost(options?: any): AxiosPromise<Response> {
            return localVarFp.webapiIntegrationRemoveAzureParametersPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options?: any): AxiosPromise<Response> {
            return localVarFp.webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAzureADParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveAzureParametersPost(body?: TenantAzureADParameter, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiIntegrationSaveAzureParametersPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantGoogleWorkspaceParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveGoogleWorkSpaceParametersPost(body?: TenantGoogleWorkspaceParameter, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiIntegrationSaveGoogleWorkSpaceParametersPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiIntegrationSaveIntegrationTypeNonePost(options?: any): AxiosPromise<Response> {
            return localVarFp.webapiIntegrationSaveIntegrationTypeNonePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * 
     * @param {number} [integrationType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType?: number, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationDownloadIntegrationInfoPdfPost(integrationType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationGetAzureParametersGet(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationGetAzureParametersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationGetGoogleWorkspaceParametersGet(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationGetGoogleWorkspaceParametersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationGetIntegrationTypeGet(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationGetIntegrationTypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationRemoveAzureParametersPost(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationRemoveAzureParametersPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationRemoveGoogleWorkSpaceParametersPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAzureADParameter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationSaveAzureParametersPost(body?: TenantAzureADParameter, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationSaveAzureParametersPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantGoogleWorkspaceParameter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationSaveGoogleWorkSpaceParametersPost(body?: TenantGoogleWorkspaceParameter, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationSaveGoogleWorkSpaceParametersPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public webapiIntegrationSaveIntegrationTypeNonePost(options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).webapiIntegrationSaveIntegrationTypeNonePost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileAppVersionApi - axios parameter creator
 * @export
 */
export const MobileAppVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMobileAppVersionGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/MobileAppVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MobileAppVersion} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMobileAppVersionPost: async (body?: MobileAppVersion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/MobileAppVersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileAppVersionApi - functional programming interface
 * @export
 */
export const MobileAppVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileAppVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiMobileAppVersionGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileAppVersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiMobileAppVersionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MobileAppVersion} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiMobileAppVersionPost(body?: MobileAppVersion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiMobileAppVersionPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileAppVersionApi - factory interface
 * @export
 */
export const MobileAppVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileAppVersionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMobileAppVersionGet(options?: any): AxiosPromise<MobileAppVersion> {
            return localVarFp.webapiMobileAppVersionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileAppVersion} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMobileAppVersionPost(body?: MobileAppVersion, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiMobileAppVersionPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileAppVersionApi - object-oriented interface
 * @export
 * @class MobileAppVersionApi
 * @extends {BaseAPI}
 */
export class MobileAppVersionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppVersionApi
     */
    public webapiMobileAppVersionGet(options?: AxiosRequestConfig) {
        return MobileAppVersionApiFp(this.configuration).webapiMobileAppVersionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileAppVersion} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileAppVersionApi
     */
    public webapiMobileAppVersionPost(body?: MobileAppVersion, options?: AxiosRequestConfig) {
        return MobileAppVersionApiFp(this.configuration).webapiMobileAppVersionPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsGraphApi - axios parameter creator
 * @export
 */
export const MsGraphApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMsGraphUserListAsyncGet: async (tenantId?: number, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/MsGraph/UserListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsGraphApi - functional programming interface
 * @export
 */
export const MsGraphApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsGraphApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiMsGraphUserListAsyncGet(tenantId?: number, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Int32Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiMsGraphUserListAsyncGet(tenantId, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsGraphApi - factory interface
 * @export
 */
export const MsGraphApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsGraphApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMsGraphUserListAsyncGet(tenantId?: number, filter?: string, options?: any): AxiosPromise<Int32Response> {
            return localVarFp.webapiMsGraphUserListAsyncGet(tenantId, filter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsGraphApi - object-oriented interface
 * @export
 * @class MsGraphApi
 * @extends {BaseAPI}
 */
export class MsGraphApi extends BaseAPI {
    /**
     * 
     * @param {number} [tenantId] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsGraphApi
     */
    public webapiMsGraphUserListAsyncGet(tenantId?: number, filter?: string, options?: AxiosRequestConfig) {
        return MsGraphApiFp(this.configuration).webapiMsGraphUserListAsyncGet(tenantId, filter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MultiUserDeskReservationApi - axios parameter creator
 * @export
 */
export const MultiUserDeskReservationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MultiReservationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMultiUserDeskReservationAddMultiReservationPost: async (body?: MultiReservationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/MultiUserDeskReservation/AddMultiReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultiUserDeskReservationApi - functional programming interface
 * @export
 */
export const MultiUserDeskReservationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultiUserDeskReservationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MultiReservationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiMultiUserDeskReservationAddMultiReservationPost(body?: MultiReservationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiMultiUserDeskReservationAddMultiReservationPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultiUserDeskReservationApi - factory interface
 * @export
 */
export const MultiUserDeskReservationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultiUserDeskReservationApiFp(configuration)
    return {
        /**
         * 
         * @param {MultiReservationDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiMultiUserDeskReservationAddMultiReservationPost(body?: MultiReservationDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiMultiUserDeskReservationAddMultiReservationPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultiUserDeskReservationApi - object-oriented interface
 * @export
 * @class MultiUserDeskReservationApi
 * @extends {BaseAPI}
 */
export class MultiUserDeskReservationApi extends BaseAPI {
    /**
     * 
     * @param {MultiReservationDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiUserDeskReservationApi
     */
    public webapiMultiUserDeskReservationAddMultiReservationPost(body?: MultiReservationDto, options?: AxiosRequestConfig) {
        return MultiUserDeskReservationApiFp(this.configuration).webapiMultiUserDeskReservationAddMultiReservationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PolicyApi - axios parameter creator
 * @export
 */
export const PolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyAddPolicyPost: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Policy/AddPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyDeletePolicyPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Policy/DeletePolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [magicLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyGetLinkPolicyGet: async (magicLink?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Policy/GetLinkPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (magicLink !== undefined) {
                localVarQueryParameter['magicLink'] = magicLink;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyGetPolicyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Policy/GetPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolicyApi - functional programming interface
 * @export
 */
export const PolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPolicyAddPolicyPost(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPolicyAddPolicyPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPolicyDeletePolicyPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPolicyDeletePolicyPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [magicLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPolicyGetLinkPolicyGet(magicLink?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPolicyGetLinkPolicyGet(magicLink, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPolicyGetPolicyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PolicyGetDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPolicyGetPolicyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolicyApi - factory interface
 * @export
 */
export const PolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyAddPolicyPost(file?: any, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiPolicyAddPolicyPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyDeletePolicyPost(options?: any): AxiosPromise<Response> {
            return localVarFp.webapiPolicyDeletePolicyPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [magicLink] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyGetLinkPolicyGet(magicLink?: string, options?: any): AxiosPromise<string> {
            return localVarFp.webapiPolicyGetLinkPolicyGet(magicLink, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPolicyGetPolicyGet(options?: any): AxiosPromise<PolicyGetDtoResponse> {
            return localVarFp.webapiPolicyGetPolicyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PolicyApi - object-oriented interface
 * @export
 * @class PolicyApi
 * @extends {BaseAPI}
 */
export class PolicyApi extends BaseAPI {
    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public webapiPolicyAddPolicyPost(file?: any, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).webapiPolicyAddPolicyPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public webapiPolicyDeletePolicyPost(options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).webapiPolicyDeletePolicyPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [magicLink] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public webapiPolicyGetLinkPolicyGet(magicLink?: string, options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).webapiPolicyGetLinkPolicyGet(magicLink, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApi
     */
    public webapiPolicyGetPolicyGet(options?: AxiosRequestConfig) {
        return PolicyApiFp(this.configuration).webapiPolicyGetPolicyGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushNotificationApi - axios parameter creator
 * @export
 */
export const PushNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OnComingReservationOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost: async (body?: OnComingReservationOptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/PushNotification/AddOrUpdateOnComingReservationOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationGetBuildingReservationNotificationParameterPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/PushNotification/GetBuildingReservationNotificationParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationGetTenantReservationNotificationParameterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/PushNotification/GetTenantReservationNotificationParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushNotificationApi - functional programming interface
 * @export
 */
export const PushNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OnComingReservationOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body?: OnComingReservationOptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPushNotificationGetBuildingReservationNotificationParameterPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPushNotificationGetBuildingReservationNotificationParameterPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiPushNotificationGetTenantReservationNotificationParameterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantReservationNotificationParameterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiPushNotificationGetTenantReservationNotificationParameterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushNotificationApi - factory interface
 * @export
 */
export const PushNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushNotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {OnComingReservationOptionDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body?: OnComingReservationOptionDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationGetBuildingReservationNotificationParameterPost(body?: number, options?: any): AxiosPromise<BuildingResponse> {
            return localVarFp.webapiPushNotificationGetBuildingReservationNotificationParameterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiPushNotificationGetTenantReservationNotificationParameterGet(options?: any): AxiosPromise<TenantReservationNotificationParameterResponse> {
            return localVarFp.webapiPushNotificationGetTenantReservationNotificationParameterGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushNotificationApi - object-oriented interface
 * @export
 * @class PushNotificationApi
 * @extends {BaseAPI}
 */
export class PushNotificationApi extends BaseAPI {
    /**
     * 
     * @param {OnComingReservationOptionDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body?: OnComingReservationOptionDto, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public webapiPushNotificationGetBuildingReservationNotificationParameterPost(body?: number, options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).webapiPushNotificationGetBuildingReservationNotificationParameterPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationApi
     */
    public webapiPushNotificationGetTenantReservationNotificationParameterGet(options?: AxiosRequestConfig) {
        return PushNotificationApiFp(this.configuration).webapiPushNotificationGetTenantReservationNotificationParameterGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeskReportFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportAddDeskReportFilterPost: async (body?: DeskReportFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/AddDeskReportFilter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeleteDeskReportFilterPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/DeleteDeskReportFilter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeleteDeskReservationPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/DeleteDeskReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeskReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeskReportListAsyncPost: async (body?: DeskReportListRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/DeskReportListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportListDeskReportFiltersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/ListDeskReportFilters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportMeetingRoomReportExportExcelPost: async (body?: MeetingRoomReportListRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/MeetingRoomReportExportExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportMeetingRoomReportListAsyncPost: async (body?: MeetingRoomReportListRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Report/MeetingRoomReportListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeskReportFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportAddDeskReportFilterPost(body?: DeskReportFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportAddDeskReportFilterPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportDeleteDeskReportFilterPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportDeleteDeskReportFilterPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportDeleteDeskReservationPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportDeleteDeskReservationPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeskReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportDeskReportListAsyncPost(body?: DeskReportListRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeskReportListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportDeskReportListAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportListDeskReportFiltersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeskReportFilterListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportListDeskReportFiltersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportMeetingRoomReportExportExcelPost(body?: MeetingRoomReportListRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportMeetingRoomReportExportExcelPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiReportMeetingRoomReportListAsyncPost(body?: MeetingRoomReportListRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingRoomReportListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiReportMeetingRoomReportListAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @param {DeskReportFilter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportAddDeskReportFilterPost(body?: DeskReportFilter, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiReportAddDeskReportFilterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeleteDeskReportFilterPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiReportDeleteDeskReportFilterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeleteDeskReservationPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiReportDeleteDeskReservationPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeskReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportDeskReportListAsyncPost(body?: DeskReportListRequestDto, options?: any): AxiosPromise<Array<DeskReportListDto>> {
            return localVarFp.webapiReportDeskReportListAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportListDeskReportFiltersGet(options?: any): AxiosPromise<DeskReportFilterListResponse> {
            return localVarFp.webapiReportListDeskReportFiltersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportMeetingRoomReportExportExcelPost(body?: MeetingRoomReportListRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.webapiReportMeetingRoomReportExportExcelPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MeetingRoomReportListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiReportMeetingRoomReportListAsyncPost(body?: MeetingRoomReportListRequestDto, options?: any): AxiosPromise<Array<MeetingRoomReportListDto>> {
            return localVarFp.webapiReportMeetingRoomReportListAsyncPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @param {DeskReportFilter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportAddDeskReportFilterPost(body?: DeskReportFilter, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportAddDeskReportFilterPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportDeleteDeskReportFilterPost(body?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportDeleteDeskReportFilterPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportDeleteDeskReservationPost(body?: number, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportDeleteDeskReservationPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeskReportListRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportDeskReportListAsyncPost(body?: DeskReportListRequestDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportDeskReportListAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportListDeskReportFiltersGet(options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportListDeskReportFiltersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MeetingRoomReportListRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportMeetingRoomReportExportExcelPost(body?: MeetingRoomReportListRequestDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportMeetingRoomReportExportExcelPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MeetingRoomReportListRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public webapiReportMeetingRoomReportListAsyncPost(body?: MeetingRoomReportListRequestDto, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).webapiReportMeetingRoomReportListAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomAddPost: async (body?: RoomAddOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomGetByFloorIdAsyncGet: async (floorId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/GetByFloorIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoomListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListByBuildingIdAndFloorIdPost: async (body?: RoomListRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/ListByBuildingIdAndFloorId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListByTenantIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/ListByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomUpdatePut: async (body?: RoomAddOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Room/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomAddPost(body?: RoomAddOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomGetByFloorIdAsyncGet(floorId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Room>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomGetByFloorIdAsyncGet(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoomListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomListByBuildingIdAndFloorIdPost(body?: RoomListRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardRoomListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomListByBuildingIdAndFloorIdPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomListByTenantIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomListByTenantIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomUpdatePut(body?: RoomAddOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomAddPost(body?: RoomAddOrUpdateDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomGetByFloorIdAsyncGet(floorId?: number, options?: any): AxiosPromise<Array<Room>> {
            return localVarFp.webapiRoomGetByFloorIdAsyncGet(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomGetByIdGet(body?: number, options?: any): AxiosPromise<RoomResponse> {
            return localVarFp.webapiRoomGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListAsyncGet(options?: any): AxiosPromise<RoomListDtoListResponse> {
            return localVarFp.webapiRoomListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoomListRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListByBuildingIdAndFloorIdPost(body?: RoomListRequestDto, options?: any): AxiosPromise<DashboardRoomListDtoListResponse> {
            return localVarFp.webapiRoomListByBuildingIdAndFloorIdPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomListByTenantIdGet(body?: number, options?: any): AxiosPromise<RoomListDtoListResponse> {
            return localVarFp.webapiRoomListByTenantIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoomAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomUpdatePut(body?: RoomAddOrUpdateDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @param {RoomAddOrUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomAddPost(body?: RoomAddOrUpdateDto, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomDeletePost(body?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [floorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomGetByFloorIdAsyncGet(floorId?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomGetByFloorIdAsyncGet(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomListAsyncGet(options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoomListRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomListByBuildingIdAndFloorIdPost(body?: RoomListRequestDto, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomListByBuildingIdAndFloorIdPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomListByTenantIdGet(body?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomListByTenantIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoomAddOrUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public webapiRoomUpdatePut(body?: RoomAddOrUpdateDto, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).webapiRoomUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomReservationManagementApi - axios parameter creator
 * @export
 */
export const RoomReservationManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementAddPost: async (body?: RoomReservationManagement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomReservationManagement/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomReservationManagement/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementListGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomReservationManagement/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementUpdatePut: async (body?: RoomReservationManagement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomReservationManagement/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomReservationManagementApi - functional programming interface
 * @export
 */
export const RoomReservationManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomReservationManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomReservationManagementAddPost(body?: RoomReservationManagement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomReservationManagementAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomReservationManagementDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomReservationManagementDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomReservationManagementListGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomReservationManagementListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomReservationManagementListGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomReservationManagementUpdatePut(body?: RoomReservationManagement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomReservationManagementUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomReservationManagementApi - factory interface
 * @export
 */
export const RoomReservationManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomReservationManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementAddPost(body?: RoomReservationManagement, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomReservationManagementAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomReservationManagementDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementListGet(body?: number, options?: any): AxiosPromise<RoomReservationManagementListResponse> {
            return localVarFp.webapiRoomReservationManagementListGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoomReservationManagement} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomReservationManagementUpdatePut(body?: RoomReservationManagement, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomReservationManagementUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomReservationManagementApi - object-oriented interface
 * @export
 * @class RoomReservationManagementApi
 * @extends {BaseAPI}
 */
export class RoomReservationManagementApi extends BaseAPI {
    /**
     * 
     * @param {RoomReservationManagement} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomReservationManagementApi
     */
    public webapiRoomReservationManagementAddPost(body?: RoomReservationManagement, options?: AxiosRequestConfig) {
        return RoomReservationManagementApiFp(this.configuration).webapiRoomReservationManagementAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomReservationManagementApi
     */
    public webapiRoomReservationManagementDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return RoomReservationManagementApiFp(this.configuration).webapiRoomReservationManagementDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomReservationManagementApi
     */
    public webapiRoomReservationManagementListGet(body?: number, options?: AxiosRequestConfig) {
        return RoomReservationManagementApiFp(this.configuration).webapiRoomReservationManagementListGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoomReservationManagement} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomReservationManagementApi
     */
    public webapiRoomReservationManagementUpdatePut(body?: RoomReservationManagement, options?: AxiosRequestConfig) {
        return RoomReservationManagementApiFp(this.configuration).webapiRoomReservationManagementUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomServiceApi - axios parameter creator
 * @export
 */
export const RoomServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceAddPost: async (body?: RoomService, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomService/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomService/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceGetByIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomService/GetById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomService/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceUpdatePut: async (body?: RoomService, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/RoomService/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomServiceApi - functional programming interface
 * @export
 */
export const RoomServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomServiceAddPost(body?: RoomService, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomServiceAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomServiceDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomServiceDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomServiceGetByIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomServiceGetByIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomServiceListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomServiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomServiceListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRoomServiceUpdatePut(body?: RoomService, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRoomServiceUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomServiceApi - factory interface
 * @export
 */
export const RoomServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceAddPost(body?: RoomService, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomServiceAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomServiceDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceGetByIdGet(body?: number, options?: any): AxiosPromise<RoomServiceResponse> {
            return localVarFp.webapiRoomServiceGetByIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceListGet(options?: any): AxiosPromise<RoomServiceListResponse> {
            return localVarFp.webapiRoomServiceListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RoomService} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRoomServiceUpdatePut(body?: RoomService, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRoomServiceUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomServiceApi - object-oriented interface
 * @export
 * @class RoomServiceApi
 * @extends {BaseAPI}
 */
export class RoomServiceApi extends BaseAPI {
    /**
     * 
     * @param {RoomService} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomServiceApi
     */
    public webapiRoomServiceAddPost(body?: RoomService, options?: AxiosRequestConfig) {
        return RoomServiceApiFp(this.configuration).webapiRoomServiceAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomServiceApi
     */
    public webapiRoomServiceDeletePost(body?: number, options?: AxiosRequestConfig) {
        return RoomServiceApiFp(this.configuration).webapiRoomServiceDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomServiceApi
     */
    public webapiRoomServiceGetByIdGet(body?: number, options?: AxiosRequestConfig) {
        return RoomServiceApiFp(this.configuration).webapiRoomServiceGetByIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomServiceApi
     */
    public webapiRoomServiceListGet(options?: AxiosRequestConfig) {
        return RoomServiceApiFp(this.configuration).webapiRoomServiceListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RoomService} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomServiceApi
     */
    public webapiRoomServiceUpdatePut(body?: RoomService, options?: AxiosRequestConfig) {
        return RoomServiceApiFp(this.configuration).webapiRoomServiceUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RuleApi - axios parameter creator
 * @export
 */
export const RuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddDepartmentPost: async (body?: Department, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/AddDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateCarParkReportOptionsPost: async (body?: ReportOptionsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/AddOrUpdateCarParkReportOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateDiningHallReportOptionsPost: async (body?: ReportOptionsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/AddOrUpdateDiningHallReportOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckinAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateUseCheckinPost: async (body?: CheckinAddOrUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/AddOrUpdateUseCheckin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddTitlePost: async (body?: Title, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/AddTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleDeleteDepartmentDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/DeleteDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleDeleteTitleDelete: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/DeleteTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetDepartmentsByTenantIdAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/GetDepartmentsByTenantIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetRulesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/GetRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetSubCompaniesByTenantIdAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/GetSubCompaniesByTenantIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetTenantParameterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/GetTenantParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetTitlesByTenantIdAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/GetTitlesByTenantIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingServicesPreferencesDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateBuildingServicesPreferencesPost: async (body?: BuildingServicesPreferencesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/UpdateBuildingServicesPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateDepartmentPost: async (body?: Department, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/UpdateDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RulesRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateRulesPost: async (body?: RulesRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/UpdateRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateTitlePost: async (body?: Title, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Rule/UpdateTitle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleApi - functional programming interface
 * @export
 */
export const RuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleAddDepartmentPost(body?: Department, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleAddDepartmentPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleAddOrUpdateCarParkReportOptionsPost(body?: ReportOptionsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleAddOrUpdateCarParkReportOptionsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleAddOrUpdateDiningHallReportOptionsPost(body?: ReportOptionsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleAddOrUpdateDiningHallReportOptionsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckinAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleAddOrUpdateUseCheckinPost(body?: CheckinAddOrUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleAddOrUpdateUseCheckinPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleAddTitlePost(body?: Title, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleAddTitlePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleDeleteDepartmentDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleDeleteDepartmentDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleDeleteTitleDelete(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleDeleteTitleDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleGetDepartmentsByTenantIdAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleGetDepartmentsByTenantIdAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleGetRulesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RulesResponseDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleGetRulesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleGetSubCompaniesByTenantIdAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubCompanyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleGetSubCompaniesByTenantIdAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleGetTenantParameterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantParameterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleGetTenantParameterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleGetTitlesByTenantIdAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleGetTitlesByTenantIdAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BuildingServicesPreferencesDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleUpdateBuildingServicesPreferencesPost(body?: BuildingServicesPreferencesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleUpdateBuildingServicesPreferencesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleUpdateDepartmentPost(body?: Department, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleUpdateDepartmentPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RulesRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleUpdateRulesPost(body?: RulesRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleUpdateRulesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiRuleUpdateTitlePost(body?: Title, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiRuleUpdateTitlePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RuleApi - factory interface
 * @export
 */
export const RuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuleApiFp(configuration)
    return {
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddDepartmentPost(body?: Department, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleAddDepartmentPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateCarParkReportOptionsPost(body?: ReportOptionsDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleAddOrUpdateCarParkReportOptionsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportOptionsDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateDiningHallReportOptionsPost(body?: ReportOptionsDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleAddOrUpdateDiningHallReportOptionsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckinAddOrUpdateDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddOrUpdateUseCheckinPost(body?: CheckinAddOrUpdateDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleAddOrUpdateUseCheckinPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleAddTitlePost(body?: Title, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleAddTitlePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleDeleteDepartmentDelete(id?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleDeleteDepartmentDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleDeleteTitleDelete(id?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleDeleteTitleDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetDepartmentsByTenantIdAsyncGet(options?: any): AxiosPromise<DepartmentListDtoListResponse> {
            return localVarFp.webapiRuleGetDepartmentsByTenantIdAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetRulesGet(options?: any): AxiosPromise<RulesResponseDtoResponse> {
            return localVarFp.webapiRuleGetRulesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetSubCompaniesByTenantIdAsyncGet(options?: any): AxiosPromise<SubCompanyListResponse> {
            return localVarFp.webapiRuleGetSubCompaniesByTenantIdAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetTenantParameterGet(options?: any): AxiosPromise<TenantParameterResponse> {
            return localVarFp.webapiRuleGetTenantParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleGetTitlesByTenantIdAsyncGet(options?: any): AxiosPromise<TitleListDtoListResponse> {
            return localVarFp.webapiRuleGetTitlesByTenantIdAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BuildingServicesPreferencesDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateBuildingServicesPreferencesPost(body?: BuildingServicesPreferencesDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleUpdateBuildingServicesPreferencesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Department} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateDepartmentPost(body?: Department, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleUpdateDepartmentPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RulesRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateRulesPost(body?: RulesRequestDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleUpdateRulesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Title} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiRuleUpdateTitlePost(body?: Title, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiRuleUpdateTitlePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RuleApi - object-oriented interface
 * @export
 * @class RuleApi
 * @extends {BaseAPI}
 */
export class RuleApi extends BaseAPI {
    /**
     * 
     * @param {Department} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleAddDepartmentPost(body?: Department, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleAddDepartmentPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportOptionsDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleAddOrUpdateCarParkReportOptionsPost(body?: ReportOptionsDto, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleAddOrUpdateCarParkReportOptionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportOptionsDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleAddOrUpdateDiningHallReportOptionsPost(body?: ReportOptionsDto, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleAddOrUpdateDiningHallReportOptionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckinAddOrUpdateDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleAddOrUpdateUseCheckinPost(body?: CheckinAddOrUpdateDto, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleAddOrUpdateUseCheckinPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Title} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleAddTitlePost(body?: Title, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleAddTitlePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleDeleteDepartmentDelete(id?: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleDeleteDepartmentDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleDeleteTitleDelete(id?: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleDeleteTitleDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleGetDepartmentsByTenantIdAsyncGet(options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleGetDepartmentsByTenantIdAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleGetRulesGet(options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleGetRulesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleGetSubCompaniesByTenantIdAsyncGet(options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleGetSubCompaniesByTenantIdAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleGetTenantParameterGet(options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleGetTenantParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleGetTitlesByTenantIdAsyncGet(options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleGetTitlesByTenantIdAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuildingServicesPreferencesDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleUpdateBuildingServicesPreferencesPost(body?: BuildingServicesPreferencesDto, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleUpdateBuildingServicesPreferencesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Department} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleUpdateDepartmentPost(body?: Department, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleUpdateDepartmentPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RulesRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleUpdateRulesPost(body?: RulesRequestDto, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleUpdateRulesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Title} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public webapiRuleUpdateTitlePost(body?: Title, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).webapiRuleUpdateTitlePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScriptApi - axios parameter creator
 * @export
 */
export const ScriptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiScriptDeskReservationLoadScriptPost: async (tenantId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Script/DeskReservationLoadScript`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tenantId !== undefined) {
                localVarQueryParameter['tenantId'] = tenantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScriptApi - functional programming interface
 * @export
 */
export const ScriptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScriptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiScriptDeskReservationLoadScriptPost(tenantId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiScriptDeskReservationLoadScriptPost(tenantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScriptApi - factory interface
 * @export
 */
export const ScriptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScriptApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiScriptDeskReservationLoadScriptPost(tenantId?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiScriptDeskReservationLoadScriptPost(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScriptApi - object-oriented interface
 * @export
 * @class ScriptApi
 * @extends {BaseAPI}
 */
export class ScriptApi extends BaseAPI {
    /**
     * 
     * @param {number} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptApi
     */
    public webapiScriptDeskReservationLoadScriptPost(tenantId?: number, options?: AxiosRequestConfig) {
        return ScriptApiFp(this.configuration).webapiScriptDeskReservationLoadScriptPost(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SendReportEmailApi - axios parameter creator
 * @export
 */
export const SendReportEmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSendReportEmailSendCarParkReportEmailPost: async (body?: ReportMailRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SendReportEmail/SendCarParkReportEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSendReportEmailSendDiningHallReportEmailPost: async (body?: ReportMailRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SendReportEmail/SendDiningHallReportEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SendReportEmailApi - functional programming interface
 * @export
 */
export const SendReportEmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SendReportEmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSendReportEmailSendCarParkReportEmailPost(body?: ReportMailRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSendReportEmailSendCarParkReportEmailPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSendReportEmailSendDiningHallReportEmailPost(body?: ReportMailRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSendReportEmailSendDiningHallReportEmailPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SendReportEmailApi - factory interface
 * @export
 */
export const SendReportEmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SendReportEmailApiFp(configuration)
    return {
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSendReportEmailSendCarParkReportEmailPost(body?: ReportMailRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.webapiSendReportEmailSendCarParkReportEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportMailRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSendReportEmailSendDiningHallReportEmailPost(body?: ReportMailRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.webapiSendReportEmailSendDiningHallReportEmailPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SendReportEmailApi - object-oriented interface
 * @export
 * @class SendReportEmailApi
 * @extends {BaseAPI}
 */
export class SendReportEmailApi extends BaseAPI {
    /**
     * 
     * @param {ReportMailRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendReportEmailApi
     */
    public webapiSendReportEmailSendCarParkReportEmailPost(body?: ReportMailRequestDto, options?: AxiosRequestConfig) {
        return SendReportEmailApiFp(this.configuration).webapiSendReportEmailSendCarParkReportEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportMailRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SendReportEmailApi
     */
    public webapiSendReportEmailSendDiningHallReportEmailPost(body?: ReportMailRequestDto, options?: AxiosRequestConfig) {
        return SendReportEmailApiFp(this.configuration).webapiSendReportEmailSendDiningHallReportEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SessionAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSessionAddPost: async (body?: SessionAddDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Session/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SessionAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSessionAddPost(body?: SessionAddDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSessionAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @param {SessionAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSessionAddPost(body?: SessionAddDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiSessionAddPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {SessionAddDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public webapiSessionAddPost(body?: SessionAddDto, options?: AxiosRequestConfig) {
        return SessionApiFp(this.configuration).webapiSessionAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShuttleApi - axios parameter creator
 * @export
 */
export const ShuttleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleAddPost: async (body?: Shuttle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleGetByShuttleIdGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/GetByShuttleId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/ListAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListByBuildingIdAsyncGet: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/ListByBuildingIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListByBuildingIdAsyncV2Get: async (buildingId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/ListByBuildingIdAsyncV2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['buildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleUpdatePut: async (body?: Shuttle, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Shuttle/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShuttleApi - functional programming interface
 * @export
 */
export const ShuttleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShuttleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleAddPost(body?: Shuttle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleGetByShuttleIdGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShuttleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleGetByShuttleIdGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleListAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShuttleListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleListAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleListByBuildingIdAsyncGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleListByBuildingIdAsyncV2Get(buildingId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Shuttle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleListByBuildingIdAsyncV2Get(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiShuttleUpdatePut(body?: Shuttle, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiShuttleUpdatePut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShuttleApi - factory interface
 * @export
 */
export const ShuttleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShuttleApiFp(configuration)
    return {
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleAddPost(body?: Shuttle, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiShuttleAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiShuttleDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleGetByShuttleIdGet(body?: number, options?: any): AxiosPromise<ShuttleResponse> {
            return localVarFp.webapiShuttleGetByShuttleIdGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListAsyncGet(options?: any): AxiosPromise<Array<ShuttleListDto>> {
            return localVarFp.webapiShuttleListAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListByBuildingIdAsyncGet(buildingId?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.webapiShuttleListByBuildingIdAsyncGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [buildingId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleListByBuildingIdAsyncV2Get(buildingId?: number, options?: any): AxiosPromise<Array<Shuttle>> {
            return localVarFp.webapiShuttleListByBuildingIdAsyncV2Get(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Shuttle} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiShuttleUpdatePut(body?: Shuttle, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiShuttleUpdatePut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShuttleApi - object-oriented interface
 * @export
 * @class ShuttleApi
 * @extends {BaseAPI}
 */
export class ShuttleApi extends BaseAPI {
    /**
     * 
     * @param {Shuttle} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleAddPost(body?: Shuttle, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleDeletePost(body?: number, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleGetByShuttleIdGet(body?: number, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleGetByShuttleIdGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleListAsyncGet(options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleListAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleListByBuildingIdAsyncGet(buildingId?: number, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleListByBuildingIdAsyncGet(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [buildingId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleListByBuildingIdAsyncV2Get(buildingId?: number, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleListByBuildingIdAsyncV2Get(buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Shuttle} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShuttleApi
     */
    public webapiShuttleUpdatePut(body?: Shuttle, options?: AxiosRequestConfig) {
        return ShuttleApiFp(this.configuration).webapiShuttleUpdatePut(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuperAdminApi - axios parameter creator
 * @export
 */
export const SuperAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddNewTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminAddNewTenantPost: async (body?: AddNewTenantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SuperAdmin/AddNewTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SuperAdmin/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminListTenantAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SuperAdmin/ListTenantAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminUpdateTenantPost: async (body?: UpdateTenantDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SuperAdmin/UpdateTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuperAdminApi - functional programming interface
 * @export
 */
export const SuperAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuperAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddNewTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSuperAdminAddNewTenantPost(body?: AddNewTenantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSuperAdminAddNewTenantPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSuperAdminDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSuperAdminDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSuperAdminListTenantAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TenantListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSuperAdminListTenantAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSuperAdminUpdateTenantPost(body?: UpdateTenantDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSuperAdminUpdateTenantPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuperAdminApi - factory interface
 * @export
 */
export const SuperAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuperAdminApiFp(configuration)
    return {
        /**
         * 
         * @param {AddNewTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminAddNewTenantPost(body?: AddNewTenantDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiSuperAdminAddNewTenantPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiSuperAdminDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminListTenantAsyncGet(options?: any): AxiosPromise<Array<TenantListDto>> {
            return localVarFp.webapiSuperAdminListTenantAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTenantDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSuperAdminUpdateTenantPost(body?: UpdateTenantDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiSuperAdminUpdateTenantPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuperAdminApi - object-oriented interface
 * @export
 * @class SuperAdminApi
 * @extends {BaseAPI}
 */
export class SuperAdminApi extends BaseAPI {
    /**
     * 
     * @param {AddNewTenantDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuperAdminApi
     */
    public webapiSuperAdminAddNewTenantPost(body?: AddNewTenantDto, options?: AxiosRequestConfig) {
        return SuperAdminApiFp(this.configuration).webapiSuperAdminAddNewTenantPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuperAdminApi
     */
    public webapiSuperAdminDeletePost(body?: number, options?: AxiosRequestConfig) {
        return SuperAdminApiFp(this.configuration).webapiSuperAdminDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuperAdminApi
     */
    public webapiSuperAdminListTenantAsyncGet(options?: AxiosRequestConfig) {
        return SuperAdminApiFp(this.configuration).webapiSuperAdminListTenantAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTenantDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuperAdminApi
     */
    public webapiSuperAdminUpdateTenantPost(body?: UpdateTenantDto, options?: AxiosRequestConfig) {
        return SuperAdminApiFp(this.configuration).webapiSuperAdminUpdateTenantPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportCenterApi - axios parameter creator
 * @export
 */
export const SupportCenterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSupportReportDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSupportCenterCreateReportPost: async (body?: CreateSupportReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/SupportCenter/CreateReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportCenterApi - functional programming interface
 * @export
 */
export const SupportCenterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportCenterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSupportReportDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSupportCenterCreateReportPost(body?: CreateSupportReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSupportCenterCreateReportPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportCenterApi - factory interface
 * @export
 */
export const SupportCenterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportCenterApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSupportReportDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSupportCenterCreateReportPost(body?: CreateSupportReportDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiSupportCenterCreateReportPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportCenterApi - object-oriented interface
 * @export
 * @class SupportCenterApi
 * @extends {BaseAPI}
 */
export class SupportCenterApi extends BaseAPI {
    /**
     * 
     * @param {CreateSupportReportDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportCenterApi
     */
    public webapiSupportCenterCreateReportPost(body?: CreateSupportReportDto, options?: AxiosRequestConfig) {
        return SupportCenterApiFp(this.configuration).webapiSupportCenterCreateReportPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSystemEnvironmentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/System/Environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiSystemEnvironmentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiSystemEnvironmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiSystemEnvironmentGet(options?: any): AxiosPromise<string> {
            return localVarFp.webapiSystemEnvironmentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public webapiSystemEnvironmentGet(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).webapiSystemEnvironmentGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiTenantGetTenantDiningHallMenuFeatureGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/Tenant/GetTenantDiningHallMenuFeature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiTenantGetTenantDiningHallMenuFeatureGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiTenantGetTenantDiningHallMenuFeatureGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiTenantGetTenantDiningHallMenuFeatureGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.webapiTenantGetTenantDiningHallMenuFeatureGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public webapiTenantGetTenantDiningHallMenuFeatureGet(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).webapiTenantGetTenantDiningHallMenuFeatureGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantParameterApi - axios parameter creator
 * @export
 */
export const TenantParameterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TenantParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost: async (body?: TenantParameter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/TenantParameter/UpdateForMultipleDeskReservationOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantParameterApi - functional programming interface
 * @export
 */
export const TenantParameterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantParameterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TenantParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body?: TenantParameter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantParameterApi - factory interface
 * @export
 */
export const TenantParameterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantParameterApiFp(configuration)
    return {
        /**
         * 
         * @param {TenantParameter} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body?: TenantParameter, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantParameterApi - object-oriented interface
 * @export
 * @class TenantParameterApi
 * @extends {BaseAPI}
 */
export class TenantParameterApi extends BaseAPI {
    /**
     * 
     * @param {TenantParameter} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantParameterApi
     */
    public webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body?: TenantParameter, options?: AxiosRequestConfig) {
        return TenantParameterApiFp(this.configuration).webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserAddPost: async (body?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserAddUserlistPost: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/AddUserlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserDeleteDelete: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetByTenantIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetRelatedUsersOfManagerAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetRelatedUsersOfManagerAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetTenantParameterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetTenantParameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUserLanguageAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetUserLanguageAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUsersHaveCarTenantIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetUsersHaveCarTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetWebUserByTenantIdGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/GetWebUserByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserListForRoomReservationAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/ListForRoomReservationAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/UpdateLoceleAttributesUserKeycloakHybeeByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/UpdateLoceleAttributesUserKeycloakHybeeWebByTenantId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdatePost: async (body?: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateUserLanguagePut: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/User/UpdateUserLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserAddPost(body?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserAddUserlistPost(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmpthyAndErrorFieldsDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserAddUserlistPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserDeleteDelete(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserDeleteDelete(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetByTenantIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetByTenantIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetRelatedUsersOfManagerAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetRelatedUsersOfManagerAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetTenantParameterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantParameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetTenantParameterGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetTokenGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetTokenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetUserLanguageAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetUserLanguageAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetUsersHaveCarTenantIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersHaveCarListDtoListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetUsersHaveCarTenantIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserGetWebUserByTenantIdGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserGetWebUserByTenantIdGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserListForRoomReservationAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserListForRoomReservationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserListForRoomReservationAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserUpdatePost(body?: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserUpdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserUpdateUserLanguagePut(language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserUpdateUserLanguagePut(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserAddPost(body?: User, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserAddUserlistPost(file?: any, options?: any): AxiosPromise<EmpthyAndErrorFieldsDtoResponse> {
            return localVarFp.webapiUserAddUserlistPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserDeleteDelete(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserDeleteDelete(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetByTenantIdGet(options?: any): AxiosPromise<UserListDtoListResponse> {
            return localVarFp.webapiUserGetByTenantIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetRelatedUsersOfManagerAsyncGet(options?: any): AxiosPromise<UserListResponse> {
            return localVarFp.webapiUserGetRelatedUsersOfManagerAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetTenantParameterGet(options?: any): AxiosPromise<TenantParameter> {
            return localVarFp.webapiUserGetTenantParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetTokenGet(options?: any): AxiosPromise<string> {
            return localVarFp.webapiUserGetTokenGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUserGet(options?: any): AxiosPromise<string> {
            return localVarFp.webapiUserGetUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUserLanguageAsyncGet(options?: any): AxiosPromise<StringResponse> {
            return localVarFp.webapiUserGetUserLanguageAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetUsersHaveCarTenantIdGet(options?: any): AxiosPromise<UsersHaveCarListDtoListResponse> {
            return localVarFp.webapiUserGetUsersHaveCarTenantIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserGetWebUserByTenantIdGet(options?: any): AxiosPromise<StringListResponse> {
            return localVarFp.webapiUserGetWebUserByTenantIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserListForRoomReservationAsyncGet(options?: any): AxiosPromise<Array<UserListForRoomReservationDto>> {
            return localVarFp.webapiUserListForRoomReservationAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body?: number, options?: any): AxiosPromise<StringListResponse> {
            return localVarFp.webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body?: number, options?: any): AxiosPromise<StringListResponse> {
            return localVarFp.webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {User} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdatePost(body?: User, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserUpdatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserUpdateUserLanguagePut(language?: string, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserUpdateUserLanguagePut(language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {User} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserAddPost(body?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserAddUserlistPost(file?: any, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserAddUserlistPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserDeleteDelete(body?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserDeleteDelete(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetByTenantIdGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetByTenantIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetRelatedUsersOfManagerAsyncGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetRelatedUsersOfManagerAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetTenantParameterGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetTenantParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetTokenGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetTokenGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetUserGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetUserLanguageAsyncGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetUserLanguageAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetUsersHaveCarTenantIdGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetUsersHaveCarTenantIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserGetWebUserByTenantIdGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserGetWebUserByTenantIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserListForRoomReservationAsyncGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserListForRoomReservationAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserUpdateLoceleAttributesUserKeycloakHybeeByTenantIdPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserUpdateLoceleAttributesUserKeycloakHybeeWebByTenantIdPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {User} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserUpdatePost(body?: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserUpdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public webapiUserUpdateUserLanguagePut(language?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).webapiUserUpdateUserLanguagePut(language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRolesApi - axios parameter creator
 * @export
 */
export const UserRolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesAddRoleToUserPost: async (userEmail?: string, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/AddRoleToUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesAutoAddManagerRoleToUserPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/AutoAddManagerRoleToUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRoleDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesCreateNewRolePost: async (body?: UserRoleDetail, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/CreateNewRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [roleId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesDeleteGroupRoleDelete: async (roleId?: number, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/DeleteGroupRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesDeleteRoleFromUserDelete: async (userEmail?: string, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/DeleteRoleFromUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesListUserRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/ListUserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesListUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/ListUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserRoleRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesUpdateGroupRolePost: async (body?: UpdateUserRoleRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/UpdateGroupRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesUpdateUserRolesPost: async (userEmail?: string, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/UserRoles/UpdateUserRoles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRolesApi - functional programming interface
 * @export
 */
export const UserRolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesAddRoleToUserPost(userEmail?: string, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesAddRoleToUserPost(userEmail, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesAutoAddManagerRoleToUserPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesAutoAddManagerRoleToUserPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRoleDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesCreateNewRolePost(body?: UserRoleDetail, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesCreateNewRolePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [roleId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesDeleteGroupRoleDelete(roleId?: number, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesDeleteGroupRoleDelete(roleId, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesDeleteRoleFromUserDelete(userEmail?: string, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesDeleteRoleFromUserDelete(userEmail, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesListUserRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleDetailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesListUserRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesListUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KeycloakUserListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesListUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserRoleRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesUpdateGroupRolePost(body?: UpdateUserRoleRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesUpdateGroupRolePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiUserRolesUpdateUserRolesPost(userEmail?: string, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiUserRolesUpdateUserRolesPost(userEmail, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRolesApi - factory interface
 * @export
 */
export const UserRolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRolesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesAddRoleToUserPost(userEmail?: string, groupName?: string, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesAddRoleToUserPost(userEmail, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesAutoAddManagerRoleToUserPost(options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesAutoAddManagerRoleToUserPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRoleDetail} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesCreateNewRolePost(body?: UserRoleDetail, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesCreateNewRolePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [roleId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesDeleteGroupRoleDelete(roleId?: number, groupName?: string, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesDeleteGroupRoleDelete(roleId, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesDeleteRoleFromUserDelete(userEmail?: string, groupName?: string, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesDeleteRoleFromUserDelete(userEmail, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesListUserRolesGet(options?: any): AxiosPromise<UserRoleDetailListResponse> {
            return localVarFp.webapiUserRolesListUserRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesListUsersGet(options?: any): AxiosPromise<Array<KeycloakUserListDto>> {
            return localVarFp.webapiUserRolesListUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserRoleRequestDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesUpdateGroupRolePost(body?: UpdateUserRoleRequestDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesUpdateGroupRolePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userEmail] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiUserRolesUpdateUserRolesPost(userEmail?: string, groupName?: string, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiUserRolesUpdateUserRolesPost(userEmail, groupName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRolesApi - object-oriented interface
 * @export
 * @class UserRolesApi
 * @extends {BaseAPI}
 */
export class UserRolesApi extends BaseAPI {
    /**
     * 
     * @param {string} [userEmail] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesAddRoleToUserPost(userEmail?: string, groupName?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesAddRoleToUserPost(userEmail, groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesAutoAddManagerRoleToUserPost(options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesAutoAddManagerRoleToUserPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRoleDetail} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesCreateNewRolePost(body?: UserRoleDetail, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesCreateNewRolePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [roleId] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesDeleteGroupRoleDelete(roleId?: number, groupName?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesDeleteGroupRoleDelete(roleId, groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userEmail] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesDeleteRoleFromUserDelete(userEmail?: string, groupName?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesDeleteRoleFromUserDelete(userEmail, groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesListUserRolesGet(options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesListUserRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesListUsersGet(options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesListUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserRoleRequestDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesUpdateGroupRolePost(body?: UpdateUserRoleRequestDto, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesUpdateGroupRolePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userEmail] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRolesApi
     */
    public webapiUserRolesUpdateUserRolesPost(userEmail?: string, groupName?: string, options?: AxiosRequestConfig) {
        return UserRolesApiFp(this.configuration).webapiUserRolesUpdateUserRolesPost(userEmail, groupName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkingAreaApi - axios parameter creator
 * @export
 */
export const WorkingAreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaAddAsyncPost: async (body?: WorkingAreaAddDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/AddAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeleteAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/DeleteAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetByIdAsyncGet: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/GetByIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetListByFloorIdAsyncGet: async (floorId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/GetListByFloorIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorId !== undefined) {
                localVarQueryParameter['floorId'] = floorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetListByTenantIdAsyncGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/GetListByTenantIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaUpdateAsyncPost: async (body?: WorkingAreaAddDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingArea/UpdateAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkingAreaApi - functional programming interface
 * @export
 */
export const WorkingAreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkingAreaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaAddAsyncPost(body?: WorkingAreaAddDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaAddAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeleteAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeleteAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaGetByIdAsyncGet(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkingAreaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaGetByIdAsyncGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaGetListByFloorIdAsyncGet(floorId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkingAreaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaGetListByFloorIdAsyncGet(floorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaGetListByTenantIdAsyncGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkingAreaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaGetListByTenantIdAsyncGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaUpdateAsyncPost(body?: WorkingAreaAddDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaUpdateAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkingAreaApi - factory interface
 * @export
 */
export const WorkingAreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkingAreaApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaAddAsyncPost(body?: WorkingAreaAddDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaAddAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeleteAsyncPost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaDeleteAsyncPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetByIdAsyncGet(body?: number, options?: any): AxiosPromise<WorkingAreaResponse> {
            return localVarFp.webapiWorkingAreaGetByIdAsyncGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [floorId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetListByFloorIdAsyncGet(floorId?: number, options?: any): AxiosPromise<WorkingAreaListResponse> {
            return localVarFp.webapiWorkingAreaGetListByFloorIdAsyncGet(floorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaGetListByTenantIdAsyncGet(options?: any): AxiosPromise<WorkingAreaListResponse> {
            return localVarFp.webapiWorkingAreaGetListByTenantIdAsyncGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkingAreaAddDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaUpdateAsyncPost(body?: WorkingAreaAddDto, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaUpdateAsyncPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkingAreaApi - object-oriented interface
 * @export
 * @class WorkingAreaApi
 * @extends {BaseAPI}
 */
export class WorkingAreaApi extends BaseAPI {
    /**
     * 
     * @param {WorkingAreaAddDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaAddAsyncPost(body?: WorkingAreaAddDto, options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaAddAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaDeleteAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaDeleteAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaGetByIdAsyncGet(body?: number, options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaGetByIdAsyncGet(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [floorId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaGetListByFloorIdAsyncGet(floorId?: number, options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaGetListByFloorIdAsyncGet(floorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaGetListByTenantIdAsyncGet(options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaGetListByTenantIdAsyncGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkingAreaAddDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaApi
     */
    public webapiWorkingAreaUpdateAsyncPost(body?: WorkingAreaAddDto, options?: AxiosRequestConfig) {
        return WorkingAreaApiFp(this.configuration).webapiWorkingAreaUpdateAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkingAreaDeskApi - axios parameter creator
 * @export
 */
export const WorkingAreaDeskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WorkingAreaDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskAddPost: async (body?: WorkingAreaDesk, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingAreaDesk/Add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskAddRangePost: async (body?: Array<WorkingAreaDesk>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingAreaDesk/AddRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskDeletePost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingAreaDesk/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskDeleteRangePost: async (body?: Array<WorkingAreaDesk>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingAreaDesk/DeleteRange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webapi/WorkingAreaDesk/GetListByWorkingAreaIdAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkingAreaDeskApi - functional programming interface
 * @export
 */
export const WorkingAreaDeskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkingAreaDeskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WorkingAreaDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeskAddPost(body?: WorkingAreaDesk, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeskAddPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeskAddRangePost(body?: Array<WorkingAreaDesk>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeskAddRangePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeskDeletePost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeskDeletePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeskDeleteRangePost(body?: Array<WorkingAreaDesk>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeskDeleteRangePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkingAreaDeskListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkingAreaDeskApi - factory interface
 * @export
 */
export const WorkingAreaDeskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkingAreaDeskApiFp(configuration)
    return {
        /**
         * 
         * @param {WorkingAreaDesk} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskAddPost(body?: WorkingAreaDesk, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaDeskAddPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskAddRangePost(body?: Array<WorkingAreaDesk>, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaDeskAddRangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskDeletePost(body?: number, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaDeskDeletePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<WorkingAreaDesk>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskDeleteRangePost(body?: Array<WorkingAreaDesk>, options?: any): AxiosPromise<Response> {
            return localVarFp.webapiWorkingAreaDeskDeleteRangePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body?: number, options?: any): AxiosPromise<WorkingAreaDeskListResponse> {
            return localVarFp.webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkingAreaDeskApi - object-oriented interface
 * @export
 * @class WorkingAreaDeskApi
 * @extends {BaseAPI}
 */
export class WorkingAreaDeskApi extends BaseAPI {
    /**
     * 
     * @param {WorkingAreaDesk} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaDeskApi
     */
    public webapiWorkingAreaDeskAddPost(body?: WorkingAreaDesk, options?: AxiosRequestConfig) {
        return WorkingAreaDeskApiFp(this.configuration).webapiWorkingAreaDeskAddPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<WorkingAreaDesk>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaDeskApi
     */
    public webapiWorkingAreaDeskAddRangePost(body?: Array<WorkingAreaDesk>, options?: AxiosRequestConfig) {
        return WorkingAreaDeskApiFp(this.configuration).webapiWorkingAreaDeskAddRangePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaDeskApi
     */
    public webapiWorkingAreaDeskDeletePost(body?: number, options?: AxiosRequestConfig) {
        return WorkingAreaDeskApiFp(this.configuration).webapiWorkingAreaDeskDeletePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<WorkingAreaDesk>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaDeskApi
     */
    public webapiWorkingAreaDeskDeleteRangePost(body?: Array<WorkingAreaDesk>, options?: AxiosRequestConfig) {
        return WorkingAreaDeskApiFp(this.configuration).webapiWorkingAreaDeskDeleteRangePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingAreaDeskApi
     */
    public webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body?: number, options?: AxiosRequestConfig) {
        return WorkingAreaDeskApiFp(this.configuration).webapiWorkingAreaDeskGetListByWorkingAreaIdAsyncPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}


