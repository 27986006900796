import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Menu from 'primevue/menu'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect';
import Chips from 'primevue/chips';
import Card from 'primevue/card';
import MegaMenu from 'primevue/megamenu'
import Menubar from 'primevue/menubar'
import TabMenu from 'primevue/tabmenu'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Textarea from 'primevue/textarea'
import InputNumber from 'primevue/inputnumber'
import FileUpload from 'primevue/fileupload'
import Calendar from 'primevue/calendar'
import InputMask from 'primevue/inputmask';
import InputSwitch from 'primevue/inputswitch';
import RadioButton from 'primevue/radiobutton';
import AutoComplete from 'primevue/autocomplete';
import ProgressSpinner from 'primevue/progressspinner';
// import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import { latLng } from "leaflet"
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import {LMap, LTileLayer, LLayerGroup, LPopup, LCircle, LMarker} from 'vue2-leaflet';

import 'leaflet/dist/leaflet.css';


import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import './assets/styles/layout.scss'
import {createI18n} from 'vue-i18n'
import enJson from './languages/en.json'
import trJson from './languages/tr.json'

import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'
import {KeycloakInstance} from 'keycloak-js'
import {VueKeycloakInstance} from '@dsb-norge/vue-keycloak-js/dist/types'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import QrcodeVue from 'qrcode.vue'
import JSZip from "jszip"
import Loading from 'vue-loading-overlay';
import { createPinia } from 'pinia'




import Chart from 'primevue/chart'


const i18n = createI18n({
  locale: "",
  messages: {
    en: enJson,
    tr: trJson,
  }
})

    const pinia = createPinia()
    pinia.state.value = {}
    const app = createApp(App)


    app.use(VueKeycloakJs, {
      init: { onLoad: 'login-required' },
      config: { url: process.env.IDENTITY_SERVER_URL, clientId: 'hybee-web', realm: 'Hybee-Web' },
      logout: { redirectUri: '' }
    })
    .use(PrimeVue, {
      locale: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        completed: 'Completed',
        pending: 'Pending',
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        chooseYear: 'Choose Year',
        chooseMonth: 'Choose Month',
        chooseDate: 'Choose Date',
        prevDecade: 'Previous Decade',
        nextDecade: 'Next Decade',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        prevHour: 'Previous Hour',
        nextHour: 'Next Hour',
        prevMinute: 'Previous Minute',
        nextMinute: 'Next Minute',
        prevSecond: 'Previous Second',
        nextSecond: 'Next Second',
        am: 'am',
        pm: 'pm',
        today: 'Today',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
        dateFormat: 'mm/dd/yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
        searchMessage: '{0} results are available',
        selectionMessage: '{0} items selected',
        emptySelectionMessage: 'No selected item',
        emptySearchMessage: 'No results found',
        emptyMessage: 'No available options',
        aria: {
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'Not Selected',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'All items selected',
            unselectAll: 'All items unselected',
            close: 'Close',
            previous: 'Previous',
            next: 'Next',
            navigation: 'Navigation',
            scrollTop: 'Scroll Top',
            moveTop: 'Move Top',
            moveUp: 'Move Up',
            moveDown: 'Move Down',
            moveBottom: 'Move Bottom',
            moveToTarget: 'Move to Target',
            moveToSource: 'Move to Source',
            moveAllToTarget: 'Move All to Target',
            moveAllToSource: 'Move All to Source',
            pageLabel: '{page}',
            firstPageLabel: 'First Page',
            lastPageLabel: 'Last Page',
            nextPageLabel: 'Next Page',
            prevPageLabel: 'Previous Page',
            rowsPerPageLabel: 'Rows per page',
            jumpToPageDropdownLabel: 'Jump to Page Dropdown',
            jumpToPageInputLabel: 'Jump to Page Input',
            selectRow: 'Row Selected',
            unselectRow: 'Row Unselected',
            expandRow: 'Row Expanded',
            collapseRow: 'Row Collapsed',
            showFilterMenu: 'Show Filter Menu',
            hideFilterMenu: 'Hide Filter Menu',
            filterOperator: 'Filter Operator',
            filterConstraint: 'Filter Constraint',
            editRow: 'Row Edit',
            saveEdit: 'Save Edit',
            cancelEdit: 'Cancel Edit',
            listView: 'List View',
            gridView: 'Grid View',
            slide: 'Slide',
            slideNumber: '{slideNumber}',
            zoomImage: 'Zoom Image',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            rotateRight: 'Rotate Right',
            rotateLeft: 'Rotate Left'
        }
      }
    })
    .use(router)
    .use(PrimeVue, {
      ripple: true,
    })
    .use(i18n)
    .use(ToastService)
    .use(pinia)
    .directive('badge', BadgeDirective)
    .directive('ripple', Ripple)
    .directive('tooltip', Tooltip)
    .directive('styleclass', StyleClass)
    .component('Dialog', Dialog)
    .component('Menu', Menu)
    .component('InputSwitch',InputSwitch)
    .component('Badge', Badge)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Dropdown',Dropdown)
    .component('Toast', Toast)
    .component('Toolbar', Toolbar)
    .component('MultiSelect', MultiSelect)
    .component('TabMenu', TabMenu)
    .component('TabView', TabView)
    .component('TabPanel', TabPanel)
    .component('Textarea', Textarea)
    .component('InputNumber', InputNumber)
    .component('Calendar', Calendar)
    .component('FileUpload', FileUpload)
    .component('InputMask',InputMask)
    .component('Chart',Chart)
    .component('Menubar',Menubar)
    .component('MegaMenu',MegaMenu)
    .component('Card',Card)
    .component('Chips',Chips)
    .component('RadioButton',RadioButton)
    .component('ProgressSpinner',ProgressSpinner)
    .component('AutoComplete',AutoComplete)
    .component('vueQr', vueQr )
    .component('QrcodeVue', QrcodeVue)
    .component('JSZip', JSZip)
    .component('Loading',Loading)
    .component('l-map', LMap)
    .component('l-tile-layer', LTileLayer)
    .component('l-marker', LMarker)
    .component('leatlef', latLng)
    .mount('#app')




    app.config.globalProperties.$apiHeader = { headers: {"Accept-Language": "", "Authorization": ""}}

  

  declare module '@vue/runtime-core' {
    interface ComponentCustomProperties  {
      $keycloak: VueKeycloakInstance,
      $apiHeader: any,
    }
  }
