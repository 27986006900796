import { FilterMatchMode } from 'primevue/api';
import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { BuildingApi, WorkingAreaApi ,CarParkApi, ShuttleApi, DiningHallApi, FloorApi, ResponseCode, RoomApi, RoomServiceApi, UserApi, RuleApi, ReportApi } from '@/metadata/hybee-web-api'
//import { ReportApi } from '@/metadata/hybee-report-api'
import { createApiForWebAPI} from "@/api"
import * as XLSX from 'xlsx'
import rolesJson from "../../roles/roles.json";
import { useAppStore } from '@/store/app';




export default class Reports extends Vue {
    appStore = useAppStore()

    buildingApi: BuildingApi = createApiForWebAPI(BuildingApi)
    floorApi: FloorApi = createApiForWebAPI(FloorApi)
    ruleApi: RuleApi = createApiForWebAPI(RuleApi)
    shuttleApi: ShuttleApi = createApiForWebAPI(ShuttleApi)
    reportApi: ReportApi = createApiForWebAPI(ReportApi)
    roomApi: RoomApi = createApiForWebAPI(RoomApi)
    userApi: UserApi = createApiForWebAPI(UserApi)
    workingAreaApi: WorkingAreaApi = createApiForWebAPI(WorkingAreaApi)
    hiddenFilters=true
    // angleButton=true
    // angleButton1=false

    dashboardAndReportPageRole: any = null
    deleteReservationsPageRole: any = null

    building: any = {}
    buildings: any = []

    floor: any = {}
    floors: any = []

    workingArea: any = {}
    workingAreas: any = []

    department: any = {}
    departments: any = []

    title: any = {}
    titles: any = []

    
    subCompany: any = {}
    subCompanies: any = []
    subCompaniesDropdownVisible = false

    shuttle: any = {}
    shuttles: any = []

    room: any={}
    meetingRooms: any=[]

    // for Desk Report
    report: any = {}
    reports: any=[]

    deletedReservation: any = {}
    deleteReservationDialog = false

    userBackOfficeLanguage: any

    checkInStatus: any = {
        checkedIn: true,
        notCheckedIn: true,
        failed: true,
        cancelled: true,
        comingUp: true,
        waitingForCheckIn: true,
        spontaneous: true,
        checkout: true,
    }

    checkInStatusData: any = {
        0: "Idle",
        1: "Coming Up",
        2: "Waiting for Checked-in",
        4: "Not Checked-in",
        8: "Cancelled",
        16: "Failed Check-in",
        32: "Checked-in",
        64: "Spontaneous",
        128: "Checkout",
    }


    // for Meeting Report
    meeting: any={}
    meetings: any=[]

    filters: any = {}

    apiHeader: any
    apiHeaderForFile: any

    advancedFilters=true

    deskReportFilters:any = {}
    deskReportFilter:any = {}
    filtersDatatableDialog = false
    deleteFilterDialog = false

    filterDelete:any = {}

    addFilterObject:any = {}
    filterAddDialog:any = false
    nameForAddNewFilter:any = ""
    filterAddSubmitted:any = false
    
    created() {
        // this.$apiHeader = { headers: { Authorization: 'Bearer ' + this.$keycloak.token, "Accept-Language":this.$i18n.locale.toUpperCase() } }
        this.apiHeaderForFile = {
            headers: {
                Authorization: 'Bearer ' + this.$keycloak.token,
                'Content-Type': 'multipart/form-data'
            }
        }
        this.initFilters();
        //console.log(process.env.APP_ENV);
    }

    mounted() {
        // this.getUserBackOfficeLanguage();
        this.loadRoles();
        if (this.dashboardAndReportPageRole) {
            this.listBuildings();
            this.listDepartments();
            this.listTitles();
            this.listShuttles();
            this.listSubCompanies();
            this.listDeskReportFilter();
        }
    }

    // @Watch('report.buildingId')
    // onPropertyChangedForBuildingDropdown(value: number, oldValue: number) {
    //   if(this.deskReportFilter != null && (this.report.floorId == null || this.report.floorId == 0) ){
    //     this.buildingNameChange();
    //   }
    // }

    // @Watch('report.floorId')
    // onPropertyChangedForFloorDropdown(value: number, oldValue: number) {
    //   if(this.deskReportFilter != null && (this.report.workingAreaId == null || this.report.workingAreaId == 0) ){
    //     this.floorNameChange();
    //   }
    // }


    @Watch('report.buildingId')
    onPropertyChangedForBuildingDropdown(value: number, oldValue: number) {
        this.buildingNameChangeForDeskReportFilterChange();

    }

    @Watch('report.floorId')
    onPropertyChangedForFloorDropdown(value: number, oldValue: number) {
        this.floorNameChangeForDeskReportFilterChange();
    }

    initFilters(): any {
        this.filters = {
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    }

    @Watch('$i18n.locale')
    onPropertyChanged(value: string, oldValue: string) {
        this.translateSomeWordsOfBackOfficeLanguage();
    }

    translateSomeWordsOfBackOfficeLanguage(){
        this.checkInStatusData = {
            0: this.$t('idle'),
            1: this.$t('comingUp'),
            2: this.$t('waitingForCheck-in'),
            4: this.$t('notChecked-in'),
            8: this.$t('cancelled'),
            16: this.$t('failedCheck-in'),
            32: this.$t('checked-in'),
            64: this.$t('spontaneous'),
            128: this.$t('checkout'),
        }
    }

    loadRoles() {
        this.dashboardAndReportPageRole = rolesJson['dashboardReports'];
        this.deleteReservationsPageRole = rolesJson['deleteReservations'];
    }

    loadCheckinStatusesForReportFilterAdd(){
        const checkInStatuses: any = []

        Object.keys(this.checkInStatus).forEach(key => {
            if (this.checkInStatus[key]){
                if (key.toString() == 'checkedIn'){
                    checkInStatuses.push(32)
                }
                else if (key.toString() == 'notCheckedIn'){
                    checkInStatuses.push(4)
                }
                else if (key.toString() == 'failed'){
                    checkInStatuses.push(16)
                }
                else if (key.toString() == 'cancelled'){
                    checkInStatuses.push(8)
                }
                else if (key.toString() == 'comingUp'){
                    checkInStatuses.push(1)
                }
                else if (key.toString() == 'waitingForCheckIn'){
                    checkInStatuses.push(2)
                }
                else if (key.toString() == 'spontaneous'){
                    checkInStatuses.push(64)
                }
                else if (key.toString() == 'checkout'){
                    checkInStatuses.push(128)
                }
            }
        });
        this.addFilterObject.checkInStatuses = checkInStatuses;
    }

    async getUserBackOfficeLanguage(){
        this.appStore.progressSpinnerShow = true

        await this.userApi.webapiUserGetUserLanguageAsyncGet(this.$apiHeader).then(x => {
            this.userBackOfficeLanguage = x.data.data;
            if(this.userBackOfficeLanguage != null && this.userBackOfficeLanguage != ""){
                if(this.userBackOfficeLanguage == "tr"){
                    this.$i18n.locale = "tr"
                }
                if(this.userBackOfficeLanguage == "en"){
                    this.$i18n.locale = "en"
                }
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
        });

        this.appStore.progressSpinnerShow = false

    }


    /* Date Functions */


    customDateString(date: Date){
        date = new Date(date)
        let dateString = new String()

        //Day format

        if(date.getDate().toString().length < 2){
            dateString +=  "0" + date.getDate().toString() + "."
        }
        else{
            dateString += date.getDate().toString() + "."
        }

        // Month format

        if((date.getMonth()+1).toString().length < 2){
            dateString +=  "0" + (date.getMonth()+1).toString() + "."   // getMonth() metodu Ocak ayını 0' dan başlattığı için +1 yapıldı
        }
        else{
            dateString += (date.getMonth()+1).toString() + "."   // getMonth() metodu Ocak ayını 0' dan başlattığı için +1 yapıldı
        }

        // Year format

        dateString += date.getFullYear().toString() + " "

        // Hour format

        if(date.getHours().toString().length < 2){
            dateString +=  "0" + date.getHours().toString() + ":"
        }
        else{
            dateString += date.getHours().toString() + ":"
        }

        // Minute format

        if(date.getMinutes().toString().length < 2){
            dateString +=  "0" + date.getMinutes().toString()
        }
        else{
            dateString += date.getMinutes().toString()
        }

        return dateString

         
    }

    /* Date Functions end */

    /* Add Functions - Start*/

    async addFilter(){
        this.appStore.progressSpinnerShow = true
        this.filterAddSubmitted = true

        this.addFilterObject = this.report
        this.addFilterObject.filterName = this.nameForAddNewFilter.trim()
        this.loadCheckinStatusesForReportFilterAdd();


        if(this.addFilterObject.filterName != null && this.addFilterObject.filterName != ""){
            if(this.addFilterObject.userName != null){
                this.addFilterObject.userName = this.addFilterObject.userName.trim()
            }
            if(this.addFilterObject.userSurname != null){
                this.addFilterObject.userSurname = this.addFilterObject.userSurname.trim()
            }
            if(this.addFilterObject.licensePlate != null){
                this.addFilterObject.licensePlate = this.addFilterObject.licensePlate.replace(/\s/g,'').toUpperCase();
            }

            
            await this.reportApi.webapiReportAddDeskReportFilterPost(this.addFilterObject, this.$apiHeader).then(x => {
                if(x.data.responseCode == ResponseCode.NUMBER_200){
                    this.listDeskReportFilter();
                    this.filterAddDialog = false
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                }
                else{
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: x.data.message, life: 3000 });

                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnAddingFilter'), life: 3000 });
            });

            this.filterAddSubmitted = false

        }

        this.nameForAddNewFilter = ""
        this.appStore.progressSpinnerShow = false

    }

    /* Add Functions - End*/
    

    /* List Functions */

    listBuildings() {
        this.buildingApi.webapiBuildingListAsyncGet(this.$apiHeader).then(x => this.buildings = x.data).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingBuildings'), life: 3000 });
        });
    }

    listDepartments() {
        this.ruleApi.webapiRuleGetDepartmentsByTenantIdAsyncGet(this.$apiHeader).then(x => { this.departments = x.data.data; }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingDepartments'), life: 3000 });
        });
    }

    listTitles() {
        this.ruleApi.webapiRuleGetTitlesByTenantIdAsyncGet(this.$apiHeader).then(x => { this.titles = x.data.data; }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingTitles'), life: 3000 });
        });
    }

    listSubCompanies() {
        this.ruleApi.webapiRuleGetSubCompaniesByTenantIdAsyncGet(this.$apiHeader).then(x => 
            {
                this.subCompanies = x.data.data;
                if(this.subCompanies != null && this.subCompanies.length > 0){
                    this.subCompaniesDropdownVisible = true;
                }
            }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingSubCompanies'), life: 3000 });
        });
    }



    listShuttles(){
        
		this.shuttleApi.webapiShuttleListByBuildingIdAsyncGet(this.report.buildingId, this.$apiHeader).then(x => this.shuttles = x.data).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t('error'), detail: this.$t('errorOnListingShuttles'), life: 3000})
		})
	}


    async listDeskReportFilter(){
        await this.reportApi.webapiReportListDeskReportFiltersGet(this.$apiHeader).then(x => {
            this.deskReportFilters = x.data.data
        })
        
    }

    async listReports(){
        this.appStore.progressSpinnerShow = true

        const checkInStatuses: any = []

        Object.keys(this.checkInStatus).forEach(key => {
            if (this.checkInStatus[key]){
                if (key.toString() == 'checkedIn'){
                    checkInStatuses.push(32)
                }
                else if (key.toString() == 'notCheckedIn'){
                    checkInStatuses.push(4)
                }
                else if (key.toString() == 'failed'){
                    checkInStatuses.push(16)
                }
                else if (key.toString() == 'cancelled'){
                    checkInStatuses.push(8)
                }
                else if (key.toString() == 'comingUp'){
                    checkInStatuses.push(1)
                }
                else if (key.toString() == 'waitingForCheckIn'){
                    checkInStatuses.push(2)
                }
                else if (key.toString() == 'spontaneous'){
                    checkInStatuses.push(64)
                }
                else if (key.toString() == 'checkout'){
                    checkInStatuses.push(128)
                }
            }
        });
        this.report.checkInStatuses = checkInStatuses;


        if(this.report.userName != null){
            this.report.userName = this.report.userName.trim()
        }
        if(this.report.userSurname != null){
            this.report.userSurname = this.report.userSurname.trim()
        }
        if(this.report.licensePlate != null){
            this.report.licensePlate = this.report.licensePlate.replace(/\s/g,'')
        }

        
		await this.reportApi.webapiReportDeskReportListAsyncPost(this.report, this.$apiHeader).then(x => {
            this.reports = x.data;
            for(let i = 0; i < this.reports.length; i++){
                // this.reports[i].reservationStartDateType =this.reports[i].reservationStartDate
                // this.reports[i].reservationEndDateType =this.reports[i].reservationEndDate
                this.reports[i].reservationStartDate = this.customDateString(this.reports[i].reservationStartDate)
                this.reports[i].reservationEndDate = this.customDateString(this.reports[i].reservationEndDate)
                if(this.reports[i].checkinTime != null){
                    this.reports[i].checkinTime = this.customDateString(this.reports[i].checkinTime).substring(11,16)
                }
                if(this.reports[i].checkoutTime != null){
                    this.reports[i].checkoutTime = this.customDateString(this.reports[i].checkoutTime).substring(11,16)
                }
                if(this.reports[i].workingTime != null){
                    this.reports[i].workingTime = this.reports[i].workingTime.substring(0,5)
                }

        }}).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t('error'), detail: this.$t('errorOnListingReports'), life: 3000})
		})
        this.appStore.progressSpinnerShow = false

	}
    async listMeetings(){
        this.appStore.progressSpinnerShow = true

		await this.reportApi.webapiReportMeetingRoomReportListAsyncPost(this.meeting, this.$apiHeader).then(x => {
            this.meetings = x.data;
            for(let i = 0; i < this.meetings.length; i++){
                this.meetings[i].startDateType = this.meetings[i].startDate
                this.meetings[i].endDateType = this.meetings[i].endDate
                this.meetings[i].startDate = this.customDateString(this.meetings[i].startDate)
                this.meetings[i].endDate = this.customDateString(this.meetings[i].endDate)
        }}).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t('error'), detail: this.$t('errorOnListingMeetings'), life: 3000})
		})

        this.appStore.progressSpinnerShow = false

        
	}


    

    /* Delete Functions */
    confirmDeleteReservation(data: any): void {
        
        this.deletedReservation = {
            id: data.deskReservasionId,
            name: data.userName,
            surname: data.userSurname,
        }
        
        this.deleteReservationDialog = true;
    }

    confirmDeleteFilter(data: any): void {
        this.filterDelete = {
            id: data.id,
            filterName: data.filterName
        }
        this.deleteFilterDialog = true;
    }

    async deleteReservation(){
        this.appStore.progressSpinnerShow = true

        await this.reportApi.webapiReportDeleteDeskReservationPost(<number>this.deletedReservation.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listReports();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteReservationDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingReservation"), life: 3000 })
        });

        this.appStore.progressSpinnerShow = false

    }

    async deleteFilter() {
        this.appStore.progressSpinnerShow = true

        await this.reportApi.webapiReportDeleteDeskReportFilterPost(<number>this.filterDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listDeskReportFilter();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteFilterDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingFilter"), life: 3000 })
        });
        
        this.appStore.progressSpinnerShow = false

    }

    


    /* Necessary functions for Excel export - start */
      
      forceFileDownload(name:string, reportData:any, excelHeaders:any) {
        const tempExcel = XLSX.utils.book_new()

        tempExcel.Props = {
            Title: name,
            Subject: "Report",
            Author: "Doğuş Teknoloji",
            CreatedDate: new Date() 
        };

        tempExcel.SheetNames.push(name);

        const stringData = JSON.stringify(reportData);   
        const data = JSON.parse(stringData);
        const dataSheet = XLSX.utils.json_to_sheet(data);

        XLSX.utils.sheet_add_aoa(dataSheet, excelHeaders);

        tempExcel.Sheets[name] = dataSheet;

        const excel = XLSX.write(tempExcel, {bookType:'xlsx', type:'binary'})
        const excelBlob = new Blob([this.charConverterToOctet(excel)],{type:"application/octet-stream"})

        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement("a");
        const createdDate = new Date
        //const createdDateForExcelName = createdDate.getDay()+"."+createdDate.getMonth()+"."+createdDate.getFullYear()+" "+ createdDate.getHours()+":"+createdDate.getMinutes()+":"+createdDate.getSeconds()
        const createdDateForExcelName = this.customDateString(createdDate) + ":" + createdDate.getSeconds()

        link.setAttribute("href", url);
        link.setAttribute("download", name+" - "+createdDateForExcelName+".xlsx");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      }

      charConverterToOctet(s:any) { 
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;    
      }

    

    meetingRoomReservationReportExcelExport(){
        
        if(this.meetings.length != 0){
            if(this.$i18n.locale == 'en')
            {
                const headers = [['User Name', 'User Surname', 'Is Hybrid Meeting', 'Meeting Title', 'Start Date', 'End Date', 'Room Name', 'Employee Id']];
                this.forceFileDownload("Meeting Room Reports", this.meetings, headers)
            }else{
                const headers = [['Ad', 'Soyad', 'Toplantı Hibrit Mi', 'Toplantı Başlığı', 'Başlangıç Tarihi', 'Bitiş Tarihi', 'Oda Adı', 'Personel Id']];
                this.forceFileDownload("Toplantı Odası Raporları", this.meetings, headers)
            }           
           
        }
        else{
            this.$toast.add({severity:'error', summary: 'Error', detail: "Excel cannot be downloaded because the report is not generated.", life: 4000})
        }
    }

    deskReservationReportExcelExport(){
        if(this.reports.length != 0){
            const tempReports = this.reports.map((a: any) => ({...a}));

            tempReports.forEach((element: any) => {
                element.status = this.checkInStatusData[element.status]
                delete element["deskReservasionId"]
            });
        if(this.$i18n.locale == 'en')
        {
        const headers = [['Reservation Start Date', 'Reservation End Date', 'Desk', 'Working Area','Floor Name', 'Check-in Status', 'Sub Company', 'Department Name',
        'Title Name', 'User Name', 'User Surname', 'Is Using Dining Hall', 'Is Using Shuttle', 'Is Using Car Park',
        'Source Shuttle Route', 'Destination Shuttle Route', 'License Plate', 'Building Name','Employee Id','Check-in Time','Check-out Time','Working Time', 'Is Checked Out By System']];
        this.forceFileDownload("Office Day Reports", tempReports, headers)
        }else{
            const headers = [['Başlangıç Tarihi', 'Bitiş Tarihi', 'Masa', 'Çalışma Alanı','Kat', 'Check-in Durumu', 'Alt Şirket','Departman',
             'Başlık', 'Ad', 'Soyad', 'Yemekhane Kullanımı', 'Servis Kullanımı', 'Otopark Kullanımı',
             'Başlangıç Servis Rotası', 'Hedef Servis Rotası', 'Plaka', 'Bina','Personel Id','Check-in Saati','Check-out Saati','Çalışma Süresi', 'Otomatik Check-Out Durumu']];
             this.forceFileDownload("Ofis Günü Raporları", tempReports, headers)
        }
         
        }
        else{
            this.$toast.add({severity:'error', summary: 'Error', detail: "Excel cannot be downloaded because the report is not generated.", life: 4000})
        }
    }

    /* Necessary functions for Excel export - end */


    /* Change Functions */

    buildingNameChange() {
        this.report.floorId = null;
        if (this.report.buildingId) {
            this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.report.buildingId, this.$apiHeader).then(x => this.floors = x.data).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
        }
        else {
            this.floors = []
            this.workingAreas = []
        }
    }

    buildingNameChangeForDeskReportFilterChange() {
        if (this.report.buildingId) {
            this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.report.buildingId, this.$apiHeader).then(x => this.floors = x.data).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
        }
        else {
            this.floors = []
            this.workingAreas = []
        }
    }

    floorNameChangeForDeskReport() {
        this.report.workingAreaId = null;
        if (this.report.floorId) {
            this.workingAreaApi.webapiWorkingAreaGetListByFloorIdAsyncGet(this.report.floorId, this.$apiHeader).then(x =>{ this.workingAreas = x.data.data; }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
        }
        else {
            this.workingAreas = []
        }
    }

    floorNameChangeForDeskReportFilterChange() {
        if (this.report.floorId) {
            this.workingAreaApi.webapiWorkingAreaGetListByFloorIdAsyncGet(this.report.floorId, this.$apiHeader).then(x =>{ this.workingAreas = x.data.data; }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
        }
        else {
            this.workingAreas = []
        }
    }


    buildingNameChangeMeeting() {
        this.meeting.floorId = null;
        if (this.meeting.buildingId) {
            this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.meeting.buildingId, this.$apiHeader).then(x => this.floors = x.data).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
        }
        else {
            this.floors = []
        }
    }
    floorNameChange() {
        this.meetingRooms = null;
        if (this.meeting.floorId) {
            this.roomApi.webapiRoomGetByFloorIdAsyncGet(this.meeting.floorId, this.$apiHeader).then(x => this.meetingRooms = x.data).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingMeetings'), life: 3000 });
            });
        }
        else {
            this.meetingRooms = []
        }
    }

    deskReportFilterChange(){
        

        if(this.deskReportFilter != null){

            this.checkInStatus.checkedIn = false
            this.checkInStatus.notCheckedIn = false
            this.checkInStatus.spontaneous = false
            this.checkInStatus.failed = false
            this.checkInStatus.cancelled = false
            this.checkInStatus.comingUp = false
            this.checkInStatus.waitingForCheckIn = false
            this.checkInStatus.checkout = false

            if(this.deskReportFilter.checkInStatuses != null && this.deskReportFilter.checkInStatuses.length > 0){
                if(this.deskReportFilter.checkInStatuses.includes(1)){
                    this.checkInStatus.comingUp = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(2)){
                    this.checkInStatus.waitingForCheckIn = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(4)){
                    this.checkInStatus.notCheckedIn = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(8)){
                    this.checkInStatus.cancelled = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(16)){
                    this.checkInStatus.failed = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(32)){
                    this.checkInStatus.checkedIn = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(64)){
                    this.checkInStatus.spontaneous = true
                }
                if(this.deskReportFilter.checkInStatuses.includes(128)){
                    this.checkInStatus.checkout = true
                }
            }
    
    
            this.report.buildingId = this.deskReportFilter.buildingId
            this.report.floorId  = this.deskReportFilter.floorId
            this.report.workingAreaId  = this.deskReportFilter.workingAreaId
    
            this.report.subCompanyId  = this.deskReportFilter.subCompanyId
            this.report.departmentId  = this.deskReportFilter.departmentId
            this.report.titleId  = this.deskReportFilter.titleId
    
            this.report.isUsingDiningHall  = this.deskReportFilter.isUsingDiningHall
            this.report.isUsingShuttle  = this.deskReportFilter.isUsingShuttle
            this.report.isUsingCarPark  = this.deskReportFilter.isUsingCarPark
    
            this.report.sourceShuttleRoute  = this.deskReportFilter.sourceShuttleRoute
            this.report.destinationShuttleRoute  = this.deskReportFilter.destinationShuttleRoute
    
            this.report.userName  = this.deskReportFilter.userName
            this.report.userSurname  = this.deskReportFilter.userSurname
            this.report.licensePlate  = this.deskReportFilter.licensePlate
    
            this.report.checkInStatuses  = this.deskReportFilter.checkInStatuses
            this.report.isCheckedOutBySystem  = this.deskReportFilter.isCheckedOutBySystem
    
        }
        else{
            this.report = {}

            this.checkInStatus.checkedIn = true
            this.checkInStatus.notCheckedIn = true
            this.checkInStatus.failed = true
            this.checkInStatus.cancelled = true
            this.checkInStatus.comingUp = true
            this.checkInStatus.waitingForCheckIn = true
            this.checkInStatus.spontaneous = true
            this.checkInStatus.checkout = true

        }


        


    }

    filtersDatatableDialogClick(){
        this.filtersDatatableDialog = true
    }

    hideFilterAddDialog(){
        this.filterAddDialog = false
        this.filterAddSubmitted= false
        this.nameForAddNewFilter = ""
    }
}