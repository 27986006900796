import { FilterMatchMode } from 'primevue/api';
import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { BuildingApi, UserRolesApi, CarParkApi, ShuttleApi, DiningHallApi, FloorApi, ResponseCode, RoomApi, RoomServiceApi, UserApi, RuleApi, DashboardApi } from '@/metadata/hybee-web-api'
import { createApiForWebAPI} from "@/api"
import rolesJson from "../../roles/roles.json";
import { useAppStore } from '@/store/app';


export default class UserRoles extends Vue {
    appStore = useAppStore()

    userApi: UserApi = createApiForWebAPI(UserApi)
    userRoleApi: UserRolesApi = createApiForWebAPI(UserRolesApi)

    filters: any = {}

    apiHeader: any
    apiHeaderForFile: any

    userBackOfficeLanguage: any

    /// Attributes
    // Page Types
    userAuthPageRole: any = null
    userRolePageRole: any = null

    webUserList: any = []
    filteredWebUserList: any = []
    
    // User Auth
    userAuthNewUser: any = {}
    userAuthUsers: any = []
    userAuthUserEdit: any = {}
    userAuthUserDelete: any = {}
    userAuthUserEditDialog = false
    deleteUserAuthUserDialog = false
    userAuthNewUserSubmitted = false
    userAuthUserEditSubmitted = false
    isUserAuthCardOpen = false

    // User Roles
    userRolesNewRole: any = {
        dashboardReports: "",
        deleteReservations: "",
        systemPreferences: "",
        rules: "",
        userAuthorization: "",
        userRole: "",
        supportCenter: "",
    }
    userRoles: any = []
    userRoleEdit: any = {}
    userRoleDelete: any = {}
    userRoleEditDialog = false
    deleteUserRoleDialog = false
    userRolesNewRoleSubmitted = false
    userRolesEditSubmitted = false
    isUserRolesCardOpen = false

    userRolesDict: any = {
        'Dashboard-Everyone': "Everyone",
        'Dashboard-Employee': "Affiliated Employees",
        'System-Edit': "Edit",
        'System-View': "View",
        'Rules-Edit': "Edit",
        'Rules-View': "View",
        'UserAuth-Edit': "Edit",
        'UserAuth-View': "View",
        'UserRoles-Edit': "Edit",
        'UserRoles-View': "View",
        'SupportCenter': "Can get support",
        'Report-Edit': "Can Report Edit",
        null: "No Access"
    }


    created() {
        this.apiHeaderForFile = {
            headers: {
                Authorization: 'Bearer ' + this.$keycloak.token,
                'Content-Type': 'multipart/form-data'
            }
        }
        this.initFilters();
        this.loadRoles();
        this.listWebUsers();
        this.translateSomeWordsOfBackOfficeLanguage();
        if (this.userAuthPageRole){
            this.listUserAuthUsers();
        }
        if (this.userRolePageRole || this.userAuthPageRole){
            this.listUserRoles();
        }
    }

    mounted() {
        // this.listUserAuthUsers()
        // this.listUserRoles()
    }
    
    @Watch('$i18n.locale')
      onPropertyChanged(value: string, oldValue: string) {
        this.translateSomeWordsOfBackOfficeLanguage();
    }
    
    initFilters(): any {
        this.filters = {
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    }

    translateSomeWordsOfBackOfficeLanguage(){
        this.userRolesDict['Dashboard-Everyone'] = this.$t('everyone');
        this.userRolesDict['Dashboard-Employee'] = this.$t('affiliatedEmployees');
        this.userRolesDict['System-Edit'] = this.$t('edit');
        this.userRolesDict['System-View'] = this.$t('view');
        this.userRolesDict['Rules-Edit'] = this.$t('edit');
        this.userRolesDict['Rules-View'] = this.$t('view');
        this.userRolesDict['UserAuth-Edit'] = this.$t('edit');
        this.userRolesDict['UserAuth-View'] = this.$t('view');
        this.userRolesDict['UserRoles-Edit'] = this.$t('edit');
        this.userRolesDict['UserRoles-View'] = this.$t('view');
        this.userRolesDict['SupportCenter'] = this.$t('canGetSupport');
        this.userRolesDict['DeleteReservations'] = this.$t('canDeleteReservations');
        this.userRolesDict['null'] = this.$t('noAccess');
    }

    // Role Loading
    loadRoles() {
        this.userAuthPageRole = rolesJson['userAuthorization'];
        this.userRolePageRole = rolesJson['userRole'];
    }

    // Web User Email Search Filter method
    searchForWebEmails(event: any) {
        this.filteredWebUserList = [...this.webUserList].filter(x => x.toLowerCase().includes(event.query.toLowerCase()))
    }

    // Card Functions
    changeIsUserRolesCardOpen(newVal: any) {
        this.isUserRolesCardOpen = newVal
    }
    
    changeIsUserAuthCardOpen(newVal: any) {
        this.isUserAuthCardOpen = newVal
    }

    // List Functions
    listWebUsers(){
        this.userApi.webapiUserGetWebUserByTenantIdGet(this.$apiHeader).then(x => {
            this.webUserList = x.data.data
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on listing Web Users", life: 3000 });
        })
    }

    listUserAuthUsers() {
        this.userRoleApi.webapiUserRolesListUsersGet(this.$apiHeader).then(x => {
            this.userAuthUsers = x.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on listing Autharized Users", life: 3000 });
        });
    }
    
    listUserRoles() {
        this.userRoleApi.webapiUserRolesListUserRolesGet(this.$apiHeader).then(x => {
            this.userRoles = x.data.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on listing User Roles", life: 3000 });
        });
    }

    // Add Functions
    async addUserAuthNewUser() {
        this.appStore.progressSpinnerShow = true

        this.userAuthNewUserSubmitted = true
        if (this.userAuthNewUser.email && this.userAuthNewUser.role) {
            await this.userRoleApi.webapiUserRolesAddRoleToUserPost(this.userAuthNewUser.email, this.userAuthNewUser.role.roleName, this.$apiHeader).then(x => {
                if(x.data.responseCode == 200){
                    this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
                }
                else {
                    this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life: 3000});
                }
                
                this.listUserAuthUsers();
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on ading User Authorization", life: 3000 });
            })
            this.userAuthNewUser = {};
			this.userAuthNewUserSubmitted = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async adduserRoles() {
        this.appStore.progressSpinnerShow = true

        this.userRolesNewRoleSubmitted = true;
        if (this.userRolesNewRole.roleName) {
            if (this.userRolesNewRole.dashboardReports || this.userRolesNewRole.systemPreferences || this.userRolesNewRole.rules || this.userRolesNewRole.userAuthorization || this.userRolesNewRole.userRole || this.userRolesNewRole.supportCenter){
                this.userRolesEmptyStrAttributesToNull();
                await this.userRoleApi.webapiUserRolesCreateNewRolePost(this.userRolesNewRole, this.$apiHeader).then(x => {
                    this.userRolesNewRole = {
                        dashboardReports: "",
                        deleteReservations: "",
                        systemPreferences: "",
                        rules: "",
                        userAuthorization: "",
                        userRole: "",
                        supportCenter: "",
                    }
                    this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
                    this.listUserRoles();
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on ading User Rule", life: 3000 });
                })
                this.userRolesNewRoleSubmitted = false;
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "All choices cannot be No Access", life: 3000 });
            }
        }
        this.appStore.progressSpinnerShow = false

    }

    // Edit Functions
    editUserAuthUser(userAuthUser: any) {
        this.userAuthUserEdit = { email: userAuthUser.email };
        this.userAuthUserEdit.role = (this.userRoles.filter((x: any) => x.roleName == userAuthUser.roleName))[0]
        this.userAuthUserEditDialog = true;
    }

    editUserRole(userRole: any) {
        this.userRoleEdit = { ...userRole, oldRoleName: userRole.roleName, UserRoleDetailId: userRole.id };
        this.userRolesEditNullAttributesToEmptyStr();
        this.userRoleEditDialog = true;
    }

    // Hide Functions
    hideUserAuthEditDialog() {
        this.userAuthUserEditDialog = false;
        this.userAuthUserEditSubmitted = false;
    }

    hideUserRolesEditDialog() {
        this.userRoleEditDialog = false;
        this.userRolesEditSubmitted = false;
    }

    // Confirm Functions
    confirmDeleteUserAuthUser(data: any): void {
        this.userAuthUserDelete = {
            email: data.email,
            roleName: data.roleName
        }
        this.deleteUserAuthUserDialog = true;
    }

    confirmDeleteUserRole(data: any): void {
        this.userRoleDelete = {
            roleId: data.id,
            groupName: data.roleName,
        }
        this.deleteUserRoleDialog = true;
    }

    // Save Functions
    async saveUserAuthEditUser() {
        this.appStore.progressSpinnerShow = true

        this.userAuthUserEditSubmitted = true;
        await this.userRoleApi.webapiUserRolesUpdateUserRolesPost(this.userAuthUserEdit.email, this.userAuthUserEdit.role.roleName ,this.$apiHeader).then(x => {
            this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
            this.listUserAuthUsers();
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on updating User Authorization", life: 3000 });
        })
        this.userAuthUserEdit = {};
        this.userAuthUserEditSubmitted = false;
        this.userAuthUserEditDialog = false;

        this.appStore.progressSpinnerShow = false

    }

    async saveUserRolesEditUser() {
        this.appStore.progressSpinnerShow = true

        this.userRolesEditSubmitted = true;
        // some back end function
        if (this.userRoleEdit.roleName){
            this.userRolesEditEmptyStrAttributesToNull();
            await this.userRoleApi.webapiUserRolesUpdateGroupRolePost(this.userRoleEdit, this.$apiHeader).then(x => {
                this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
                this.listUserRoles();
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on updating User Role", life: 3000 });
            });
            this.userRoleEdit = {}
            this.userRolesEditSubmitted = false;
            this.userRoleEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    // Delete Functions
    async deleteUserAuth() {
        this.appStore.progressSpinnerShow = true

        await this.userRoleApi.webapiUserRolesDeleteRoleFromUserDelete(this.userAuthUserDelete.email, this.userAuthUserDelete.roleName, this.$apiHeader).then(x => {
            this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
            this.listUserAuthUsers();
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on deleting User Authorization", life: 3000 });
        })
        this.deleteUserAuthUserDialog = false;
        this.appStore.progressSpinnerShow = false

    }

    async deleteUserRole() {
        this.appStore.progressSpinnerShow = true

        await this.userRoleApi.webapiUserRolesDeleteGroupRoleDelete(this.userRoleDelete.roleId, this.userRoleDelete.groupName, this.$apiHeader).then(x => {
            this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
            this.listUserRoles();
            this.listUserAuthUsers();
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on deleting User Role", life: 3000 });
        })
        this.deleteUserRoleDialog = false;
        this.appStore.progressSpinnerShow = false

    }

    // Transaction Functions
    userRolesEmptyStrAttributesToNull() {
        if (this.userRolesNewRole.dashboardReports == ""){
            this.userRolesNewRole.dashboardReports = null
        }
        if (this.userRolesNewRole.deleteReservations == ""){
            this.userRolesNewRole.deleteReservations = null
        }
        if (this.userRolesNewRole.rules == ""){
            this.userRolesNewRole.rules = null
        }
        if (this.userRolesNewRole.userAuthorization == ""){
            this.userRolesNewRole.userAuthorization = null
        }
        if (this.userRolesNewRole.userRole == ""){
            this.userRolesNewRole.userRole = null
        }
        if (this.userRolesNewRole.supportCenter == ""){
            this.userRolesNewRole.supportCenter = null
        }
        if (this.userRolesNewRole.systemPreferences == ""){
            this.userRolesNewRole.systemPreferences = null
        }
    }

    userRolesEditNullAttributesToEmptyStr() {
        if (this.userRoleEdit.dashboardReports == null){
            this.userRoleEdit.dashboardReports = ""
        }
        if (this.userRoleEdit.deleteReservations == null){
            this.userRoleEdit.deleteReservations = ""
        }
        if (this.userRoleEdit.rules == null){
            this.userRoleEdit.rules = ""
        }
        if (this.userRoleEdit.userAuthorization == null){
            this.userRoleEdit.userAuthorization = ""
        }
        if (this.userRoleEdit.userRole == null){
            this.userRoleEdit.userRole = ""
        }
        if (this.userRoleEdit.supportCenter == null){
            this.userRoleEdit.supportCenter = ""
        }
        if (this.userRoleEdit.systemPreferences == null){
            this.userRoleEdit.systemPreferences = ""
        }
    }
    
    userRolesEditEmptyStrAttributesToNull() {
        if (this.userRoleEdit.dashboardReports == ""){
            this.userRoleEdit.dashboardReports = null
        }
        if (this.userRoleEdit.deleteReservations == ""){
            this.userRoleEdit.deleteReservations = null
        }
        if (this.userRoleEdit.rules == ""){
            this.userRoleEdit.rules = null
        }
        if (this.userRoleEdit.userAuthorization == ""){
            this.userRoleEdit.userAuthorization = null
        }
        if (this.userRoleEdit.userRole == ""){
            this.userRoleEdit.userRole = null
        }
        if (this.userRoleEdit.supportCenter == ""){
            this.userRoleEdit.supportCenter = null
        }
        if (this.userRoleEdit.systemPreferences == ""){
            this.userRoleEdit.systemPreferences = null
        }
    }
}