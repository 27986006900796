<template>
    <div v-if="appStore.progressSpinnerShow" class="loading-overlay">
        <ProgressSpinner style="width: 100px; height: 100px" animationDuration=".8s" fill="transparent"  :z-index=999 :is-full-page=true />
    </div>
  <Toast />
  <div :class="containerClass" v-if="isKeycloakTokenLoaded" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" style="display: flex; flex-direction: column; justify-content: space-between;" @click="onSidebarClick">
        <AppMenu @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card" style="min-height: calc(100vh - 9rem)">
                <router-view />
            </div>
        </div>
    </div>

    <transition name="layout-mask">
        <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>
<style scoped>
  .loading-overlay{
    position: fixed;
    left: 0;
    top:0;
    z-index: 9999999;
    height: 100%;
    width: 100%;
    background-color: #34343433;
    display: flex;
    justify-content: center;
    align-items: center;
    color : var(--primary-color)
  }
</style>

<script>
import AppTopBar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import SystemPreferences from './views/SystemPreferences/SystemPreferences'
import Dashboards from './views/Dashboards/Dashboards'
import Reports from './views/Reports/Reports'
import UserRoles from './views/UserRoles/UserRoles'
import SuperAdmins from './views/SuperAdmins/SuperAdmin'

import { createApiForWebAPI } from "@/api"
import {UserApi } from '@/metadata/hybee-web-api'
import { usePrimeVue } from "primevue/config";
import { defineComponent, onMounted } from "vue";
import jwt_decode from "jwt-decode";
import rolesJson from "./roles/roles.json";
import { useAppStore } from '@/store/app';


const userApi = createApiForWebAPI(UserApi);
let userBackOfficeLanguage = "";



export default {




    // setup() {
    //     const changeToTurkish = () => {
    //         const primevue = usePrimeVue();
    //         primevue.config.locale.accept = 'Kabul';
    //         primevue.config.locale.reject = 'Red';
    //         primevue.config.locale.dayNamesMin = ['Pa','Pz','Sa','Çr','Pr','Cu','Ct'];
    //         primevue.config.locale.dayNames = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
    //     }

    //     onMounted(() => {
    //         changeToTurkish();
    //     })
    // },

    emits: ['change-theme'],
    data() {
        return {
            appStore : useAppStore(),
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            isKeycloakTokenLoaded: false,
            isFirstLogin: true,
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
        },
        '$keycloak.token'() {
            if (this.$keycloak.token != null){
                this.isKeycloakTokenLoaded = true

                // role define
                let decodedJwt = jwt_decode(this.$keycloak.token)
                let roleList = decodedJwt.realm_access.roles

                if (roleList.includes('UserAuth-Edit')){
                    rolesJson['userAuthorization'] = 'UserAuth-Edit';
                }
                else if(roleList.includes('UserAuth-View')){
                    rolesJson['userAuthorization'] = 'UserAuth-View';
                }

                if (roleList.includes('UserRoles-Edit')){
                    rolesJson['userRole'] = 'UserRoles-Edit';
                }
                else if(roleList.includes('UserRoles-View')){
                    rolesJson['userRole'] = 'UserRoles-View';
                }

                if (roleList.includes('Dashboard-Everyone')){
                    rolesJson['dashboardReports'] = 'Dashboard-Everyone';
                }
                else if(roleList.includes('Dashboard-Employee')){
                    rolesJson['dashboardReports'] = 'Dashboard-Employee';
                }

                if (roleList.includes('System-Edit')){
                    rolesJson['systemPreferences'] = 'System-Edit';
                }
                else if(roleList.includes('System-View')){
                    rolesJson['systemPreferences'] = 'System-View';
                }

                if (roleList.includes('Rules-Edit')){
                    rolesJson['rules'] = 'Rules-Edit';
                }
                else if(roleList.includes('Rules-View')){
                    rolesJson['rules'] = 'Rules-View';
                }

                if (roleList.includes('SupportCenter')){
                    rolesJson['supportCenter'] = 'SupportCenter';
                }

                if (roleList.includes('DeleteReservations')){
                    rolesJson['deleteReservations'] = 'DeleteReservations';
                }
                if (roleList.includes('SuperAdmin')){
                    rolesJson['superAdmin'] = 'SuperAdmin';
                }

                if (roleList.includes('MultiReservations-Edit')){
                    rolesJson['multiReservations'] = 'MultiReservations-Edit';
                }
                else if(roleList.includes('MultiReservations-View')){
                    rolesJson['multiReservations'] = 'MultiReservations-View';
                }

                
                this.$router.beforeEach((to, from, next) => {
                    if (to.meta.authRequired === 'true') {

                        //access check

                        if (to.name === "Dashboards"){
                            if (rolesJson['dashboardReports'] != null) {
                                return next()
                            }
                            else {
                                if(rolesJson['systemPreferences'] != null){
                                    this.$router.push({
                                        name: 'SystemPreferences'
                                    })
                                }
                                else if(rolesJson['rules'] != null){
                                    this.$router.push({
                                        name: 'Rules'
                                    })
                                }
                                else if(rolesJson['userRole'] != null || rolesJson['userAuthorization'] != null){
                                    this.$router.push({
                                        name: 'UserRoles'
                                    })
                                }
                                else if(rolesJson['supportCenter'] != null){
                                    this.$router.push({
                                        name: 'SupportCenter'
                                    })
                                }
                                else if(rolesJson['superAdmin'] != null){
                                    this.$router.push({
                                        name: 'SuperAdmin'
                                    })
                                }else if(rolesJson['multiReservations'] != null){
                                    this.$router.push({
                                        name: 'MultiReservations'
                                    })
                                }
                                
                                
                            }
                        }
                        else {
                            if (to.name === "SystemPreferences" && rolesJson['systemPreferences'] != null) {
                                return next()
                            }
                            else if (to.name === "Reports" && rolesJson['dashboardReports'] != null) {
                                return next()
                            }
                            else if (to.name === "Rules" && rolesJson['rules'] != null) {
                                return next()
                            }
                            else if (to.name === "UserRoles" && (rolesJson['userRole'] != null || rolesJson['userAuthorization'] != null)) {
                                return next()
                            }
                            else if (to.name === "SupportCenter" && rolesJson['supportCenter'] != null) {
                                return next()
                            }
                            else if (to.name === "SuperAdmin" && rolesJson['superAdmin'] != null) {
                                return next()
                            }
                            else if (to.name === "MultiReservations" && rolesJson['multiReservations'] != null) {
                                return next()
                            }
                            else {
                                this.$router.push({
                                    name: 'Dashboards'
                                })
                            }
                        }
                        
                    } else {
                        return next()
                    }
                });
            }

            this.$apiHeader.headers["Authorization"] = 'Bearer ' + this.$keycloak.token
            let acceptLanguageForWebApi = "";
            if (userBackOfficeLanguage != this.$i18n.locale || !this.$i18n.locale){
                const apiHeader = { headers: { Authorization: 'Bearer ' + this.$keycloak.token } }
                userApi.webapiUserGetUserLanguageAsyncGet(apiHeader).then(x => {
                    userBackOfficeLanguage = x.data.data;
                    if(userBackOfficeLanguage != null && userBackOfficeLanguage != ""){
                        if(userBackOfficeLanguage == "tr"){
                            this.$i18n.locale = "tr"
                            acceptLanguageForWebApi = "tr-TR"
                            this.changeToTurkish();
                        }
                        if(userBackOfficeLanguage == "en"){
                            this.$i18n.locale = "en";
                            acceptLanguageForWebApi = "en-US"
                            this.changeToEnglish();
                        }
                    }
                    else {
                        this.$i18n.locale = "en";
                        acceptLanguageForWebApi = "en-US"

                    }
                    // this.$apiHeader.headers["Accept-Language"] = this.$i18n.locale.toUpperCase();
                    this.$apiHeader.headers["Accept-Language"] = acceptLanguageForWebApi;

                    

                    if(this.isFirstLogin == true){
                        this.$router.push({name: "Dashboards"});
                        this.isFirstLogin = false;
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingGetLanguage'), life: 3000 });
                });
            }

            
        },

        '$i18n.locale'(){
            let acceptLanguageForWebApi = "";

            if(this.$i18n.locale == "tr"){
               this.changeToTurkish();
                this.$i18n.locale = "tr";
                acceptLanguageForWebApi = "tr-TR"

               
            }
            if(this.$i18n.locale == "en"){
               this.changeToEnglish();
                this.$i18n.locale = "en";
                acceptLanguageForWebApi = "en-US"

            }
            // this.$apiHeader.headers["Accept-Language"] = this.$i18n.locale.toUpperCase();
            this.$apiHeader.headers["Accept-Language"] = acceptLanguageForWebApi;

            this.$apiHeader.headers["Authorization"] = 'Bearer ' + this.$keycloak.token
        },


    },
   
    

    methods: {
        changeToTurkish(){
            // tr çevirilerinin tamamı --> https://github.com/primefaces/primelocale/blob/main/tr.json
            this.$primevue.config.locale.accept = 'Kabul';
            this.$primevue.config.locale.reject = 'Red';
            this.$primevue.config.locale.dayNamesMin = ['Pa','Pz','Sa','Çr','Pr','Cu','Ct'];
            this.$primevue.config.locale.dayNames = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
            this.$primevue.config.locale.monthNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

        },  
        changeToEnglish(){

            this.$primevue.config.locale.startsWith = 'Starts with',
            this.$primevue.config.locale.contains = 'Contains',
            this.$primevue.config.locale.notContains = 'Not contains',
            this.$primevue.config.locale.endsWith = 'Ends with',
            this.$primevue.config.locale.equals = 'Equals',
            this.$primevue.config.locale.notEquals = 'Not equals',
            this.$primevue.config.locale.noFilter = 'No Filter',
            this.$primevue.config.locale.lt = 'Less than',
            this.$primevue.config.locale.lte = 'Less than or equal to',
            this.$primevue.config.locale.gt = 'Greater than',
            this.$primevue.config.locale.gte = 'Greater than or equal to',
            this.$primevue.config.locale.dateIs = 'Date is',
            this.$primevue.config.locale.dateIsNot = 'Date is not',
            this.$primevue.config.locale.dateBefore = 'Date is before',
            this.$primevue.config.locale.dateAfter = 'Date is after',
            this.$primevue.config.locale.clear = 'Clear',
            this.$primevue.config.locale.apply = 'Apply',
            this.$primevue.config.locale.matchAll = 'Match All',
            this.$primevue.config.locale.matchAny = 'Match Any',
            this.$primevue.config.locale.addRule = 'Add Rule',
            this.$primevue.config.locale.removeRule = 'Remove Rule',
            this.$primevue.config.locale.accept = 'Yes',
            this.$primevue.config.locale.reject = 'No',
            this.$primevue.config.locale.choose = 'Choose',
            this.$primevue.config.locale.upload = 'Upload',
            this.$primevue.config.locale.cancel = 'Cancel',
            this.$primevue.config.locale.completed = 'Completed',
            this.$primevue.config.locale.pending = 'Pending',
            this.$primevue.config.locale.dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            this.$primevue.config.locale.dayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            this.$primevue.config.locale.dayNamesMin = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
            this.$primevue.config.locale.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            this.$primevue.config.locale.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            this.$primevue.config.locale.chooseYear = 'Choose Year',
            this.$primevue.config.locale.chooseMonth = 'Choose Month',
            this.$primevue.config.locale.chooseDate = 'Choose Date',
            this.$primevue.config.locale.prevDecade = 'Previous Decade',
            this.$primevue.config.locale.nextDecade = 'Next Decade',
            this.$primevue.config.locale.prevYear = 'Previous Year',
            this.$primevue.config.locale.nextYear = 'Next Year',
            this.$primevue.config.locale.prevMonth = 'Previous Month',
            this.$primevue.config.locale.nextMonth = 'Next Month',
            this.$primevue.config.locale.prevHour = 'Previous Hour',
            this.$primevue.config.locale.nextHour = 'Next Hour',
            this.$primevue.config.locale.prevMinute = 'Previous Minute',
            this.$primevue.config.locale.nextMinute = 'Next Minute',
            this.$primevue.config.locale.prevSecond = 'Previous Second',
            this.$primevue.config.locale.nextSecond = 'Next Second',
            this.$primevue.config.locale.am = 'am',
            this.$primevue.config.locale.pm = 'pm',
            this.$primevue.config.locale.today = 'Today',
            this.$primevue.config.locale.weekHeader = 'Wk',
            this.$primevue.config.locale.firstDayOfWeek = 0,
            this.$primevue.config.locale.dateFormat = 'mm/dd/yy',
            this.$primevue.config.locale.weak = 'Weak',
            this.$primevue.config.locale.medium = 'Medium',
            this.$primevue.config.locale.strong = 'Strong',
            this.$primevue.config.locale.passwordPrompt = 'Enter a password',
            this.$primevue.config.locale.emptyFilterMessage = 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
            this.$primevue.config.locale.searchMessage = '{0} results are available',
            this.$primevue.config.locale.selectionMessage = '{0} items selected',
            this.$primevue.config.locale.emptySelectionMessage = 'No selected item',
            this.$primevue.config.locale.emptySearchMessage = 'No results found',
            this.$primevue.config.locale.emptyMessage = 'No available options',
            this.$primevue.config.locale.aria = {
                trueLabel : 'True',
                falseLabel : 'False',
                nullLabel : 'Not Selected',
                star : '1 star',
                stars : '{star} stars',
                selectAll : 'All items selected',
                unselectAll : 'All items unselected',
                close : 'Close',
                previous : 'Previous',
                next : 'Next',
                navigation : 'Navigation',
                scrollTop : 'Scroll Top',
                moveTop : 'Move Top',
                moveUp : 'Move Up',
                moveDown : 'Move Down',
                moveBottom : 'Move Bottom',
                moveToTarget : 'Move to Target',
                moveToSource : 'Move to Source',
                moveAllToTarget : 'Move All to Target',
                moveAllToSource : 'Move All to Source',
                pageLabel : '{page}',
                firstPageLabel : 'First Page',
                lastPageLabel : 'Last Page',
                nextPageLabel : 'Next Page',
                prevPageLabel : 'Previous Page',
                rowsPerPageLabel : 'Rows per page',
                jumpToPageDropdownLabel : 'Jump to Page Dropdown',
                jumpToPageInputLabel : 'Jump to Page Input',
                selectRow : 'Row Selected',
                unselectRow : 'Row Unselected',
                expandRow : 'Row Expanded',
                collapseRow : 'Row Collapsed',
                showFilterMenu : 'Show Filter Menu',
                hideFilterMenu : 'Hide Filter Menu',
                filterOperator : 'Filter Operator',
                filterConstraint : 'Filter Constraint',
                editRow : 'Row Edit',
                saveEdit : 'Save Edit',
                cancelEdit : 'Cancel Edit',
                listView : 'List View',
                gridView : 'Grid View',
                slide : 'Slide',
                slideNumber : '{slideNumber}',
                zoomImage : 'Zoom Image',
                zoomIn : 'Zoom In',
                zoomOut : 'Zoom Out',
                rotateRight : 'Rotate Right',
                rotateLeft : 'Rotate Left'
            }
        }, 

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle(e) {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            e.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        changeTheme(event) {
            this.$emit('change-theme', event);
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu
    }
}
</script>


