import { FilterMatchMode } from 'primevue/api';
import { Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { BuildingApi, CarParkApi, ShuttleApi, DiningHallApi, FloorApi, ResponseCode, RoomApi, RoomServiceApi, UserApi, RuleApi, DashboardApi } from '@/metadata/hybee-web-api'
//import { DashboardApi } from '@/metadata/hybee-report-api'
import { createApiForWebAPI} from "@/api"
import rolesJson from "../../roles/roles.json";

export default class Dashboards extends Vue {

    dashboardApi: DashboardApi = createApiForWebAPI(DashboardApi)
    buildingApi: BuildingApi = createApiForWebAPI(BuildingApi)
    floorApi: FloorApi = createApiForWebAPI(FloorApi)
    roomApi: RoomApi = createApiForWebAPI(RoomApi)
    userApi: UserApi = createApiForWebAPI(UserApi)

    dashboardAndReportPageRole: any = null

    isHidden= false
    isHidden1= true
    isHidden2= true

    buttonsWeekly=true
    buttonsYearly=true

    clickedWeekly=false
    clickedYearly=true

    selectedRange= null
    selectedRange1=null
    selectedRange2=null
    selectedRange3=null    
            values= [
                {name: 'En Düşük', id:1},
                {name: 'Ortalama', id:2},
                {name: 'En Yüksek', id:3}
            ]

myfunc() {
       if(this.clickedWeekly){
        this.clickedWeekly=!this.clickedWeekly
       }
    }
    delete() {
        // terminate the function
        // if an async request is processing
        if (this.clickedWeekly === true) {
          return;
        }
    }
     
//    data() {
//     return {
//         basicData: { //renkler barların renkleri 1.-2.
//             labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
//             datasets: [
//                 {
//                     label: 'Capacity', //tıklayınca yok oluyor
//                     backgroundColor: '#0E1F79',
//                     data: [52,52,52,52,52,52,52]
//                 },
//                 {
//                     label: 'Reservation count',
//                     backgroundColor: '#FF056E',
//                     data: [20,30,52,35,26,15,5]
//                 }
//             ]
//         },
//         basicData1: { //renkler barların renkleri 1.-2.
//             labels: ['Week1', 'Week2', 'Week3', 'Week4', 'Week5', 'Week6', 'Week7'],
//             datasets: [
//                 {
//                     label: 'Capacity', //tıklayınca yok oluyor
//                     backgroundColor: '#0E1F79',
//                     data: [52,52,52,52,52,52,52]
//                 },
//                 {
//                     label: 'Reservation count',
//                     backgroundColor: '#FF056E',
//                     data: [20,30,52,35,26,15,5]
//                 }
//             ]
//         },
//         basicData2: { //renkler barların renkleri 1.-2.
//             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
//             datasets: [
//                 {
//                     label: 'Capacity', //tıklayınca yok oluyor
//                     backgroundColor: '#0E1F79',
//                     data: [52,52,52,52,52,52,52,52,52,52,52,52]
//                 },
//                 {
//                     label: 'Reservation count',
//                     backgroundColor: '#FF056E',
//                     data: [20,30,52,35,26,15,5,50,14,87,11,41]
//                 }
//             ]
//         },
//         basicOptions: {
//             plugins: {
//                 legend: { // capacity-rescount yazı rengi
//                     labels: {
//                         color: '#495057'
//                     }
//                 }
//             },
//             scales: { //ticks=> monday tuesday yazısı, grid=> soldan sağa çubuklar
//                 x: {
//                     ticks: {
//                         color: '#495057'
//                     },
//                     grid: {
//                         color: '#ebedef'
//                     }
//                 },
//                 y: { //ticks=> soldaki sayıların rengi, grid=> yatay çizginin rengi
//                     ticks: {
//                         color: '#495057'
//                     },
//                     grid: {
//                         color: '#ebedef'
//                     }
//                 }
//             }
//         },
//         basicOptions1: {
//             plugins: {
//                 legend: { // capacity-rescount yazı rengi
//                     labels: {
//                         color: '#495057'
//                     }
//                 }
//             },
//             scales: { //ticks=> monday tuesday yazısı, grid=> soldan sağa çubuklar
//                 x: {
//                     ticks: {
//                         color: '#495057'
//                     },
//                     grid: {
//                         color: '#ebedef'
//                     }
//                 },
//                 y: { //ticks=> soldaki sayıların rengi, grid=> yatay çizginin rengi
//                     ticks: {
//                         color: '#495057'
//                     },
//                     grid: {
//                         color: '#ebedef'
//                     }
//                 }
//             }
//         }
       
//     }
// }

filters: any = {}

apiHeader: any
apiHeaderForFile: any

currentDate : Date = new Date()
defaultStartDate : Date = new Date(new Date().setDate(this.currentDate.getDate()-7))
 
dashboard: any = {}
dashboardStartAndEndDate: any = []

dashboardCard: any = {}


buildings: any = []
buildingAll = {
    name : "All",  
    id : -1
}


floors: any = []
floorAll : any = {
    name : "All",  
    id : -1
}
//usingDeskCardFloors : any = []

rooms: any = []
roomAll : any = {
    name : "All",  
    id : -1
}

chartDataPie:any = {}

chartDataBarWeekly: any = {}
chartDataBarYearly: any = {}

pieChartData:any = {}
pieChartColorList:any =[]

checkInResults: any = {}
checkInChartData: any = {}
checkInChartOptions: any = {
    cutout: '60%',
    responsive: true,
    radius: 125,
}

//cardsData: any = {}
cardsData: any = {
    dashboardMeetingRoomCardResponseDto:{
        averagePercentage:0,
        mostPreferredTimeRange:" - "
    },
    dashboardCarParkCardResponseDto:{
        averagePercentage:0,
        mostPreferredDayOfWeek:" - "
    },
    dashboardDiningHallCardResponseDto:{
        averagePercentage:0,
        mostPreferredDayOfWeek:" - "
    },
    dashboardStudyDeskCardResponseDto:{
        averagePercentage: 0,
        mostPrefferedWeekday: " - "
    }
}

optionsForWeekly: any = {
    plugins: {
        tooltip: {
            callbacks: {},
        },
    }
};
optionsForYearly: any = {
    plugins: {
        tooltip: {
            callbacks: {},
        },
    }
};

dasboardDiningHallCardBuildingDropdownDisabled : any = false
dasboardCarParkCardBuildingDropdownDisabled : any = false
dasboardStudyDeskFloorDropdownDisabled : any = true

userBackOfficeLanguage: any


created() {
    // this.$apiHeader = { headers: { Authorization: 'Bearer ' + this.$keycloak.token, "Accept-Language":this.$i18n.locale.toUpperCase() } }
    // this.$apiHeader = this.$apiHeader;
    this.apiHeaderForFile = {
        headers: {
            Authorization: 'Bearer ' + this.$keycloak.token,
            'Content-Type': 'multipart/form-data'
        }
    }
    this.initFilters();
    // this.fillDashboardRequestObject();
    // this.fillDashboardCardsRequestObject();
    // this.listRooms();
    // this.listCardsData();
    this.loadRoles();
    this.translateSomeWordsOfBackOfficeLanguage();
    if(this.dashboardAndReportPageRole){
        this.fillDashboardRequestObject();
        this.fillDashboardCardsRequestObject();
        this.listBuildings()
        this.listFloors()
        this.listRooms()
        this.reRenderPage()
    }
}

@Watch('$i18n.locale')
  onPropertyChanged(value: string, oldValue: string) {
    this.translateSomeWordsOfBackOfficeLanguage();
    if(this.dashboardAndReportPageRole){
        this.fillDashboardRequestObject();
        this.fillDashboardCardsRequestObject();
        this.listBuildings()
        this.listFloors()
        this.listRooms()
        this.reRenderPage()
    }
}



mounted(){
    // this.listBuildings()
    // this.listFloors()
    // this.listRooms()
    // this.reRenderPage()    
}

initFilters(): any {
    this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
    }
}

loadRoles() {
    this.dashboardAndReportPageRole = rolesJson['dashboardReports'];
}

translateSomeWordsOfBackOfficeLanguage(){
    this.buildingAll.name = this.$t('all')
    this.floorAll.name = this.$t('all')
    this.roomAll.name = this.$t('all')
}

reRenderPage(){
    this.listPieChartData()
    this.listBarChartData()
    this.listCardsData()
    this.listCheckInData()
}

fillDashboardRequestObject(){
    this.dashboard.buildingId = this.buildingAll.id
    this.dashboardCard.diningHallCardBuildingId = this.buildingAll.id
    this.dashboardCard.carParkCardBuildingId = this.buildingAll.id


    this.defaultStartDate.setHours(0,0,0,0)
    this.currentDate.setHours(0,0,0,0) 
    this.dashboardStartAndEndDate = [
        this.defaultStartDate,
        this.currentDate
        
    ]
    this.dashboard.reservationStartDate = this.dashboardStartAndEndDate[0]
    this.dashboard.reservationEndDate = this.dashboardStartAndEndDate[1]

}

fillDashboardCardsRequestObject(){
    this.dashboardCard.buildingId = this.buildingAll.id
    this.dashboardCard.roomId = this.roomAll.id

    this.defaultStartDate.setHours(0,0,0,0)
    this.currentDate.setHours(0,0,0,0) 
    this.dashboardStartAndEndDate = [
        this.defaultStartDate,
        this.currentDate
        
    ]
    this.dashboardCard.reservationStartDate = this.dashboardStartAndEndDate[0]
    this.dashboardCard.reservationEndDate = this.dashboardStartAndEndDate[1]

}


customDateString(date: Date){
    date = new Date(date)
    let dateString = new String()

    //Day format

    if(date.getDate().toString().length < 2){
        dateString +=  "0" + date.getDate().toString() + "."
    }
    else{
        dateString += date.getDate().toString() + "."
    }

    // Month format

    if(date.getMonth().toString().length < 2){
        dateString +=  "0" + date.getMonth().toString() + "."
    }
    else{
        dateString += date.getMonth().toString() + "."
    }

    // Year format

    dateString += date.getFullYear().toString() + " "

    // Hour format

    if(date.getHours().toString().length < 2){
        dateString +=  "0" + date.getHours().toString() + ":"
    }
    else{
        dateString += date.getHours().toString() + ":"
    }

    // Minute format

    if(date.getMinutes().toString().length < 2){
        dateString +=  "0" + date.getMinutes().toString()
    }
    else{
        dateString += date.getMinutes().toString()
    }

    return dateString

     
}


 /* List Functions */

 listPieChartData() {
    this.dashboardApi.webapiDashboardGetDashboardPieChartPost(this.dashboard, this.$apiHeader).then(x =>
        {
        this.pieChartData = x.data;
        
        for (let i = 0; i < this.pieChartData.departmentNameList.length; i++){
            if (this.pieChartData.departmentNameList[i].length > 45){
                this.pieChartData.departmentNameList[i] = this.pieChartData.departmentNameList[i].substring(0, 46) + "...";
            }
        }

        this.chartDataPie.labels = this.pieChartData.departmentNameList;

        this.chartDataPie.datasets = [{
            data : this.pieChartData.reservationCountList,
            backgroundColor : ["#0E1F79","#FF056E","#A6DD0A","#FF6B00","#323232","#28A8FF","#FFBB33","#A788FF"],
            //backgroundColor : this.randomColorListGenerator(this.pieChartData.departmentNameList),
            //hoverBackgroundColor : this.pieChartData.colorList
        }]
    }).catch(() => {
        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingPieChartData'), life: 3000 });
    });
}

listCardsData() {
    this.dashboardApi.webapiDashboardGetDashboardCardsPost(this.dashboardCard, this.$apiHeader).then(x =>
    {
        this.cardsData = x.data;    
    }).catch(() => {
        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingCardsData'), life: 3000 });
    });
}

listCheckInData() {

    this.dashboardApi.webapiDashboardGetDashboardCheckInChartPost(this.dashboard, this.$apiHeader).then(x => {
        this.checkInResults = x.data
        this.checkInChartData = {
            datasets: [
                {
                    data: [x.data.checkedIn, x.data.waiting, x.data.notCheckedIn, x.data.cancelled, x.data.failed, x.data.comingUp, x.data.spontaneous, x.data.checkout],
                    backgroundColor: ['#3FC300', '#FFB118', '#808080', '#0E1F79', '#FF0000', '#28A8FF', '#A788FF', '#322653'],
                }
            ]
        }
    })
    
}

listBarChartData() {
    this.dashboardApi.webapiDashboardGetDashboardChartPost(this.dashboard, this.$apiHeader).then(x => 
        {
            const barChartData = x.data
            
            const barChartWeeklyLabels: any[] = []
            const barChartWeeklyAverage: any[] = []
            const barChartWeeklyCapacities: any[] = []
            const barChartWeeklyHovers: any[] = []

            const barChartYearlyLabels: any[] = []
            const barChartYearlyAverage: any[] = []
            const barChartYearlyCapacities: any[] = []
            const barChartYearlyHovers: any[] = []

            barChartData.dashboardChartWeeklyBars!.forEach(weeklyBar => {
                const tempHoverData: any[] = [];
                barChartWeeklyCapacities.push(barChartData.capacity)
                barChartWeeklyAverage.push(weeklyBar.average) //?.toString().substring(0,2)
                barChartWeeklyLabels.push(weeklyBar.name)
                weeklyBar.hovers?.forEach(hover => {
                    tempHoverData.push(hover.date + ": " + hover.average);
                });
                barChartWeeklyHovers.push(tempHoverData);
            });

            barChartData.dashboardChartYearlyBars!.forEach(yearlyBar => {
                const tempHoverData: any[] = [];
                barChartYearlyCapacities.push(barChartData.capacity)
                barChartYearlyAverage.push(yearlyBar.average)
                barChartYearlyLabels.push(yearlyBar.name)
                yearlyBar.hovers?.forEach(hover => {
                    tempHoverData.push(hover.date + ": " + hover.average);
                });
                barChartYearlyHovers.push(tempHoverData);
            });

            this.chartDataBarWeekly.datasets = [{
                type: 'line',
                label: this.$t('capacity'),
                borderColor: '#FF056E',
                borderWidth: 2,
                fill: false,
                data: barChartWeeklyCapacities,
            },
            {
                type: 'bar',
                label: this.$t('reservations'),
                backgroundColor: '#0E1F79',
                data: barChartWeeklyAverage,
                borderColor: 'white',
                borderWidth: 1
            }]
            this.chartDataBarWeekly.labels = barChartWeeklyLabels
            const capacityStr = this.$t("capacity")
            this.optionsForWeekly.plugins.tooltip.callbacks.label = function(context: any, data: any) {
                if (context.dataset.type == 'bar'){
                    return (barChartWeeklyHovers[context.dataIndex]);
                }
                else {
                    return (capacityStr + ': ' + barChartWeeklyCapacities[0]);
                }
            }

            this.chartDataBarYearly.datasets = [{
                type: 'line',
                label: this.$t('capacity'),
                borderColor: '#FF056E',
                borderWidth: 2,
                fill: false,
                data: barChartYearlyCapacities,
            },
            {
                type: 'bar',
                label: this.$t('reservations'),
                backgroundColor: '#0E1F79',
                data: barChartYearlyAverage,
                borderColor: 'white',
                borderWidth: 1
            }]
            this.chartDataBarYearly.labels = barChartYearlyLabels
            this.optionsForYearly.plugins.tooltip.callbacks.label = function(context: any) {
                if (context.dataset.type == 'bar'){
                    return (barChartYearlyHovers[context.dataIndex]);
                }
                else {
                    return (capacityStr + ": " + barChartYearlyCapacities[0]);
                }
            }
        })
}

listBuildings() {
    this.buildingApi.webapiBuildingListAsyncGet(this.$apiHeader).then(x => 
        {
            this.buildings = x.data;
            this.buildings.unshift(this.buildingAll);
        }).catch(() => {
        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingBuildings'), life: 3000 });
    });
}

listRooms() {
    this.roomApi.webapiRoomListByBuildingIdAndFloorIdPost(this.dashboardCard, this.$apiHeader).then(x => 
        {
            this.rooms = x.data.data;
            this.rooms.unshift(this.roomAll) ;         
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingRooms'), life: 3000 });
        });
    
}

listFloors(){
    if(this.dashboard.buildingId != -1){

        this.dashboard.floorId = null;

        this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.dashboard.buildingId, this.$apiHeader).then(x => 
            {
                this.reRenderPage();
                this.floors = x.data;
                this.floors.unshift(this.floorAll);
            
            })
            .catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnListingFloors'), life: 3000 });
            });
    }
    else{
        this.reRenderPage()
    }  
}


/* List Function end */


/* Change Function */

buildingNameChange() {
    if(this.dashboard.buildingId == -1){
        this.dashboardCard.diningHallCardBuildingId = this.buildingAll.id
        this.dashboardCard.carParkCardBuildingId = this.buildingAll.id
        this.dashboardCard.floorId = this.floorAll.id
        this.dashboard.floorId = this.floorAll.id

        this.dasboardDiningHallCardBuildingDropdownDisabled = false
        this.dasboardCarParkCardBuildingDropdownDisabled = false
        this.dasboardStudyDeskFloorDropdownDisabled = true
    }
    else{
        this.dashboardCard.diningHallCardBuildingId = this.dashboard.buildingId
        this.dashboardCard.carParkCardBuildingId = this.dashboard.buildingId

        this.dasboardDiningHallCardBuildingDropdownDisabled = true
        this.dasboardCarParkCardBuildingDropdownDisabled = true
        this.dasboardStudyDeskFloorDropdownDisabled = false
    }


    this.listFloors();
    this.reRenderPage()
}

floorNameChange() {
    this.dashboardCard.floorId = this.dashboard.floorId
    this.dasboardStudyDeskFloorDropdownDisabled = true
    this.reRenderPage();
}

studyDeskUsageFloorNameChange() {
    this.listCardsData()
}

diningHallCardBuildingNameChange() {
    this.listCardsData()

}

carParkCardBuildingNameChange() {
    this.listCardsData()

}

roomNameChange(){
    this.listCardsData();
}

/* Change Function end */

randomColorListGenerator(list:any){
    
if(list != null){
    for(let i = 0; i < list.length; i++){
        const randomColor = "#"+Math.floor(Math.random()*16777215).toString(16);
        this.pieChartColorList.push(randomColor)
    }

    return this.pieChartColorList
}
    
}

dateSelect(){    
    if(this.dashboardStartAndEndDate[0] != null && this.dashboardStartAndEndDate[1] != null){
        this.dashboard.reservationStartDate = new Date(this.dashboardStartAndEndDate[0])
        this.dashboard.reservationEndDate = new Date(this.dashboardStartAndEndDate[1])

        this.dashboardCard.reservationStartDate = new Date(this.dashboardStartAndEndDate[0])
        this.dashboardCard.reservationEndDate = new Date(this.dashboardStartAndEndDate[1])
        this.reRenderPage()


    }
}



data() {
    return {


        chartData: {
            labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            datasets: [{
                type: 'line',
                label: 'Capacity',
                borderColor: '#FF056E',
                borderWidth: 2,
                fill: false,
                data: [50,50,50,50,50,50,50]
            }, {
                type: 'bar',
                label: 'Reservation count',
                backgroundColor: '#0E1F79',
                data: [21,84,24,75,37,65,34],
                borderColor: 'white',
                borderWidth: 1
            }]
        },
        chartOptions: {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        },
        chartData1: {
            labels: ['17.10', '24.10', '31.10', '07.11', '14.11', '21.11', '28.11'],
            datasets: [{
                type: 'line',
                label: 'Capacity',
                borderColor: '#FF056E',
                borderWidth: 2,
                fill: false,
                data: [50,50,50,50,50,50,50]
            }, {
                type: 'bar',
                label: 'Reservation count',
                backgroundColor: '#0E1F79',
                data: [21,84,24,75,37,65,34],
                borderColor: 'white',
                borderWidth: 1
            }]
        },
        chartOptions1: {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        },
        chartData2: {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'],
            datasets: [{
                type: 'line',
                label: 'Capacity',
                borderColor: '#FF056E',
                borderWidth: 2,
                fill: false,
                data: [52,52,52,52,52,52,52,52,52,52,52,52]
            }, {
                type: 'bar',
                label: 'Reservation count',
                backgroundColor: '#0E1F79',
                data: [20,30,52,35,26,15,5,50,14,87,11,41],
                borderColor: 'white',
                borderWidth: 1
            }]
        },
        chartOptions2: {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        }
    }
}
}