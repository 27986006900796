import { FilterMatchMode } from 'primevue/api';
import { Vue } from 'vue-class-component'
import { TenantParameterApi,TenantApi, CarParkUserAuthorizationApi,UserRolesApi,BuildingApi, AccessControlSystemApi,CarParkApi, ShuttleApi, DiningHallApi, FloorApi, FloorDeskApi, WorkingAreaApi, ResponseCode, RoomApi, RoomServiceApi, UserApi, RuleApi, CalenderApi, CalenderHolidayApi, PushNotificationApi, PolicyApi, IntegrationApi, DiningHallMenuApi, BuildingIpApi } from '@/metadata/hybee-web-api'
import { createApiForWebAPI } from "@/api"
import { usePrimeVue } from "primevue/config";
import rolesJson from "../../roles/roles.json";
import * as XLSX from 'xlsx'
// import "smart-tagz/dist/smart-tagz.css";

import { defineComponent } from "vue";
import { registerRuntimeHelpers } from '@vue/compiler-core';
import { ref } from 'vue'
import { toPathString } from '@/metadata/hybee-web-api/common';
import QrcodeVue from 'qrcode.vue'
import { qrcanvas } from 'qrcanvas';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import JSZip from "jszip"
import { saveAs } from 'file-saver';
import { useAppStore } from '@/store/app';

import { Watch } from 'vue-property-decorator'




export default class SystemPreferences extends Vue {
    appStore = useAppStore()

    buildingApi: BuildingApi = createApiForWebAPI(BuildingApi)
    floorApi: FloorApi = createApiForWebAPI(FloorApi)
    floorDeskApi: FloorDeskApi = createApiForWebAPI(FloorDeskApi)
    workingAreaApi: WorkingAreaApi = createApiForWebAPI(WorkingAreaApi)
    roomApi: RoomApi = createApiForWebAPI(RoomApi)
    roomServiceApi: RoomServiceApi = createApiForWebAPI(RoomServiceApi)
    diningHallApi: DiningHallApi = createApiForWebAPI(DiningHallApi)
    carParkApi: CarParkApi = createApiForWebAPI(CarParkApi)
    shuttleApi: ShuttleApi = createApiForWebAPI(ShuttleApi)
    userApi: UserApi = createApiForWebAPI(UserApi)
    ruleApi: RuleApi = createApiForWebAPI(RuleApi)
    calendarApi: CalenderApi = createApiForWebAPI(CalenderApi)
    calenderHolidayApi: CalenderHolidayApi = createApiForWebAPI(CalenderHolidayApi)
    pushNotificationApi: PushNotificationApi = createApiForWebAPI(PushNotificationApi)
    policyApi: PolicyApi = createApiForWebAPI(PolicyApi)
    accessControlSystemApi: AccessControlSystemApi = createApiForWebAPI(AccessControlSystemApi)
    integrationApi: IntegrationApi = createApiForWebAPI(IntegrationApi)
    userRoleApi: UserRolesApi = createApiForWebAPI(UserRolesApi)
    carParkUserAuthorizationApi: CarParkUserAuthorizationApi = createApiForWebAPI(CarParkUserAuthorizationApi)
    diningHallMenuApi: DiningHallMenuApi = createApiForWebAPI(DiningHallMenuApi)
    tenantApi: TenantApi = createApiForWebAPI(TenantApi)
    tenantParameterApi : TenantParameterApi = createApiForWebAPI(TenantParameterApi)
    buildingIpApi: BuildingIpApi = createApiForWebAPI(BuildingIpApi)

    systemPreferencesPageRole: any = null
    maxFileSize = "800 KB"
    active = ref(0);

    floorPlanBigImagePath:any = ""
    workingAreaPlanBigImagePath:any = ""
    meetingRoomPlanBigImagePath:any = ""
    filteredWebUserList: any = []
    webUserList: any = []
    floorPlanBaseUrl:any = "https://storage.googleapis.com/" + process.env.BUCKET_NAME + "/FloorPlans/"
    workingAreaPlanBaseUrl:any = "https://storage.googleapis.com/" + process.env.BUCKET_NAME + "/WorkingAreaPlans/"
    meetingRoomPlanBaseUrl:any = "https://storage.googleapis.com/" + process.env.BUCKET_NAME + "/MeetingRooms/"

    emailValidateBool = false
    emailValidateText = ""


    

    
    building: any = {}
    buildingsForCheckIn: any = {}

    buildingEdit: any = {}
    buildings: any = []
    buildingEditDialog = false
    buildingSubmitted = false
    buildingEditSubmitted = false
    deleteBuildingDialog = false
    buildingDelete: any = {}
    selectedBuilding: any = {}

    user: any = {}
    userEdit: any = {}
    selectedLanguage: any = {}
    users: any = []
    userEditDialog = false
    userSubmitted = false
    userEditSubmitted = false
    deleteUserDialog = false
    userDelete: any = {}
    addOrUpdateTitlesDialog = false
    addOrUpdateDepartmentsDialog = false
    userBackOfficeLanguage: any


    floor: any = {}
    buildingFloors: any = []
    floorEdit: any = {}
    floors: any = []
    floorSubmitted = false
    floorEditSubmitted = false
    floorDelete: any = {}
    deleteFloorDialog = false
    floorEditDialog = false


    //---------------------------------------------------------
    cacheWorkingAreaList:any=[]
    workingAreaSubmitted = false
    workingAreaLaterAddSubmitted = false
    workingArea:any={
        tempId: 0
    }
    workingAreaLaterAdd:any={}
    workingAreaLaterAddDialog = false
    workingAreaEdit:any={}
    workingAreaEditDialog = false
    workingAreaEditSubmitted = false
    tempWorkingAreaEditSubmitted = false
    deleteWorkingAreaDialog = false
    workingAreaDelete:any={}

    tempWorkingAreaEditDialog = false
    tempWorkingAreaEdit:any = {}
    deleteTempWorkingAreaDialog = false
    tempWorkingAreaDelete :any ={}
    workingAreas=[
        {
            name:"Cam Kenarı",
            capacity:30,
            imagePath:"xxx"
        },
        {
            name:"Duvar Kenarı",
            capacity:20,
            imagePath:"xxx"
        },
    
    ]

    newFloors = [
        {
            id:1,
            name:"1. Kat",
            workingAreas:[
                {
                    name:"Cam Kenarı",
                    capacity:30,
                    imagePath:"xxx"
                },
                {
                    name:"Duvar Kenarı",
                    capacity:20,
                    imagePath:"xxx"
                },
            
            ]
        },
        {
            id:2,
            name:"2. Kat",
            workingAreas:[
                {
                    name:"A Bölgesi",
                    capacity:30,
                    imagePath:"xxx"
                },
                {
                    name:"B Bölgesi",
                    capacity:20,
                    imagePath:"xxx"
                },
            
            ]
        }
    ]

    floorAndWorkingAreaDto:any = {
        floor:{}
    }

    floorImagePopup:any=false
    workingAreaImagePopup:any = false
    meetingRoomImagePopup:any = false

    isDivideWorkingArea = false
    floorCapacityInputVisible = true
    floorEditCapacityInputVisible = true
    expandedRows:any=[]

    
    diningHallMenu: any = {}
    diningHallMenuAdd: any = {}
    diningHallMenus: any = []
    diningHallMenuSubmitted = false
    diningHallMenuEditDialog = false
    diningHallMenuEdit: any = {}
    diningHallMenuEditSubmitted = false
    diningHallMenuList: any = {}

    diningHallMenuDeleteDialog = false
    diningHallMenuDelete: any = {}

    diningHallMenuFile: any = null;
    diningHallMenuFileUpload:any = {}
    selectFileVisibleDiningHall = true;

    diningHallMenuStartAndEndDate:any = []
    diningHallMenuEditStartAndEndDate:any = []
    

    diningHallMenuSectionIsVisible = false
    multipleReservationDaySettingsEn:any = [
        {name:"1 Day", day:1},
        {name:"2 Day", day:2},
        {name:"3 Day", day:3},
        {name:"4 Day", day:4},
        {name:"5 Day", day:5},
        {name:"6 Day", day:6},
        {name:"7 Day", day:7},
        {name:"8 Day", day:8},
        {name:"9 Day", day:9},
        {name:"10 Day", day:10},
        {name:"11 Day", day:11},
        {name:"12 day", day:12},
        {name:"13 Day", day:13},
        {name:"14 Day", day:14},
        {name:"15 Day", day:15}
    ]

    multipleReservationDaySettingsTr:any = [
        {name:"1 Gün", day:1},
        {name:"2 Gün", day:2},
        {name:"3 Gün", day:3},
        {name:"4 Gün", day:4},
        {name:"5 Gün", day:5},
        {name:"6 Gün", day:6},
        {name:"7 Gün", day:7},
        {name:"8 Gün", day:8},
        {name:"9 Gün", day:9},
        {name:"10 Gün", day:10},
        {name:"11 Gün", day:11},
        {name:"12 Gün", day:12},
        {name:"13 Gün", day:13},
        {name:"14 Gün", day:14},
        {name:"15 Gün", day:15}
    ]

    


    isDivideToogleUpdate(){
        if(this.isDivideWorkingArea == true){
            this.floorCapacityInputVisible = false
            this.floor.capacity = null
        }
        else{
            this.floorCapacityInputVisible = true
        }
        this.workingAreaSubmitted = false
        this.floorSubmitted = false

    }

    onRowExpand(event:any) {
           
        //this.expandedRows.push(JSON.parse(JSON.stringify(event.data)))
        //this.$toast.add({ severity: 'info', summary: 'Floor Expanded', detail: event.data.name, life: 3000 });
    }
    onRowCollapse(event:any){
        //delete this.expandedRows[event.data]
        //this.$toast.add({ severity: 'success', summary: 'Floor Collapsed', detail: event.data.name, life: 3000 });
    }

    setExpandColumns(){
        for(let j=0; j<this.floors.length; j++){
            this.floors[j].floorDividedIntoAreas = false

        }
        for(let i=0; i<this.floors.length; i++){
            if(this.floors[i].workingAreas != null){
                this.floors[i].floorDividedIntoAreas = true
            }

        }
    }


// ----------------------------------------------------------------

    title: any = {}
    titleEdit: any = {}
    titles: any = []
    titleEditDialog = false
    titleSubmitted = false
    titleEditSubmitted = false
    deleteTitleDialog = false
    titleDelete: any = {}


    department: any = {}
    departmentEdit: any = {}
    departments: any = []
    departmentEditDialog = false
    departmentSubmitted = false
    departmentEditSubmitted = false
    departmentDeleteDialog = false
    departmentDelete: any = {}


    file: any = new FormData();
    fileName: any = ""


    room: any = {}
    rooms: any = []
    roomService: any = {}
    roomServices: any = []
    roomFloors: any = []
    roomEditFloors: any = []
    roomSubmitted = false
    roomEdit: any = {}
    roomEditSubmitted = false
    roomEditDialog = false
    roomDelete: any = {}
    deleteRoomDialog = false
    servicesArray: any = []

    addAmenities: any = {}
    addAmanitiesSubmitted = false
    addOrUpdateAmenitiesDialog = false
    amenitiesEdit: any = {}
    amenitiesEditSubmitted = false
    amenitiesEditDialog = false
    amenitiesDelete: any = {}
    amenitiesDeleteSubmitted = false
    amenitiesDeleteDialog = false


    //emailValidateText = '/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/'

    // Wroking Area Reservation Management Variables
    workingAreaReservationManagement: any = {}
    
    diningHall: any = {}
    diningHalls: any = []
    diningHallEdit: any = {}
    diningHallEditDialog = false
    diningHallSubmitted = false
    diningHallEditSubmitted = false
    deletediningHallDialog = false
    diningHallDelete: any = {}

    carParks: any = []
    carPark: any = {}
    carParkEdit: any = {}
    carParkEditDialog = false
    carParkSubmitted = false
    carParkEditSubmitted = false
    deletecarParkDialog = false
    carParkDelete: any = {}


    ipAddresses: any = []
    ipAddressObject: any = {}
    ipAddressEdit: any = {}
    ipAddressEditDialog = false
    ipAddressSubmitted = false
    ipAddressEditSubmitted = false
    deleteIpAddressDialog = false
    ipAddressDelete: any = {}

    carParkUserAuthorizationSubmitted = false
    carParkUserAuthorizations: any = []
    carParkUserAuthorization: any = {}
    carParkUserAuthorizationEdit : any = {}
    carParkUserAuthorizationEditDialog = false;

    shuttles: any = []
    shuttle: any = {}
    shuttleEdit: any = {}
    shuttleEditDialog = false
    shuttleSubmitted = false
    shuttleEditSubmitted = false
    deleteshuttleDialog = false
    shuttleDelete: any = {}

    calendarList: any = []
    calendar: any = {}
    calendarWorkingDays: any = [] 
    calendarWorkingDay: any = {}
    calendarWorkingDayEdit: any = {}
    calendarWorkingDayEditDialog = false
    calendarWorkingDaySubmitted = false
    deletecalendarWorkingDayDialog = false
    calendarWorkingDayDelete: any = {}
    calendarHasNoEndingDate = false
    calendarReservationEndDateInputVisible = false
    calendarSubmitted:any=false

    holidays: any = []
    holiday: any = {}
    holidayEdit: any = {}
    holidayEditDialog = false
    holidaySubmitted = false
    holidayEditSubmitted = false
    deleteholidayDialog = false
    holidayDelete: any = {}
    
    filePolicy: any = null;
    selectFileVisible = true;
    policy: any;
    policyDelete: any = {}
    deletePolicyDialog = false

        
    integrationTypes:any = [
        {id:0, name:"None"},
        {id:1, name:"Azure AD"},
        {id:2, name:"Google Workspace"}
    ]
    integrationType:any = {}
    integrationSubmitted = false
    integrationDialog = false

    azureAD:any = {}
    googleWorkspace:any = {}
    
    filters: any = {}

    apiHeader: any
    apiHeaderForFile: any

    tenant: any = 0


    tenantParameters:any={}
    buildingServicesPreferences: any = {
        isUsingDiningHall: false,
        isUsingCarPark: false,
        isUsingShuttle: false,
        isUsingShuttleRoute: false,
        userAuthIsActive: false,
    }
    tenantReservationNotificationParameters:any={}
    buildingReservationNotificationParameters:any={}

    carParkReportSubmitted = false
    carParkReport:any = {isActive : true}
    //carParkReportCheckbox=true
    addReceiverEmailInvalid=false

    diningHallReportSubmitted = false
    diningHallReport:any = {isActive : true}
    //diningHallReportCheckbox=true
    addReceiverEmailInvalidDiningHall=false

    sendOnComingReservationSubmitted = false
    sendOnComingReservation:any = {isActive : true}

    checkin:any={}
    checkinNotification:any={}

    usingQrCodeBool :any =false
    usingDeskQrCodeBool: any=false
    reservationFailIfNoCheckinBool : any = false

    

    reservationFailsAftersEn:any = [
        {name:"1 Hour", hour:1},
        {name:"2 Hour", hour:2},
        {name:"3 Hour", hour:3}
    ]    
    reservationFailsAftersTr:any = [
        {name:"1 Saat", hour:1},
        {name:"2 Saat", hour:2},
        {name:"3 Saat", hour:3}
    ]    
    
    checkOutWorkTimeEn:any = [
        {name:"0 Hour", hour:0},
        {name:"1 Hour", hour:1},
        {name:"2 Hour", hour:2},
        {name:"3 Hour", hour:3},
        {name:"4 Hour", hour:4},
        {name:"5 Hour", hour:5},
        {name:"6 Hour", hour:6},
        {name:"7 Hour", hour:7},
        {name:"8 Hour", hour:8},
        {name:"9 Hour", hour:9}
    ]  
    checkOutWorkTimeTr:any = [
        {name:"0 Saat", hour:0},
        {name:"1 Saat", hour:1},
        {name:"2 Saat", hour:2},
        {name:"3 Saat", hour:3},
        {name:"4 Saat", hour:4},
        {name:"5 Saat", hour:5},
        {name:"6 Saat", hour:6},
        {name:"7 Saat", hour:7},
        {name:"8 Saat", hour:8},
        {name:"9 Saat", hour:9}
    ]
    reservationFailsAfter:any=1

    notificationNumbers:any = [1,2,3]

    notificationNumbersForCheckout:any = [1,2,3]

    notificationOftenEn:any = [
        {name:"15 Minute", minute:15},
        {name:"30 Minute", minute:30},
        {name:"60 Minute", minute:60}
    ]
    notificationOftenTr:any = [
        {name:"15 Dakika", minute:15},
        {name:"30 Dakika", minute:30},
        {name:"60 Dakika", minute:60}
    ]

    notificationOftenForCheckoutEn:any = [
        {name:"15 Minute", minute:15},
        {name:"30 Minute", minute:30},
        {name:"60 Minute", minute:60}
    ]
    notificationOftenForCheckoutTr:any = [
        {name:"15 Dakika", minute:15},
        {name:"30 Dakika", minute:30},
        {name:"60 Dakika", minute:60}
    ]

    accessControlSystemIntegrationDialog:any = false
    accessControlSystemIntegration:any = {}// {userName:null, password:null, authUrl:null, url:null}
    accessControlSystemIntegrationSubmitted:any = false
    accessControlSystemIntegrationChecked: any = false
    floorAndWorkingAreaDesks:any=[]
    floorAndWorkingAreaDesksFilterDto:any= {
        buildingId : null,
        floorId : null,
        workingAreaId : null
    }
    floorAndWorkingAreaDeskFilterFloors:any=[]
    floorAndWorkingAreaDeskFilterWorkingAreas:any=[]


    tenantDiningHallMenuFeature:any = false



    value:any="https://www.google.com.tr"
    primevue: any = usePrimeVue();
    //size:any=150


    //useCheckin=false


 
    
    
    //email= null
    // showModal= false
    // email= ''
    // msg= []
    created() {
        //this.$apiHeader = { headers: { Authorization: 'Bearer ' + this.$keycloak.token, "Accept-Language":this.$i18n.locale.toUpperCase() } }
        this.apiHeaderForFile = {
            headers: {
                Authorization: 'Bearer ' + this.$keycloak.token,
                'Content-Type': 'multipart/form-data'
            }
        }
        this.listWebUsers();
        this.initFilters();
    }

    formatCoordinates(coordinate:string) {
        if(coordinate != null && coordinate != ""){
            const formattedString = coordinate.toString().replace(/,/g, '.');

            const firstDotIndex = formattedString.indexOf('.');
            if (firstDotIndex !== -1) {
                const textBeforeDot = formattedString.substring(0, firstDotIndex + 1);
                const textAfterDot = formattedString.substring(firstDotIndex + 1).replace(/\./g, ''); // Tüm noktaları sil
                return textBeforeDot + textAfterDot;
            }

            return formattedString;
        }
        else{
            return coordinate
        }
    }
    @Watch('building.latitude')
    onPropertyChangedForBuildingLatitude(value: string, oldValue: string) {
        this.building.latitude = this.formatCoordinates(this.building.latitude)
    }

    @Watch('building.longitude')
    onPropertyChangedForBuildingLongitude(value: string, oldValue: string) {
        this.building.longitude = this.formatCoordinates(this.building.longitude)
    }

    updated(){
        this.$watch('building.longitude', this.onPropertyChangedForBuildingLongitude);
        this.$watch('building.longitude', this.onPropertyChangedForBuildingLongitude);
    }

    mounted() {
        this.loadRoles();
        if (this.systemPreferencesPageRole) {
            
            this.getTenantDiningHallMenuFeature();
            this.listBuildings();
            this.listFloors();
            this.listRooms();
            //this.listDiningHalls();
            //this.listDiningHallByBuildingId();
            // this.listCarParks();
            // this.listShuttles();
            this.listUsers();
            this.listTitles();
            this.listDepartments();
            this.listHolidays();
            this.getCalendars();
            this.listTenantParameters();
            
            this.listPolicyFile();
            this.getIntegrationType();
            this.loadBuildingForCheckIn();
        }
    }



    // qrOptions:any

    // qrUpdated(QrId:any, BuildingName:any){
    //     this.qrOptions = Object.assign({}, this.qrOptions, {
    //         data: QrId
    //       });
    // }
    // test(dataUrl:any,id:any){
    //     const xhr = new XMLHttpRequest();
    //     xhr.responseType = 'blob';
    //     xhr.onload = function () {
    //         const a = document.createElement('a');
    //         a.href = window.URL.createObjectURL(xhr.response);
    //         a.download = "xxx" + ' QR Code.png';
    //         a.style.display = 'none';
    //         document.body.appendChild(a);
    //         a.click();
    //         a.remove();
    //       };
    //       xhr.open('GET', dataUrl); // This is to download the canvas Image
    //       xhr.send();
    // }


   

    downloadCanvasAsImage(object:any){

        let fileName = "" 
        

        if(object.name != null){
            fileName = object.name + ' QR Code.png'

        }
        else{
            if(object.workingAreaName != "-"){
                fileName = object.buildingName + '/' + object.floorName + '/' + object.workingAreaName + '/' + object.deskNumber + '-' + object.chairNumber + ' QR Code.png'
            }
            else{
                fileName = object.buildingName + '/' + object.floorName + '/' + object.deskNumber + '-' + object.chairNumber + ' QR Code.png'
            }
        }

        
        const deskInfosForQrText = this.getDeskInfosForQrText(object)

        const canvas = document.getElementById(object.qrGuid) as HTMLCanvasElement 

        const paddedImage = this.editQrCodeImage(canvas, deskInfosForQrText) 

        const canvasImage = paddedImage.toDataURL('image/png');   

        // this can be used to download any image from webpage to local disk

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvasImage); // This is to download the canvas Image
          xhr.send();
    }

    downloadAllQrCodes(){
        this.appStore.progressSpinnerShow = true
        const zip = new JSZip();
        //const folder:any = zip.folder('Qr Codes');

        let fileName = "" 

        if(this.floorAndWorkingAreaDesksFilterDto.buildingId != null){
            const buildingName = this.buildings.find((x:any) => x.id === this.floorAndWorkingAreaDesksFilterDto.buildingId).name; 
            fileName += buildingName
        }
        if(this.floorAndWorkingAreaDesksFilterDto.floorId != null){
            const floorName = this.floorAndWorkingAreaDeskFilterFloors.find((x:any) => x.id === this.floorAndWorkingAreaDesksFilterDto.floorId).name; 
            fileName += '/' + floorName
        }
        if(this.floorAndWorkingAreaDesksFilterDto.workingAreaId != null){
            const workingAreaName = this.floorAndWorkingAreaDeskFilterWorkingAreas.find((x:any) => x.id === this.floorAndWorkingAreaDesksFilterDto.workingAreaId).name; 
            fileName += '/' + workingAreaName
        }
        if(fileName == ""){
            fileName += 'QR Codes'
        }
        else{
            fileName += ' QR Codes'

        }


        for(let i=0; i<this.floorAndWorkingAreaDesks.length; i++ ){
            if(this.floorAndWorkingAreaDesks[i].qrGuid != null){
                const desk = this.floorAndWorkingAreaDesks[i]
                let fileName = "" 

                if(desk.workingAreaName != "-"){
                    fileName = desk.buildingName + '/' + desk.floorName + '/' + desk.workingAreaName + '/' + desk.deskNumber + '-' + desk.chairNumber + ' QR Code.png'
                }
                else{
                    fileName =desk.buildingName + '/' + desk.floorName + '/' + desk.deskNumber + '-' + desk.chairNumber + ' QR Code.png'
                }
                const deskInfosForQrText = this.getDeskInfosForQrText(desk)
                const canvas = document.getElementById(desk.qrGuid) as HTMLCanvasElement 
                const paddedImage = this.editQrCodeImage(canvas, deskInfosForQrText) 
                const canvasImage = paddedImage.toDataURL('image/png');   
                zip.file(fileName, canvasImage.split('base64,')[1], { base64: true });
            }
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, fileName);
          });

        this.appStore.progressSpinnerShow = false

    }

    getDeskInfosForQrText(desk:any){
        let deskInfoText = "";

        if(desk.floorName != null){
            deskInfoText += desk.floorName
        }

        if(desk.workingAreaName != null && desk.workingAreaName != "-"){
            deskInfoText += " | " +desk.workingAreaName
        }

        if(deskInfoText != ""){
            deskInfoText += " | Desk - " + desk.deskNumber
            return deskInfoText
        }
        else{
            const building = desk
            const buildingInfoText = building.name
            return buildingInfoText

        }

        
    }

    editQrCodeImage(canvas:any, deskInfoText:any){

        const paddedImage = document.createElement("canvas"); 
        const amount = 200
        paddedImage.width = canvas.width + amount * 2; // set the size
        paddedImage.height = canvas.height + amount * 2;

        const ctx = paddedImage.getContext("2d") as CanvasRenderingContext2D;
        ctx.strokeStyle = "#0E1F79";  // set the colour;
        ctx.lineWidth = amount;
        // draw the border
        ctx.strokeRect(amount / 2 , amount / 2, canvas.width + amount, canvas.height + amount);
        // draw the image on top
        ctx.drawImage(canvas, amount, amount);

        const deskText = deskInfoText
        //const deskText = 'DESK - ' + desk;
        const scanText = 'SCAN TO CHECK-IN';

        const captionX = paddedImage.width / 2;
        const captionY = amount / 2 // Yükseklik düzenlemesi
        const captionYBottom = paddedImage.height - amount / 2; // Alt yazı

        ctx.font = 'bold 60px Noto Sans';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(deskText, captionX, captionY);
        ctx.fillText(scanText, captionX, captionYBottom);

        return paddedImage

    }



    initFilters(): any {
        this.filters = {
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    }

    loadRoles() {
        this.systemPreferencesPageRole = rolesJson['systemPreferences'];
    }

    searchForWebEmails(event: any) 
    {
        const searchTerm = event.query.toLowerCase(); 
        this.filteredWebUserList = [...this.webUserList].filter(x => {
            return x.email.toLowerCase().includes(searchTerm);
          });
    }  

    changeCheckInBuilding(){
        this.floorAndWorkingAreaDesksFilterDto.buildingId = this.selectedBuilding.id
        if(this.selectedBuilding.isActiveOnComingReservation == false){
            this.sendOnComingReservation.sendTime = "12:00"
        }
        this.floorAndWorkingAreaDeskFilterBuildingNameChange();
        this.listAccessControlSystemIntegration();
        this.listBuildings();
        this.listBuildingReservationNotificationParameters();
    }

    changeSelectedBuildingForServices(){
        this.getBuildingByBuildingId();
    }

    fillServicesPreferencesByBuilding(building:any){
        if(building != null){
            this.buildingServicesPreferences.isUsingDiningHall = building.isUsingDiningHall;
            this.buildingServicesPreferences.isUsingCarPark = building.isUsingCarPark;
            this.buildingServicesPreferences.isUsingShuttle = building.isUsingShuttle;
            this.buildingServicesPreferences.isUsingShuttleRoute = building.isUsingRoute;  
            this.buildingServicesPreferences.userAuthIsActive = building.userAuthIsActive;
        }
    }

    
    loadBuildingForCheckIn(){

        this.buildingApi.webapiBuildingListAsyncGet(this.$apiHeader)
        .then(x=> {
            this.buildingsForCheckIn = x.data;
            if(this.buildingsForCheckIn != null && this.buildingsForCheckIn.length != 0){
                this.selectedBuilding = this.buildingsForCheckIn[0]
                this.selectedBuilding.onComingReservationSendTime = this.selectedBuilding.onComingReservationSendTime.substring(0,5) == null ? "12:00" : this.selectedBuilding.onComingReservationSendTime.substring(0,5)
                this.sendOnComingReservation.sendTime = this.selectedBuilding.onComingReservationSendTime
                this.changeCheckInBuilding()
                // this.listBuildingReservationNotificationParameters();
            }
        }).catch(x=> {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingBuildings"), life: 3000 });
        })  
    }

    //

    inputNullCheck(input: any, inputSubmitted: any) {
        if (!inputSubmitted) {
            return false;
        }
        if (!input) {
            return true
        }
        if (input.length == 0) {
            return true;
        }
        return false;
    }


    roomAmenitiesInputCheck() {
        if (!this.roomSubmitted) {
            return false;
        }
        if (!this.room.amenities) {
            return true;
        }
        if (this.room.amenities.length == 0) {
            return true;
        }
        return false;
    }

    roomEditAmenitiesInputCheck() {
        if (!this.roomEditSubmitted) {
            return false;
        }
        if (!this.roomEdit.amenities) {
            return true;
        }
        if (this.roomEdit.amenities.length == 0) {
            return true;
        }
        return false;
    }

    loadCalendarModel(){
        this.calendar = this.calendarList[0];
        // this.calendar.reservationStartDate = this.calendar.reservationStartDate.substring(0,10)
        // this.calendar.reservationEndDate = this.calendar.reservationEndDate.substring(0,10)

        const reservationStartDate = this.calendar.reservationStartDate.substring(8,10)+"."+this.calendar.reservationStartDate.substring(5,7)+"."+this.calendar.reservationStartDate.substring(0,4)
        const reservationOpenHours = this.calendar.openHours.substring(0,5)
        const reservationClosedHours = this.calendar.closedHours.substring(0,5)

        this.calendar.openHours = reservationOpenHours
        this.calendar.closedHours = reservationClosedHours
        this.calendar.reservationStartDate = reservationStartDate

        if(this.calendar.reservationEndDate != null){
            const reservationEndDate = this.calendar.reservationEndDate.substring(8,10)+"."+this.calendar.reservationEndDate.substring(5,7)+"."+this.calendar.reservationEndDate.substring(0,4)
            this.calendar.reservationEndDate = reservationEndDate
        }


        if(this.calendar.workingDays.includes(1)){
            this.calendarWorkingDays.monday = true
        }

        if(this.calendar.workingDays.includes(2)){
            this.calendarWorkingDays.tuesday = true
        }
        
        if(this.calendar.workingDays.includes(3)){
            this.calendarWorkingDays.wednesday = true
        }

        if(this.calendar.workingDays.includes(4)){
            this.calendarWorkingDays.thursday = true
        }

        if(this.calendar.workingDays.includes(5)){
            this.calendarWorkingDays.friday = true
        }

        if(this.calendar.workingDays.includes(6)){
            this.calendarWorkingDays.saturday = true
        }

        if(this.calendar.workingDays.includes(7)){
            this.calendarWorkingDays.sunday = true
        }

        

    }


    /* List Functions */
    listWebUsers(){
        this.userApi.webapiUserGetByTenantIdGet(this.$apiHeader).then(x => {
            this.webUserList = x.data.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error on listing Car Park User Authorizations", life: 3000 });
        })
    }

    listCarParkUserAuthorizations() {
        const id = this.buildingServicesPreferences.buildingId;
        this.carParkUserAuthorizationApi.webapiCarParkUserAuthorizationGetListByBuildingIdGet(id,this.$apiHeader).then(x => {
            this.carParkUserAuthorizations = x.data.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingCarParkUserAuthorization"), life: 3000 });
            });         
    }

    listBuildings() {
        this.buildingApi.webapiBuildingListAsyncGet(this.$apiHeader).then(x => {
            this.buildings = x.data;
            if(this.buildings != null && this.buildings.length > 0){
                this.buildingServicesPreferences.buildingId = this.buildings[0].id
                this.fillServicesPreferencesByBuilding(this.buildings[0])
                this.listDiningHallByBuildingId()
                this.listCarParkByBuildingId()
                this.listShuttleByBuildingId()
                this.listCarParkUserAuthorizations();
            }
            if(this.buildings.onComingReservationSendTime == null){
                this.sendOnComingReservation.sendTime = "17:00"
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingBuildings"), life: 3000 });
        });
    }

    getBuildingByBuildingId(){
        this.buildingApi.webapiBuildingGetByIdAsyncGet(this.buildingServicesPreferences.buildingId, this.$apiHeader).then(x => {
            
            if(x.data != null){
                this.fillServicesPreferencesByBuilding(x.data)
                this.listDiningHallByBuildingId();
                this.listCarParkByBuildingId();
                this.listShuttleByBuildingId();
                this.listCarParkUserAuthorizations();
                this.listIpAddressesByBuildingId();
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingBuildings"), life: 3000 });
        });
    }

    listFloors() {
        this.floorApi.webapiFloorListAsyncGet(this.$apiHeader).then(x => {
            this.floors = x.data;
            this.setExpandColumns();
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingFloors"), life: 3000 });
        });
    }

    listUsers() {
        this.userApi.webapiUserGetByTenantIdGet(this.$apiHeader).then(x => this.users = x.data.data).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingUsers"), life: 3000 });
        });
    }
    listTitles() {
        this.ruleApi.webapiRuleGetTitlesByTenantIdAsyncGet(this.$apiHeader).then(x => { this.titles = x.data.data; }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingTitles"), life: 3000 });
        });
    }

    listDepartments() {
        this.ruleApi.webapiRuleGetDepartmentsByTenantIdAsyncGet(this.$apiHeader).then(x => { this.departments = x.data.data; }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingDepartments"), life: 3000 });
        });
    }



    listRooms() {
        this.roomApi.webapiRoomListAsyncGet(this.$apiHeader).then(x => { this.rooms = x.data.data;}).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingRooms"), life: 3000 })
        })

        this.listRoomServices()
    }

    listRoomServices() {
        this.roomServiceApi.webapiRoomServiceListGet(this.$apiHeader).then(x => { this.roomService = x.data.data;}).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingRoomServices"), life: 3000 })
        })
    }
	
	listDiningHalls(){
		this.diningHallApi.webapiDiningHallListAsyncGet(this.$apiHeader).then(x => {
            this.diningHalls = x.data;     
        }).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingDiningHalls"), life: 3000})
		})
	}


    listDiningHallByBuildingId(){
        this.diningHallApi.webapiDiningHallListByBuildingIdAsyncGet(this.buildingServicesPreferences.buildingId, this.$apiHeader).then(x => {
            this.diningHalls = x.data
            if(this.diningHalls.length > 0){
                this.listDiningHallMenu();
                this.diningHallMenuSectionIsVisible = true;
            }
            else{
                this.diningHallMenus = [];
                this.diningHallMenuSectionIsVisible = false;
            }
        }).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingDiningHalls"), life: 3000})
		})
    }

    listDiningHallMenu(){
        this.diningHallMenuApi.webapiDiningHallMenuGetDiningHallMenuByDiningHallIdGet(this.diningHalls[0].id ,this.$apiHeader).then(x => {
            this.diningHallMenus = x.data.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingDiningHallMenu"), life: 3000 });
        });
    }

    listCarParkByBuildingId(){
        this.carParkApi.webapiCarParkListByBuildingIdAsyncGet(this.buildingServicesPreferences.buildingId, this.$apiHeader).then(x => {
            this.carParks = x.data;
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingCarParks"), life: 3000})
        })
    }

    listIpAddressesByBuildingId(){
        this.buildingIpApi.webapiBuildingIpListAsyncGet(this.buildingServicesPreferences.buildingId, this.$apiHeader).then(x => {
            this.ipAddresses = x.data;
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIpAddresses"), life: 3000})
        })
    }

    listShuttleByBuildingId(){
        this.shuttleApi.webapiShuttleListByBuildingIdAsyncV2Get(this.buildingServicesPreferences.buildingId, this.$apiHeader).then(x => {
            this.shuttles = x.data;
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingShuttles"), life: 3000})
        })
    }

	listCarParks(){
		this.carParkApi.webapiCarParkListAsyncGet(this.$apiHeader).then(x => this.carParks = x.data).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingCarParks"), life: 3000})
		})
	}
	listShuttles(){
		this.shuttleApi.webapiShuttleListAsyncGet(this.$apiHeader).then(x => this.shuttles = x.data).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingShuttles"), life: 3000})
		})
	}
    listHolidays(){
		this.calenderHolidayApi.webapiCalenderHolidayListAsyncGet(this.$apiHeader).then(x => {
            this.holidays = x.data; 
        }).catch(() => {
			this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingHolidays"), life: 3000})
		})
	}


    listTenantParameters(){
        this.ruleApi.webapiRuleGetTenantParameterGet(this.$apiHeader).then(x => {
            this.tenantParameters = x.data.data;

            this.checkin.isUseCheckin = this.tenantParameters.isUseCheckin



            if(this.tenantParameters.checkinFlowType == 0 || this.tenantParameters.checkinFlowType == null || this.tenantParameters.checkinFlowType == 1 ){
                this.checkin.checkinFlowType = 1
            }
            else{
                this.checkin.checkinFlowType = this.tenantParameters.checkinFlowType
                if(this.tenantParameters.checkinFlowType == 3){
                    this.listFloorAndWorkingAreaDesks()
                }
            }


            if(this.tenantParameters.reservationFailIfNoCheckin == null){
                this.checkin.reservationFailIfNoCheckin = false
            }
            else{
                this.checkin.reservationFailIfNoCheckin = this.tenantParameters.reservationFailIfNoCheckin
            }


            if(this.tenantParameters.reservationFailsAfter == null){
                this.checkin.reservationFailsAfter = 1
            }
            else{
                this.checkin.reservationFailsAfter = this.tenantParameters.reservationFailsAfter
            }

            

            if(this.tenantParameters.carParkReportEmail != null){
                this.carParkReport.recieverEmailAddress = this.tenantParameters.carParkReportEmail
                this.carParkReport.sendTime = this.tenantParameters.carParkReportSendTime.toString().substring(0,5)
                this.carParkReport.isActive = this.tenantParameters.isActiveCarParkReport
            }

            if(this.tenantParameters.diningHallReportEmail != null){
                this.diningHallReport.recieverEmailAddress = this.tenantParameters.diningHallReportEmail
                this.diningHallReport.sendTime = this.tenantParameters.diningHallReportSendTime.toString().substring(0,5)
                this.diningHallReport.isActive = this.tenantParameters.isActiveDiningHallReport
            }


        }).catch(() =>{
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingTenantParameters"), life: 3000})

        })
    }

    listTenantReservationNotificationParameters() {
        this.pushNotificationApi.webapiPushNotificationGetTenantReservationNotificationParameterGet(this.$apiHeader).then(x => {
            this.tenantReservationNotificationParameters = x.data.data
            if (this.tenantReservationNotificationParameters.onComingReservationSendTime != null) {
                this.sendOnComingReservation.sendTime = this.tenantReservationNotificationParameters.onComingReservationSendTime.toString().substring(0,5)
            } 
            this.sendOnComingReservation.isActive = this.tenantReservationNotificationParameters.isActiveOnComingReservation
        })
    }

    getTenantDiningHallMenuFeature(){
        this.tenantApi.webapiTenantGetTenantDiningHallMenuFeatureGet(this.$apiHeader).then(x => {
            this.tenantDiningHallMenuFeature = x.data;


        })
    }
    
    listBuildingReservationNotificationParameters() 
    {    
        const id = this.selectedBuilding.id;
        this.pushNotificationApi.webapiPushNotificationGetBuildingReservationNotificationParameterPost(id,this.$apiHeader).then(x => {
            this.buildingReservationNotificationParameters = x.data.data;
            if (this.buildingReservationNotificationParameters.onComingReservationSendTime != null) {
                this.sendOnComingReservation.sendTime = this.buildingReservationNotificationParameters.onComingReservationSendTime.toString().substring(0,5);
            } 
            this.sendOnComingReservation.isActive = this.buildingReservationNotificationParameters.isActiveOnComingReservation == null ? false : this.buildingReservationNotificationParameters.isActiveOnComingReservation;
        })
    }

    listFloorAndWorkingAreaDesks(){
        this.floorDeskApi.webapiFloorDeskGetDeskListPost(this.floorAndWorkingAreaDesksFilterDto, this.$apiHeader).then(x => {
            this.floorAndWorkingAreaDesks = x.data.data
        })
    }

    listPolicyFile(){
        this.policyApi.webapiPolicyGetPolicyGet(this.$apiHeader).then(x => {
            this.policy = x.data.data;
            this.selectFileVisible = this.policy == null ? true : false;
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingPolicy"), life: 3000})
        })
    }



    getIntegrationType(){
        this.integrationApi.webapiIntegrationGetIntegrationTypeGet(this.$apiHeader).then(x => {
            this.integrationType.id = x.data.data;
            if(this.integrationType.id == 1){
                this.integrationApi.webapiIntegrationGetAzureParametersGet(this.$apiHeader).then(x => { this.azureAD = x.data.data;}).catch(() => {
                    this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIntegrationType"), life: 3000}) });
            }else if(this.integrationType.id == 2){
                this.integrationApi.webapiIntegrationGetGoogleWorkspaceParametersGet(this.$apiHeader).then(x => { this.googleWorkspace = x.data.data; }).catch(() => {
                    this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIntegrationType"), life: 3000}) });
            }
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIntegrationType"), life: 3000})
        });
    }

    changeIntegrationType(){
        if(this.integrationType.id == 1){
            this.integrationApi.webapiIntegrationGetAzureParametersGet(this.$apiHeader).then(x => { this.azureAD = x.data.data;}).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIntegrationType"), life: 3000}) });
        }else if(this.integrationType.id == 2){
            this.integrationApi.webapiIntegrationGetGoogleWorkspaceParametersGet(this.$apiHeader).then(x => { this.googleWorkspace = x.data.data; }).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingIntegrationType"), life: 3000}) });
        }
    }

    async saveIntegrationType(){
        this.integrationSubmitted=true;
        this.appStore.progressSpinnerShow = true
        if(this.integrationType.id == 1){
            if(this.azureAD.azureClientID != null && this.azureAD.azureClientSecret != null && this.azureAD.azureTenantID != null && 
                this.azureAD.azureClientID != "" && this.azureAD.azureClientSecret != "" && this.azureAD.azureTenantID != ""){
                await this.integrationApi.webapiIntegrationSaveAzureParametersPost(this.azureAD, this.$apiHeader).then(x => {
                    this.$toast.add({severity:'success', summary: this.$t("successful"), detail: this.$t("successfulOnSavingIntegrationType"), life: 3000})
                }).catch(() => {
                    this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnSavingIntegrationType"), life: 3000})
                    this.integrationSubmitted=false;
                });
            }else{
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnSavingIntegrationType"), life: 3000})
            }
        }else if(this.integrationType.id ==2){
            if(this.googleWorkspace.admin != null && this.googleWorkspace.projectId != null && this.googleWorkspace.privateKeyId != null && this.googleWorkspace.clientId != null && this.googleWorkspace.clientEmail != null && this.googleWorkspace.privateKey != null && 
                this.googleWorkspace.admin != "" && this.googleWorkspace.projectId != "" && this.googleWorkspace.privateKeyId != "" && this.googleWorkspace.clientId != "" && this.googleWorkspace.clientEmail != "" && this.googleWorkspace.privateKey != ""){
                    await this.integrationApi.webapiIntegrationSaveGoogleWorkSpaceParametersPost(this.googleWorkspace, this.$apiHeader).then(x => {
                        this.$toast.add({severity:'success', summary: this.$t("successful"), detail: this.$t("successfulOnSavingIntegrationType"), life: 3000})
                    }).catch(() => {
                        this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnSavingIntegrationType"), life: 3000})
                        this.integrationSubmitted=false;
                    });
            }else{
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnSavingIntegrationType"), life: 3000})
            }
        }else{
            await this.integrationApi.webapiIntegrationSaveIntegrationTypeNonePost(this.$apiHeader).then(x => {
                this.$toast.add({severity:'success', summary: this.$t("successful"), detail: this.$t("successfulOnSavingIntegrationType"), life: 3000})
            }).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnSavingIntegrationType"), life: 3000})
                this.integrationSubmitted=false;
            });
        }
        this.appStore.progressSpinnerShow = false

        
    }

    deleteIntegrationType(){
        this.integrationSubmitted=true;
        this.appStore.progressSpinnerShow = true

        if(this.integrationType.id == 1){
            this.integrationApi.webapiIntegrationRemoveAzureParametersPost(this.$apiHeader).then(x => {
                this.$toast.add({severity:'success', summary: this.$t("successful"), detail: this.$t("successfulOnDeletingIntegrationType"), life: 3000})
                this.integrationSubmitted=false;
                this.getIntegrationType();
            }).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingIntegrationType"), life: 3000})
                this.integrationSubmitted=false;
            });
        }else if(this.integrationType.id ==2){
            this.integrationApi.webapiIntegrationRemoveGoogleWorkSpaceParametersPost(this.$apiHeader).then(x => {
                this.$toast.add({severity:'success', summary: this.$t("successful"), detail: this.$t("successfulOnDeletingIntegrationType"), life: 3000})
                this.integrationSubmitted=false;
                this.getIntegrationType();
            }).catch(() => {
                this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingIntegrationType"), life: 3000})
                this.integrationSubmitted=false;
            });
        }else{
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingIntegrationType"), life: 3000})
            this.integrationSubmitted=false;
        }

        this.appStore.progressSpinnerShow = false


    }
    downloadIntegrationInfoPdf(){
        this.integrationApi.webapiIntegrationDownloadIntegrationInfoPdfPost(this.integrationType.id ,this.$apiHeader).then(x => {
            const link = document.createElement('a');
            link.href = x.data;
            link.setAttribute('download', 'file.png'); //or any other extension
            document.body.appendChild(link);
            window.open(x.data, '_blank');
        }).catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnDownloadingIntegrationInfoPdf"), life: 3000})
        });
    }
    /* List Functions (End) */

    cleanFilterForFloorAndWorkingAreaDesks(){
        this.floorAndWorkingAreaDesksFilterDto.buildingId = null
        this.floorAndWorkingAreaDesksFilterDto.floorId = null
        this.floorAndWorkingAreaDesksFilterDto.workingAreaId = null

        this.listFloorAndWorkingAreaDesks()

    }


    /* Get Functions */

    getCalendars(){
        this.calendarApi.webapiCalenderGetListByTenantIdGet(this.$apiHeader).then(x => {this.calendarList = x.data.data; this.loadCalendarModel()})
        .catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingCalendar"), life:3000})
        })
       
    }

    getTranslatedReservationFailsAfters() {
        const language = this.getCurrentLanguage(); 
        return language === 'en' ? this.reservationFailsAftersEn : this.reservationFailsAftersTr;
    }
    getTranslatedCheckOutWorkTime() 
    {
        const language = this.getCurrentLanguage();
        return language === 'en' ? this.checkOutWorkTimeEn : this.checkOutWorkTimeTr;
    }
    getTranslatedNotificationOften() {
        const language = this.getCurrentLanguage();
        return language === 'en' ? this.notificationOftenEn : this.notificationOftenTr;
    }
    getTranslatedNotificationOftenForCheckout(){
        const language = this.getCurrentLanguage();
        return language === 'en' ? this.notificationOftenForCheckoutEn : this.notificationOftenForCheckoutTr;
    }

    getCurrentLanguage() {
        this.selectedLanguage = this.$i18n.locale;
        return this.selectedLanguage;     
    }
    accessControlSystemIntegrationCheckControl()
    {
        if(this.accessControlSystemIntegration.username != null && 
            this.accessControlSystemIntegration.password != null
            && this.accessControlSystemIntegration.authUrl != null && this.accessControlSystemIntegration.url != null
            && this.accessControlSystemIntegration.buildingId != null && this.accessControlSystemIntegration.username != "" && 
            this.accessControlSystemIntegration.password != ""
            && this.accessControlSystemIntegration.authUrl != "" && this.accessControlSystemIntegration.url != "")
            {
                this.accessControlSystemIntegrationChecked = true;
            }
        else {
            this.accessControlSystemIntegrationChecked = false;
        }
     }

 
    listAccessControlSystemIntegration(){

        const buildingId = this.selectedBuilding.id
        this.accessControlSystemApi.webapiAccessControlSystemGetByBuildingIdAsyncGet(buildingId, this.$apiHeader)
        .then(x => {
            this.accessControlSystemIntegration = x.data.data;
            this.accessControlSystemIntegrationCheckControl();
            
        })
        .catch(() => {
            this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnListingAccessControlSystemIntegration"), life:3000})
        })
    }

    /* Get Functions (End) */




    /* Add Functions */
    async addBuilding() {
        this.buildingSubmitted = true;     
        this.appStore.progressSpinnerShow = true          
            if (this.building.name && this.building.address && this.building.latitude && this.building.longitude ) {
                await this.buildingApi.webapiBuildingAddPost(this.building, this.$apiHeader).then(x => {
                    if (x.data.responseCode === ResponseCode.NUMBER_200) {
                        this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                        this.listBuildings();
                        this.loadBuildingForCheckIn();
                       
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                    }
                    this.building = {};
                    this.buildingSubmitted = false;
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingBuilding"), life: 3000 });
                });
            }
        this.appStore.progressSpinnerShow = false          

    }
    sayac = 1 // For working area temp Id
    addWorkingArea(){
        if(this.sayac >= 11){
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("max10WorkingAreas"), life: 3000 });
        }
        else{
            this.workingAreaSubmitted = true;
            if(this.workingArea.name && this.workingArea.capacity){
                const cloneWorkingArea = this.workingArea
                this.cacheWorkingAreaList.push(JSON.parse(JSON.stringify(this.workingArea)))

                this.workingAreaSubmitted = false
                this.workingArea.name = null
                this.workingArea.capacity = null
                this.workingArea.imagePath = null
                this.workingArea.fileName = null
                this.workingArea.fileBase64 = null
                this.workingArea.file = null


                this.sayac = this.sayac + 1
                this.workingArea.tempId = this.workingArea.tempId + 1

            }
            
        }
    }

    async addLaterWorkingArea(){
        this.workingAreaLaterAddSubmitted = true;
        this.appStore.progressSpinnerShow = true
        if(this.workingAreaLaterAdd.name && this.workingAreaLaterAdd.capacity){
            await this.workingAreaApi.webapiWorkingAreaAddAsyncPost(this.workingAreaLaterAdd,this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listFloors();
                    this.listFloorAndWorkingAreaDesks();
                }
            this.workingAreaLaterAddSubmitted = false
            this.workingAreaLaterAdd = {}
            this.workingAreaLaterAddDialog = false


            })
        }
        this.appStore.progressSpinnerShow = false

    }

    async addFloor() {
        this.floorSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.floor.building && this.floor.name && (this.isDivideWorkingArea || this.floor.capacity)) {

            if((this.isDivideWorkingArea && this.cacheWorkingAreaList.length != 0) || this.floor.capacity){
                this.floorAndWorkingAreaDto.floor.buildingId = this.floor.building.id

                // sonradan eklendi duruma göre silinebilir
                const floorName = this.floor.name
                const buildingId =this.floor.building.id
                const floorPlanImageFileName = "testFileName"
                const floorBuildingId = this.floor.building.id
                const floorCapacity = this.floor.capacity
                const floorFloorPlanImageFileName = "dasada"
                const workingAreas = this.cacheWorkingAreaList

                // ----------------------------------------

                this.floorAndWorkingAreaDto.floor.name = this.floor.name
                this.floorAndWorkingAreaDto.floor.capacity = this.floor.capacity
                this.floorAndWorkingAreaDto.floor.floorPlanImageFileName = "testFileName"
                this.floorAndWorkingAreaDto.workingAreas = this.cacheWorkingAreaList
                this.floorAndWorkingAreaDto.fileName = this.floor.fileName
                this.floorAndWorkingAreaDto.fileBase64 = this.floor.fileBase64



                await this.floorApi.webapiFloorAddV2Post(this.floorAndWorkingAreaDto, this.$apiHeader).then(x => {
                    if (x.data.responseCode === ResponseCode.NUMBER_200) {
                        this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                        this.listFloors();
                        this.listFloorAndWorkingAreaDesks();
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                    }
                    this.floorAndWorkingAreaDto = {
                        floor:{}
                    }
                    this.cacheWorkingAreaList = []
                    this.floor = {}
                    this.floorSubmitted = false;
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingFloor"), life: 3000 });
                });
            }

        }
        this.appStore.progressSpinnerShow = false

    }


    async addRoom() {
        this.roomSubmitted = true;
        this.appStore.progressSpinnerShow = true


        // this.room.label && this.room.roomEmailAddress && this.room.amenities && this.room.amenities.length > 0
        // bu alanlar nullable yapıldığından buraya şart eklenmesine gerek kalmadı
        if (this.room.building && this.room.floor && this.room.roomName && this.room.capacity) {

            this.room.buildingId = this.room.building.id
            this.room.floorId = this.room.floor.id

            if(this.room.amenities != null){
                this.roomServices = [];
                this.room.amenities.forEach((x: any) => {
                    this.roomServices.push(x.service)
                });
            }
            

            this.room.services = this.roomServices;

            await this.roomApi.webapiRoomAddPost(this.room, this.$apiHeader).then(x => {
                if (x.data.responseCode == ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listRooms();
                    this.roomSubmitted = false;
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                    this.roomSubmitted = false;
                }
                this.room = {};
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingRoom"), life: 3000 });
                this.roomSubmitted = false;
            });
        }

        this.appStore.progressSpinnerShow = false

    }

    async addUser() {
        this.userSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.user.name && this.user.surname && this.user.title && this.user.department && this.user.email) {
            if(this.user.employeeId == ""){
                this.user.employeeId = null
            }
            await this.userApi.webapiUserAddPost(this.user, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listUsers();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.user = {};
                this.userSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingUser"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    validateEmail(email: any) {

        if (email) {
            this.emailValidateText = "Invalid email address format"

            //eslint-disable-next-line
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            if (regex.test(email)) {
                this.emailValidateText = "Invalid email address format"
                this.emailValidateBool = true
                return true
            }
        }
        else {
            this.emailValidateText = "Room Email Address is required"
            return false

        }

        return false;
    }

	
	async addDiningHall() {
		this.diningHallSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.diningHall.buildingId = this.buildingServicesPreferences.buildingId;
		if (this.diningHall.buildingId && this.diningHall.openHours && this.diningHall.closeHours && this.diningHall.capacity) {
			await this.diningHallApi.webapiDiningHallAddPost(this.diningHall, this.$apiHeader).then(x => {
				if (x.data.responseCode === ResponseCode.NUMBER_200) {
					this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
					//this.listDiningHalls();
                    this.listDiningHallByBuildingId()
				}
				else {
					this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life: 3000});
				}
				this.diningHall = {};
				this.diningHallSubmitted = false;
			}).catch(() => {
				this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnAddingDiningHall"), life: 3000});
			});
		}
        this.appStore.progressSpinnerShow = false

	}

    async addDiningHallMenu() {
        this.diningHallMenuSubmitted = true;
        this.appStore.progressSpinnerShow = true;
        this.diningHallMenuAdd.diningHallId = this.diningHalls[0].id;
		if (this.diningHallMenuAdd.validityStartDate !=null && this.diningHallMenuAdd.validityStartDate !=null) {
			await this.diningHallMenuApi.webapiDiningHallMenuAddPost(this.diningHallMenuAdd, this.$apiHeader).then(x => {
				if (x.data.responseCode === ResponseCode.NUMBER_200) {
					this.$toast.add({severity:'success', summary: this.$t("successful"), detail: x.data.message, life: 3000});
                    this.listDiningHallMenu();
                    this.removeUploadedMenuFile();
                    this.diningHallMenuStartAndEndDate = [];
				}
				else {
					this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life: 3000});
				}
				this.diningHallMenuSubmitted = false;
			}).catch(() => {
				this.$toast.add({severity:'error', summary: this.$t("error"), detail: this.$t("errorOnAddingDiningHallMenu"), life: 3000});
			});
		}
        this.appStore.progressSpinnerShow = false

	}


    async addCarPark() {
        this.carParkSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.carPark.buildingId = this.buildingServicesPreferences.buildingId;

        if (this.carPark.buildingId && this.carPark.capacity) {
            await this.carParkApi.webapiCarParkAddPost(this.carPark, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listCarParks();
                    this.listCarParkByBuildingId()

                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.carPark = {};
                this.carParkSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingCarPark"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async addIpAddress() {
        this.ipAddressSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.ipAddressObject.buildingId = this.buildingServicesPreferences.buildingId;

        if (this.ipAddressObject.buildingId && this.ipAddressObject.ipAddress) {
            await this.buildingIpApi.webapiBuildingIpAddPost(this.ipAddressObject, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listIpAddressesByBuildingId()
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.ipAddressObject = {};
                this.ipAddressSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingIpAddress"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async addShuttle() {
        this.shuttleSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.shuttle.buildingId = this.buildingServicesPreferences.buildingId
        if (this.shuttle.buildingId && this.shuttle.routeName) {
            await this.shuttleApi.webapiShuttleAddPost(this.shuttle, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listShuttles();
                    this.listShuttleByBuildingId()
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.shuttle = {};
                this.shuttleSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingShuttle"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async addDepartment() {
        this.departmentSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.department.name) {
            await this.ruleApi.webapiRuleAddDepartmentPost(this.department, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listDepartments();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.department = {};
                this.departmentSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingDepartment"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async addAmenity() {
        this.addAmanitiesSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.addAmenities.service){
            await this.roomServiceApi.webapiRoomServiceAddPost(this.addAmenities, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listRoomServices();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.addAmenities = {};
                this.addAmanitiesSubmitted = false;
            }).catch(x => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingAmenity"), life: 3000 });
            })
        }
        this.appStore.progressSpinnerShow = false

    }

    async addTitle() {
        this.titleSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.title.name) {
            await this.ruleApi.webapiRuleAddTitlePost(this.title, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listTitles();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.title = {};
                this.titleSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingTitle"), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async addHoliday(){
        this.holidaySubmitted = true
        this.appStore.progressSpinnerShow = true

        if(this.holiday.name && this.holiday.StartingDate && this.holiday.EndingDate){
            this.holiday.StartingDate = new Date(this.holiday.StartingDate)
            this.holiday.EndingDate = new Date(this.holiday.EndingDate)

            await this.calenderHolidayApi.webapiCalenderHolidayAddAsyncPost(this.holiday, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listHolidays();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
                this.title = {};
                this.titleSubmitted = false;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingHoliday"), life: 3000 });
            });
        }                          
        this.appStore.progressSpinnerShow = false

    }

    


    /* Add Functions (End) */

    /* Edit Functions */

    editBuilding(building: any) {
        // this.editInitMap();
        this.buildingEdit = { ...building };
        this.buildingEditDialog = true;
    }

    editFloor(floor: any) {
        this.floorEdit = { ...floor };
        if(this.floorEdit.workingAreas != null){
            this.floorEditCapacityInputVisible = false
        }
        else{
            this.floorEditCapacityInputVisible = true
        }
        this.floorEditDialog = true;
    }
    editWorkingArea(workingArea: any) {
        this.workingAreaEdit = { ...workingArea };
        this.workingAreaEditDialog = true;
    }
    openWorkingAreaLaterAddDialog(floorId:any){
        this.workingAreaLaterAddDialog = true
        this.workingAreaLaterAdd.floorId = floorId
    }

    editTempWorkingArea(tempWorkingArea: any) {
        this.tempWorkingAreaEdit = { ...tempWorkingArea };
        this.tempWorkingAreaEditDialog = true;
    }

    editUser(user: any) {
        this.userEdit = { ...user };
        this.userEditDialog = true;
    }

    addOrEditDepartments() {
        this.addOrUpdateDepartmentsDialog = true
    }

    addOrEditTitles() {
        this.addOrUpdateTitlesDialog = true
    }

    addOrEditaAmenities() {
        this.addOrUpdateAmenitiesDialog = true
    }

    addOrUpdateAccessControlSystemIntegration(){
        if(this.accessControlSystemIntegration == null){
            this.accessControlSystemIntegration.userName = ""
            this.accessControlSystemIntegration.password = ""
            this.accessControlSystemIntegration.authUrl = ""
            this.accessControlSystemIntegration.url = ""

        }
        this.accessControlSystemIntegrationDialog = true
    }

    async saveAddOrUpdateAccessControlSystemIntegration(){
        this.accessControlSystemIntegrationSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.accessControlSystemIntegration.buildingId = this.selectedBuilding.id;
        if(this.accessControlSystemIntegration.username != null && 
            this.accessControlSystemIntegration.password != null
            && this.accessControlSystemIntegration.authUrl != null && this.accessControlSystemIntegration.url != null
            && this.accessControlSystemIntegration.buildingId != null && this.accessControlSystemIntegration.username != "" && 
            this.accessControlSystemIntegration.password != ""
            && this.accessControlSystemIntegration.authUrl != "" && this.accessControlSystemIntegration.url != "")
            {
                await this.accessControlSystemApi.webapiAccessControlSystemAddOrUpdateAsyncPost(this.accessControlSystemIntegration,this.$apiHeader).then(x=> {
                    if(x.data.responseCode === ResponseCode.NUMBER_200){
                        this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: this.$t("successAddOrUpdateAccessControlSystemIntegration"), life: 3000 });
                        this.accessControlSystemIntegrationCheckControl();
                    }
                    else{
                        this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddOrUpdateAccessControlSystemIntegration"), life: 3000 });
                    }
                    // this.accessControlSystemIntegrationSubmitted = false;
                    this.hideAccessControlSystemIntegrationDialog();
                })
            }
            this.appStore.progressSpinnerShow = false

    }

    editRoom(room: any) {
        this.roomServices = []
        this.roomEdit.services = []
        this.roomEdit = { ...room };

        if(this.roomEdit.servicesText != null && this.roomEdit.servicesText != ''){
            this.roomEdit.services = this.roomEdit.servicesText.split(',')
        }

        this.roomEditBuildingNameChange()
        this.roomEditDialog = true;
        
    }

    editDiningHall(diningHall: any) {
        this.diningHallEdit = { ...diningHall };
        this.diningHallEdit.openHours = this.formatDate(diningHall.openHours);
        this.diningHallEdit.closeHours = this.formatDate(diningHall.closeHours);
        this.diningHallEditDialog = true;
    }

    editDiningHallMenu(diningHallMenu: any) {
        this.diningHallMenuEdit = { ...diningHallMenu };
        this.diningHallMenuEditStartAndEndDate[0] = new Date(this.diningHallMenuEdit.validityStartDate)
        this.diningHallMenuEditStartAndEndDate[1] = new Date(this.diningHallMenuEdit.validityEndDate)

        this.diningHallMenuEditDialog = true;
    }

    formatHourAndMinute(time : any){
        // Coming time parameter example --> 12:00:00
        // formated time --> 12:00

        return time.substring(0,5)
    }

    formatLongDate(date : any){
        // Coming date parameter example --> 2021-07-07T00:00:00
        // formated date --> 2021-07-07
        
        return date.substring(0,10).split('-').reverse().join('-')
    }   

    formatDate(hour: any) {
        const d = new Date();
        const [hours, minutes, seconds] = hour.split(':');
        d.setHours(hours); 
        d.setMinutes(minutes);
        d.setSeconds(seconds);
        return d;
    }
    
    dateSelect(){    
        if(this.diningHallMenuStartAndEndDate[0] != null && this.diningHallMenuStartAndEndDate[1] != null){
            this.diningHallMenuAdd.validityStartDate = new Date(this.diningHallMenuStartAndEndDate[0])
            this.diningHallMenuAdd.validityEndDate = new Date(this.diningHallMenuStartAndEndDate[1])
        }
    }

    dateSelectEdit(){    
        if(this.diningHallMenuEditStartAndEndDate[0] != null && this.diningHallMenuEditStartAndEndDate[1] != null){
            this.diningHallMenu.validityStartDate = new Date(this.diningHallMenuEditStartAndEndDate[0])
            this.diningHallMenu.validityEndDate = new Date(this.diningHallMenuEditStartAndEndDate[1])
        }
    }

    editCarPark(carPark: any) {
        this.carParkEdit = { ...carPark };
        this.carParkEditDialog = true;
    }

    editIpAddress(ipAddress: any) {
        this.ipAddressEdit = { ...ipAddress };
        this.ipAddressEditDialog = true;
    }


    editCarParkUserAuthorization(carPark: any) 
    {
        this.carParkUserAuthorizationEdit = { ...carPark };  
        this.carParkUserAuthorizationEditDialog = true;
    }

    editShuttle(shuttle: any) {
        this.shuttleEdit = { ...shuttle };
        this.shuttleEditDialog = true;
    }

    editTitle(title: any) {
        this.titleEdit = { ...title };
        this.titleEditDialog = true;
    }

    editDepartment(department: any) {
        this.departmentEdit = { ...department };
        this.departmentEditDialog = true;
    }

    editAmenities(amenity: any) {
        this.amenitiesEdit = { ...amenity }
        this.amenitiesEditDialog = true
    }
    

    editHoliday(holiday: any){
        this.holidayEdit = { ...holiday };
        this.holidayEdit.startingDate = this.formatDateWithTime(holiday.startingDate) as Date;
        this.holidayEdit.endingDate = this.formatDateWithTime(holiday.endingDate)as Date;
        this.holidayEditDialog = true;
    }

    formatDateWithTime(date: any) {
        const d = new Date();

        const [year, month, rest] = date.split('-');
        const [day, time] = rest.split('T');
        const [hours, minutes, seconds] = time.split(':');

        d.setFullYear(year, (month-1), day)  // Ayları index mantığıyla gösterdiği için -1 yapıldı --> Örneğin Kasım ayını 12 olarak gösteriyordu
        d.setHours(hours); 
        d.setMinutes(minutes);
        d.setSeconds(seconds);

        return d;
    }

    customDateString(date: Date){
        let string = new String()

        if(date.getDate().toString().length < 2){
            string += "0"+date.getDate().toString() + "."
        }
        else{
            string += date.getDate().toString() + "."
        }

        if((date.getMonth()+1).toString().length < 2){
            string += "0"+(date.getMonth()+1).toString() + "."
        }
        else{
            string += +(date.getMonth()+1).toString() + "."
        }

        string += date.getFullYear().toString() + " "

        if(date.getHours().toString().length < 2){
            string += "0"+date.getHours().toString() + ":"
        }
        else{
            string += +date.getHours().toString() + ":"
        }

        if(date.getMinutes().toString().length < 2){
            string += "0"+date.getMinutes().toString()
        }
        else{
            string += date.getMinutes().toString()
        }
        return string


        // if(date.getDate().toString().length < 2){
        //     if((date.getMonth()+1).toString().length < 2){
        //         const s = new String(
        //             "0"+date.getDate().toString() + "." +
        //             "0"+(date.getMonth()+1).toString() + "." +
        //             date.getFullYear().toString() + " " +
        //             date.getHours().toString() + ":" +
        //             date.getMinutes().toString()
        //         );
        //         return s;
        //     }
        //     else{
        //         const s = new String(
        //             "0"+date.getDate().toString() + "." +
        //             (date.getMonth()+1).toString() + "." +
        //             date.getFullYear().toString() + " " +
        //             date.getHours().toString() + ":" +
        //             date.getMinutes().toString()
        //         );
        //         return s;
        //     }

        // }
        // else{
        //     if((date.getMonth()+1).toString().length < 2){
        //         const s = new String(
        //             date.getDate().toString() + "." +
        //             "0"+(date.getMonth()+1).toString() + "." +
        //             date.getFullYear().toString() + " " +
        //             date.getHours().toString() + ":" +
        //             date.getMinutes().toString()
        //         );
        //         return s;

        //     }
        //     else{
        //         const s = new String(
        //             date.getDate().toString() + "." +
        //             (date.getMonth()+1).toString() + "." +
        //             date.getFullYear().toString() + " " +
        //             date.getHours().toString() + ":" +
        //             date.getMinutes().toString()
        //         );
        //         return s;

        //     }
        // }
        
    }

    /* Edit Functions (End) */

    /* Delete Functions */

    async deleteBuilding() {
        this.appStore.progressSpinnerShow = true

        await this.buildingApi.webapiBuildingDeleteAsyncPost(<number>this.buildingDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listBuildings();
                this.listFloors();
                this.listFloorAndWorkingAreaDesks();
                this.listRooms();
                this.loadBuildingForCheckIn();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteBuildingDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingBuilding"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteFloor() {
        this.appStore.progressSpinnerShow = true

        await this.floorApi.webapiFloorDeleteAsyncPost(<number>this.floorDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listFloors();
                this.listFloorAndWorkingAreaDesks();
                this.listRooms();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteFloorDialog = false
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingFloor"), life: 3000 });
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteWorkingArea() {
        this.appStore.progressSpinnerShow = true

        await this.workingAreaApi.webapiWorkingAreaDeleteAsyncPost(<number>this.workingAreaDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listFloors();
                this.listFloorAndWorkingAreaDesks();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteWorkingAreaDialog = false
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingWorkingArea"), life: 3000 });
        });

        this.appStore.progressSpinnerShow = false

    }

    deleteTempWorkingArea(): void {
        const tempWorkingAreaIndex = this.cacheWorkingAreaList.findIndex((x:any) => x.tempId == this.tempWorkingAreaDelete.tempId)
        this.cacheWorkingAreaList.splice(tempWorkingAreaIndex,1);
        this.sayac--

        this.deleteTempWorkingAreaDialog = false

    }

    async deleteUser() {
        this.appStore.progressSpinnerShow = true

        await this.userApi.webapiUserDeleteDelete(<number>this.userDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listUsers();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteUserDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingUser"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteRoom() {
        this.appStore.progressSpinnerShow = true

        await this.roomApi.webapiRoomDeletePost(<number>this.roomDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listRooms();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteRoomDialog = false
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingRoom"), life: 3000 });
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteDiningHall() {
        this.appStore.progressSpinnerShow = true

        await this.diningHallApi.webapiDiningHallDeleteDelete(<number>this.diningHallDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                //this.listDiningHalls();
                this.listDiningHallByBuildingId();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deletediningHallDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingDiningHall"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteDiningHallMenu() {
        this.appStore.progressSpinnerShow = true
        await this.diningHallMenuApi.webapiDiningHallMenuDeleteDelete(<number>this.diningHallMenuDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                //this.listDiningHalls();
                this.listDiningHallMenu();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                this.diningHallMenuDeleteDialog = false;
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 })
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingDiningHallMenu"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteCarParkUserAuthorization(data:any)
    {
        this.carParkUserAuthorization.userId = {userId: data.userId}
        this.appStore.progressSpinnerShow = true

        await this.carParkUserAuthorizationApi.webapiCarParkUserAuthorizationDeleteDelete(this.carParkUserAuthorization.userId.userId, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listCarParkUserAuthorizations();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else 
            {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingCarParkUserAuthorization"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteCarPark() {
        this.appStore.progressSpinnerShow = true

        await this.carParkApi.webapiCarParkDeleteDelete(<number>this.carParkDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                //this.listCarParks();
                this.listCarParkByBuildingId()
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deletecarParkDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingCarPark"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false
    }

    async deleteIpAddress() {
        this.appStore.progressSpinnerShow = true

        await this.buildingIpApi.webapiBuildingIpDeleteAsyncPost(<number>this.ipAddressDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listIpAddressesByBuildingId();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteIpAddressDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingIpAddress"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteShuttle() {
        this.appStore.progressSpinnerShow = true

        await this.shuttleApi.webapiShuttleDeletePost(<number>this.shuttleDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                //this.listShuttles();
                this.listShuttleByBuildingId()
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteshuttleDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingShuttle"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteDepartment() {
        this.appStore.progressSpinnerShow = true

        await this.ruleApi.webapiRuleDeleteDepartmentDelete(<number>this.departmentDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listDepartments();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.departmentDeleteDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingDepartment"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteAmenities() {
        this.appStore.progressSpinnerShow = true

        await this.roomServiceApi.webapiRoomServiceDeletePost(<number>this.amenitiesDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listRoomServices();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.amenitiesDeleteDialog = false;
        }).catch(x => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingAmenity"), life: 3000 })
        })
        this.appStore.progressSpinnerShow = false

    }

    async deleteTitle() {
        this.appStore.progressSpinnerShow = true

        await this.ruleApi.webapiRuleDeleteTitleDelete(<number>this.titleDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listTitles();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteTitleDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingTitle"), life: 3000 })
        });
        
        this.appStore.progressSpinnerShow = false

    }

    async deleteHoliday(){
        this.appStore.progressSpinnerShow = true

        await this.calenderHolidayApi.webapiCalenderHolidayDeleteAsyncPost(<number>this.holidayDelete.id, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.listHolidays();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deleteholidayDialog = false;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnDeletingHoliday"), life: 3000 })
        });
        this.appStore.progressSpinnerShow = false

    }

    async deleteUploadedPolicyFile() {
        this.appStore.progressSpinnerShow = true

        await this.policyApi.webapiPolicyDeletePolicyPost(this.$apiHeader)
        .then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                this.removeUploadedPolicyFile();
                this.removeUploadedPolicyFileAfterDeleting();
            }else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
            this.deletePolicyDialog = false;
        }
        ).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingUserList"), life: 3000 });
        }
        );

        this.appStore.progressSpinnerShow = false

    }

    /* Delete Functions (End) */

    /* Hide Functions */

    hideCarParkUserAuthorizationDialog(){
        this.carParkUserAuthorizationEditDialog = false;
        this.carParkUserAuthorizationSubmitted = false;
    }

    hideBuildingDialog() {
        this.buildingEditDialog = false;
        this.buildingSubmitted = false;
    }

    hideFloorDialog() {
        this.floorEditDialog = false;
        this.floorSubmitted = false;
    }

    hideWorkingAreaDialog() {
        this.workingAreaEditDialog = false;
        this.workingAreaEditSubmitted = false;
    }

    hideWorkingAreaLaterAddDialog() {
        this.workingAreaLaterAddDialog = false;
        this.workingAreaLaterAddSubmitted = false;
    }

    hideTempWorkingAreaDialog() {
        this.tempWorkingAreaEditDialog = false;
        this.tempWorkingAreaEditSubmitted = false;
    }

    hideUserDialog() {
        this.userEditDialog = false;
        this.userSubmitted = false;
    }

    hideRoomDialog() {
        this.roomEditDialog = false;
        this.roomEditSubmitted = false;
    }

    hideDiningHallDialog() {
        this.diningHallEditDialog = false;
        this.diningHallSubmitted = false;
    }
    hideDiningHallMenuDialog() {
        this.diningHallMenuEditDialog = false;
        this.diningHallMenuSubmitted = false;
    }
    hideCarParkDialog() {
        this.carParkEditDialog = false;
        this.carParkSubmitted = false;
    }
    hideIpAddressDialog() {
        this.ipAddressEditDialog = false;
        this.ipAddressSubmitted = false;
    }
    hideShuttleDialog() {
        this.shuttleEditDialog = false;
        this.shuttleSubmitted = false;
    }

    hideTitleDialog() {
        this.titleEditDialog = false;
        this.titleSubmitted = false;
    }

    hideDepartmentDialog() {
        this.departmentEditDialog = false;
        this.departmentSubmitted = false;
    }

    hideAmenitiesDialog() {
        this.amenitiesEditDialog = false
        this.amenitiesEditSubmitted = false
    }

    hideHolidayDialog() {
        this.holidayEditDialog = false;
        this.holidaySubmitted = false;
    }

    hideAccessControlSystemIntegrationDialog(){
        this.accessControlSystemIntegrationDialog = false
        this.accessControlSystemIntegrationSubmitted = false;
        this.listAccessControlSystemIntegration();

    }

    /* Hide Functions (End) */

    /* Save Functions */


    async saveCarParkUserAuthorization(){
        this.carParkUserAuthorizationSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.carParkUserAuthorization.carParkId = this.carParkUserAuthorizationEdit.id;
        this.carParkUserAuthorization.userId = this.carParkUserAuthorization.email;
        this.carParkUserAuthorization.buildingId = this.buildingServicesPreferences.buildingId;

        if (this.carParkUserAuthorization.userId != null &&this.carParkUserAuthorization.carParkId &&this.carParkUserAuthorization.buildingId) {
            this.carParkUserAuthorization.userId = this.carParkUserAuthorization.email.id;
            await this.carParkUserAuthorizationApi.webapiCarParkUserAuthorizationAddPost(this.carParkUserAuthorization, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.listCarParkUserAuthorizations();
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });                
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnAddingCarParkUserAuthorization'), life: 3000 });
            });
            // this.hideCarParkUserAuthorizationDialog();
            this.carParkUserAuthorization.email = null;
            this.carParkUserAuthorization = {};
            this.carParkUserAuthorizationSubmitted = false;
        }
        this.appStore.progressSpinnerShow = false

    }
    async saveBuilding() {
        this.buildingEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

            if (this.buildingEdit.name && this.buildingEdit.address && this.buildingEdit.latitude && this.buildingEdit.longitude ) {
                await this.buildingApi.webapiBuildingUpdatePost(this.buildingEdit, this.$apiHeader).then(x => {
                    if (x.data.responseCode === ResponseCode.NUMBER_200) {
                        this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                        this.listBuildings();
                        this.loadBuildingForCheckIn();
                    }
                    else {
                        this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingBuilding'), life: 3000 });
                });
                this.buildingEdit = {};
                this.buildingEditSubmitted = false;
                this.buildingEditDialog = false;
            }
        this.appStore.progressSpinnerShow = false

                      
        
    }

    async saveFloor() {
        this.floorEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.floorEdit.building && this.floorEdit.name) {
            await this.floorApi.webapiFloorUpdatePut(this.floorEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listFloors();
                    this.listFloorAndWorkingAreaDesks();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingFloor'), life: 3000 });
            });
            this.floorEdit = {};
            this.floorEditSubmitted = false;
            this.floorEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveWorkingArea() {
        this.workingAreaEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.workingAreaEdit.name && this.workingAreaEdit.capacity) {
            await this.workingAreaApi.webapiWorkingAreaUpdateAsyncPost(this.workingAreaEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listFloors();
                    this.listFloorAndWorkingAreaDesks();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingWorkingArea'), life: 3000 });
            });
            this.workingAreaEdit = {};
            this.workingAreaEditSubmitted = false;
            this.workingAreaEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    saveTempWorkingArea() {
        this.tempWorkingAreaEditSubmitted = true;
        if (this.tempWorkingAreaEdit.name && this.tempWorkingAreaEdit.capacity) {

            const tempWorkingAreaIndex = this.cacheWorkingAreaList.findIndex((x:any) => x.tempId == this.tempWorkingAreaEdit.tempId)

            this.cacheWorkingAreaList[tempWorkingAreaIndex] = this.tempWorkingAreaEdit
            
            this.tempWorkingAreaEdit = {};
            this.tempWorkingAreaEditSubmitted = false;
            this.tempWorkingAreaEditDialog = false;
        }
    }

    async saveRoom() {
        this.roomEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.room.imagePath = "testImagePath"


        // && this.roomEdit.services && this.roomEdit.services.length > 0 && this.roomEdit.label && this.roomEdit.roomEmailAddress 
        // bu alanlar nullable yapıldığından buraya şart eklenmesine gerek kalmadı
        if (this.roomEdit.floor.building.id && this.roomEdit.floor.id && this.roomEdit.roomName && this.roomEdit.capacity) {
            this.roomEdit.buildingId = this.roomEdit.floor.building.id
            this.roomEdit.floorId = this.roomEdit.floor.id

            // this.roomEdit.amenities.forEach((x: any) => {
            //  this.roomServices.push(x.service)              
            // });
            // this.roomEdit.services = this.roomServices


            await this.roomApi.webapiRoomUpdatePut(this.roomEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listRooms();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingRoom'), life: 3000 });
            });
            this.roomEdit = {};
            this.roomEditSubmitted = false;
            this.roomEditDialog = false;

        }
        this.appStore.progressSpinnerShow = false

    }

    async saveUser() {
        this.userEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        this.userEdit.department = this.userEdit.department.id;
        this.userEdit.title = this.userEdit.title.id;
        if (this.userEdit.name && this.userEdit.surname && this.userEdit.title && this.userEdit.department && this.userEdit.email) {
            if(this.userEdit.employeeId == ""){
                this.userEdit.employeeId = null
            }
            await this.userApi.webapiUserUpdatePost(this.userEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listUsers();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingUser'), life: 3000 });
            });
            this.userEdit = {};
            this.userEditSubmitted = false;
            this.userEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async savebuildingServicesPreferences() {
        this.appStore.progressSpinnerShow = true

        if (this.buildingServicesPreferences.isUsingShuttle == false){
            this.buildingServicesPreferences.isUsingShuttleRoute = false
        }
        if(this.buildingServicesPreferences.isUsingCarPark == false){
            this.buildingServicesPreferences.userAuthIsActive = false
        }
        await this.ruleApi.webapiRuleUpdateBuildingServicesPreferencesPost(this.buildingServicesPreferences, this.$apiHeader).then(x => {
            if (x.data.responseCode === ResponseCode.NUMBER_200) {
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingDiningHall'), life: 3000 });
        })
        this.appStore.progressSpinnerShow = false

    }

    async saveDiningHall() {
        this.diningHallEditSubmitted = true;
        this.appStore.progressSpinnerShow = true
        //this.diningHallEdit.buildingId = this.diningHallEdit.building.id
        this.diningHallEdit.buildingId = this.buildingServicesPreferences.buildingId

        if (this.diningHallEdit.buildingId && this.diningHallEdit.openHours && this.diningHallEdit.closeHours && this.diningHallEdit.capacity) {
            this.diningHallEdit.openHours = this.diningHallEdit.openHours as Date;
            this.diningHallEdit.closeHours = this.diningHallEdit.closeHours as Date;
            await this.diningHallApi.webapiDiningHallUpdatePut(this.diningHallEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listDiningHalls();
                    this.listDiningHallByBuildingId();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingDiningHall'), life: 3000 });
            });
            this.diningHallEdit = {};
            this.diningHallEditSubmitted = false;
            this.diningHallEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false
    }

    async saveDiningHallMenu() {
        this.diningHallMenuEditSubmitted = true;
        if (this.diningHallMenuEdit.diningHallId && this.diningHallMenuEditStartAndEndDate) {
            this.diningHallMenuEdit.validityStartDate = this.diningHallMenuEditStartAndEndDate[0] as Date;
            this.diningHallMenuEdit.validityEndDate = this.diningHallMenuEditStartAndEndDate[1] as Date;
            await this.diningHallMenuApi.webapiDiningHallMenuUpdatePut(this.diningHallMenuEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listDiningHalls();
                    this.listDiningHallMenu();
                    this.diningHallEditDialog = false;
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingDiningHallMenu'), life: 3000 });
            });
            this.diningHallEdit = {};
            this.diningHallMenuEditSubmitted = false;
            this.diningHallEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveCarPark() {
        this.carParkEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        //this.carParkEdit.buildingId = this.carParkEdit.building.id
        this.carParkEdit.buildingId = this.buildingServicesPreferences.buildingId

        if (this.carParkEdit.buildingId && this.carParkEdit.capacity) {
            await this.carParkApi.webapiCarParkUpdatePut(this.carParkEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listCarParks();
                    this.listCarParkByBuildingId()

                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingCarPark'), life: 3000 });
            })
            this.carParkEdit = {};
            this.carParkEditSubmitted = false;
            this.carParkEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }


    async saveIpAddress() {
        this.ipAddressSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.ipAddressEdit.ipAddress && this.ipAddressEdit.buildingId) {
            await this.buildingIpApi.webapiBuildingIpUpdatePost(this.ipAddressEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listIpAddressesByBuildingId();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingIpAddress'), life: 3000 });
            });
            this.ipAddressEdit = {};
            this.ipAddressSubmitted = false;
            this.ipAddressEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveShuttle() {
        this.shuttleEditSubmitted = true;
        this.appStore.progressSpinnerShow = true


        //this.shuttleEdit.buildingId = this.shuttleEdit.building.id
        this.shuttleEdit.buildingId = this.buildingServicesPreferences.buildingId
        if (this.shuttleEdit.buildingId && this.shuttleEdit.routeName) {
            await this.shuttleApi.webapiShuttleUpdatePut(this.shuttleEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    //this.listShuttles();
                    this.listShuttleByBuildingId()

                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingShuttle'), life: 3000 });
            })
            this.shuttleEdit = {};
            this.shuttleEditSubmitted = false;
            this.shuttleEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveCalendar(){
        this.calendarSubmitted = true
        this.appStore.progressSpinnerShow = true


        // 01.07.2022 day-month-year converted --> 2022.07.01  year-month-day

        if(this.calendar.reservationStartDate.length <= 10 ){
            const convertibleReservationStartDate = this.calendar.reservationStartDate.substring(6) + "." + this.calendar.reservationStartDate.substring(3,5) + "." + this.calendar.reservationStartDate.substring(0,2)
            this.calendar.reservationStartDate = new Date(convertibleReservationStartDate)
        }
        // else{
        //     this.calendar.reservationStartDate = new Date(this.calendar.reservationStartDate)
        // }

        if(this.calendar.hasNoEndingDate == true){
            this.calendar.reservationEndDate = null
        }
        else{
            if(this.calendar.reservationEndDate != null){
                if(this.calendar.reservationEndDate.length <= 10){
                    const convertibleReservationEndDate = this.calendar.reservationEndDate.substring(6) + "." + this.calendar.reservationEndDate.substring(3,5) + "." + this.calendar.reservationEndDate.substring(0,2)
                    this.calendar.reservationEndDate = new Date(convertibleReservationEndDate)
                }
                // else{
                //     this.calendar.reservationEndDate = new Date(this.calendar.reservationEndDate)
                // }
            }
        }


        this.calendar.openHours = this.calendar.openHours.toString()
        this.calendar.closedHours = this.calendar.closedHours.toString()

        if(this.calendar.openHours.length > 5){
            this.calendar.openHours = this.calendar.openHours.toString().substring(16,21)
        }
        if(this.calendar.closedHours.length > 5){
            this.calendar.closedHours = this.calendar.closedHours.toString().substring(16,21)
        }

        if(this.calendar.hasNoEndingDate == false){
            if(this.calendar.reservationStartDate && this.calendar.reservationEndDate && this.calendar.openHours && this.calendar.closedHours){
                await this.calendarApi.webapiCalenderAddOrUpdatePost(this.calendar, this.$apiHeader).then(x => {
                    if(x.data.responseCode == ResponseCode.NUMBER_200){
                        this.calendarSubmitted = false
                        this.getCalendars();
                        this.$toast.add({severity:'success', summary:this.$t("successful"), detail: x.data.message, life:3000})
                    }
                    else{
                        this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life:3000})
                    }
                }).catch(() => {
                    this.$toast.add({severity:'error', summary:this.$t('error'), detail: this.$t('errorOnUpdatingCalendar'), life:3000})
                })
                await this.tenantParameterApi.webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(this.tenantParameters,this.$apiHeader).then(x=>{
                    if(x.data.responseCode == ResponseCode.NUMBER_200){
                        this.listTenantParameters();
                    }
                    else{
                        this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life:3000}) 
                    }
                    
                })
                
            }
        }
        else{
            if(this.calendar.reservationStartDate && this.calendar.openHours && this.calendar.closedHours){
                await this.calendarApi.webapiCalenderAddOrUpdatePost(this.calendar, this.$apiHeader).then(x => {
                    if(x.data.responseCode == ResponseCode.NUMBER_200){
                        this.calendarSubmitted = false
                        this.getCalendars();
                        this.$toast.add({severity:'success', summary:this.$t("successful"), detail: x.data.message, life:3000})
                    }
                    else{
                        this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life:3000})
                    }
                }).catch(() => {
                    this.$toast.add({severity:'error', summary:this.$t('error'), detail: this.$t('errorOnUpdatingCalendar'), life:3000})
                })
                await this.tenantParameterApi.webapiTenantParameterUpdateForMultipleDeskReservationOptionsPost(this.tenantParameters,this.$apiHeader).then(x=>{
                    if(x.data.responseCode == ResponseCode.NUMBER_200){
                        this.listTenantParameters();
                    }
                    else{
                        this.$toast.add({severity:'error', summary: this.$t("error"), detail: x.data.message, life:3000}) 
                    }
                    
                })
            }
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveTitle() {
        this.titleEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.titleEdit.name) {
            await this.ruleApi.webapiRuleUpdateTitlePost(this.titleEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listTitles();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingTitle'), life: 3000 });
            });
            this.titleEdit = {};
            this.titleEditSubmitted = false;
            this.titleEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveDepartment() {
        this.departmentEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.departmentEdit.name) {
            await this.ruleApi.webapiRuleUpdateDepartmentPost(this.departmentEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listDepartments();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingDepartment'), life: 3000 });
            });
            this.departmentEdit = {};
            this.departmentEditSubmitted = false;
            this.departmentEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveAmenities() {
        this.amenitiesEditSubmitted = true
        this.appStore.progressSpinnerShow = true

        if (this.amenitiesEdit.service) {
            await this.roomServiceApi.webapiRoomServiceUpdatePut(this.amenitiesEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listRoomServices();
                    this.amenitiesEdit = {};
                    this.amenitiesEditSubmitted = false
                    this.amenitiesEditDialog = false
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingAmenity'), life: 3000 });
            });
        }
        this.appStore.progressSpinnerShow = false

    }

    async saveHoliday(){
        this.holidayEditSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if (this.holidayEdit.name && this.holidayEdit.startingDate && this.holidayEdit.endingDate) {
            this.holidayEdit.startingDate = new Date(this.holidayEdit.startingDate)
            this.holidayEdit.endingDate = new Date(this.holidayEdit.endingDate)
            await this.calenderHolidayApi.webapiCalenderHolidayUpdateAsyncPost(this.holidayEdit, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listHolidays();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('errorOnUpdatingHoliday'), life: 3000 });
            });
            this.holidayEdit = {};
            this.holidayEditSubmitted = false;
            this.holidayEditDialog = false;
        }
        this.appStore.progressSpinnerShow = false

    }


    async saveCarParkReportRule(){
        this.carParkReportSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if(this.carParkReport.recieverEmailAddress && this.carParkReport.sendTime){
            this.carParkReport.sendTime = this.carParkReport.sendTime.toString()
            if(this.carParkReport.sendTime.length > 5){
                this.carParkReport.sendTime = this.carParkReport.sendTime.toString().substring(16,21)
            }
            await this.ruleApi.webapiRuleAddOrUpdateCarParkReportOptionsPost(this.carParkReport, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.listTenantParameters();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }

            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingCarParkReportRule"), life: 3000 });
            });
            this.carParkReportSubmitted = false;
        }                       
        this.appStore.progressSpinnerShow = false
                   
    }

    async saveDiningHallReportRule(){
        this.diningHallReportSubmitted = true;
        this.appStore.progressSpinnerShow = true

        if(this.diningHallReport.recieverEmailAddress && this.diningHallReport.sendTime){
            this.diningHallReport.sendTime = this.diningHallReport.sendTime.toString()
            if(this.diningHallReport.sendTime.length > 5){
                this.diningHallReport.sendTime = this.diningHallReport.sendTime.toString().substring(16,21)
            }

            await this.ruleApi.webapiRuleAddOrUpdateDiningHallReportOptionsPost(this.diningHallReport, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200 ) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }

            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingCarParkReportRule"), life: 3000 });
            });
            this.diningHallReportSubmitted = false;
        }  
        this.appStore.progressSpinnerShow = false

    }

    async saveSendOnComingReservation() {
        this.sendOnComingReservationSubmitted = true;
        this.appStore.progressSpinnerShow = true
        this.sendOnComingReservation.buildingId = this.selectedBuilding.id;
        if(this.sendOnComingReservation.sendTime){
            this.sendOnComingReservation.sendTime = this.sendOnComingReservation.sendTime.toString()
            if(this.sendOnComingReservation.sendTime.length > 5){
                this.sendOnComingReservation.sendTime = this.sendOnComingReservation.sendTime.toString().substring(16,21)
            }
            await this.pushNotificationApi.webapiPushNotificationAddOrUpdateOnComingReservationOptionsPost(this.sendOnComingReservation, this.$apiHeader).then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    // this.listTenantReservationNotificationParameters();
                    // this.listBuildingReservationNotificationParameters();
                    this.listBuildings();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingSendOnComingReservation"), life: 3000 });
            });
            this.sendOnComingReservationSubmitted = false;
        }    
        this.saveBuildingForCheckin();
        this.appStore.progressSpinnerShow = false
    }

    checkRadio(checkinFlowType:any){
        if(checkinFlowType == 1){
            if(this.checkin.checkinFlowType == 1){
                return true;
            }
            return false;
        }
        if(checkinFlowType == 3){
            if(this.checkin.checkinFlowType == 3){
                return true;
            }
            return false;
        }

    }



    async saveBuildingForCheckin(){
        this.appStore.progressSpinnerShow = true
        if(this.selectedBuilding.numberOfNotification == 0){
            this.selectedBuilding.numberOfNotification = 1
        }
        if(this.selectedBuilding.notificationFrequency == 0){
            this.selectedBuilding.notificationFrequency = 15
        }
        if(this.selectedBuilding.numberOfNotificationCheckout == 0){
            this.selectedBuilding.numberOfNotificationCheckout = 1
        }
        if(this.selectedBuilding.checkoutNotificationFrequency == 0){
            this.selectedBuilding.checkoutNotificationFrequency = 15
        }
        this.selectedBuilding.onComingReservationSendTime = this.sendOnComingReservation.sendTime.toString().substring(0,5);
        this.selectedBuilding.isActiveOnComingReservation = this.sendOnComingReservation.isActive;
        await this.buildingApi.webapiBuildingUpdatePost(this.selectedBuilding, this.$apiHeader).then(x => {
            if(x.data.responseCode == ResponseCode.NUMBER_200){
                this.listBuildings();
                // this.loadBuildingForCheckIn();
                this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
            }
            else{
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
            }
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: "Error updating check-in rule", life: 3000 });
        });
        this.appStore.progressSpinnerShow = false

    }


    


    /* Save Functions (End) */



    /* Dialog Functions (Start) */

    clickFloorImage(){
        this.floorImagePopup = !this.floorImagePopup
    }

    clickWorkingAreaImage(){
        this.workingAreaImagePopup = !this.workingAreaImagePopup
    }
    clickMeetingRoomImage(){
        this.meetingRoomImagePopup = !this.meetingRoomImagePopup
    }



    /* Dialog Functions (End) */




    /* Confirm Functions */

    confirmDeleteBuilding(data: any): void {
        this.buildingDelete = {
            id: data.id,
            name: data.name
        }
        this.deleteBuildingDialog = true;
    }

    confirmDeleteFloor(data: any) {
        this.floorDelete = {
            id: data.id,
            name: data.name,
            buildingName: data.building.name
        }
        this.deleteFloorDialog = true
    }

    confirmDeleteWorkingArea(data: any) {
        this.workingAreaDelete = {
            id: data.id,
            name: data.name,
            capacity: data.capacity
        }
        this.deleteWorkingAreaDialog = true
    }

    confirmDeleteTempWorkingArea(data: any) {
        this.tempWorkingAreaDelete = {
            tempId: data.tempId,
            name: data.name,
            capacity: data.capacity
        }
        this.deleteTempWorkingAreaDialog = true
    }


    confirmDeleteUser(data: any): void {
        this.userDelete = {
            id: data.id,
            name: data.name
        }
        this.deleteUserDialog = true;
    }

    confirmDeleteRoom(data: any) {
        this.roomDelete = {
            id: data.id,
            name: data.roomName,
            buildingName: data.floor.building.name,
            floorName: data.floor.name
        }
        this.deleteRoomDialog = true
    }

    confirmDeleteDiningHall(data: any): void {
        this.diningHallDelete = {
            id: data.id,
            //name: data.building.name
        }
        this.deletediningHallDialog = true;
    }

    confirmDeleteDiningHallMenu(data: any): void {
        this.diningHallMenuDelete = {
            id: data.id,
            //name: data.building.name
        }
        this.diningHallMenuDeleteDialog = true;
    }

    confirmDeleteCarPark(data: any): void {
        this.carParkDelete = {
            id: data.id,
            //name: data.building.name
        }
        this.deletecarParkDialog = true;
    }

    confirmDeleteIpAddress(data: any): void {
        this.ipAddressDelete = {
            id: data.id,
            //name: data.building.name
        }
        this.deleteIpAddressDialog = true;
    }

    confirmDeleteShuttle(data: any): void {
        this.shuttleDelete = {
            id: data.id,
            routeName : data.routeName
            //name: data.building.name
        }
        this.deleteshuttleDialog = true;
    }

    confirmDeleteTitle(data: any): void {
        this.titleDelete = {
            id: data.id,
            name: data.name
        }
        this.deleteTitleDialog = true;
    }

    confirmDeleteDepartment(data: any): void {
        this.departmentDelete = {
            id: data.id,
            name: data.name
        }
        this.departmentDeleteDialog = true;
    }

    confirmDeleteAmenities(data: any) {
        this.amenitiesDelete = { ...data }
        this.amenitiesDeleteDialog = true;
    }

    confirmDeleteHoliday(data: any): void {
        this.holidayDelete = {
            id: data.id,
            name: data.name
        }
        this.deleteholidayDialog = true;
    }

    confirmDeletePolicy(data: any): void {
        this.policyDelete = {
            id: data.id,
            name: data.fileNameForBrowser
        }
        this.deletePolicyDialog = true;
    }

    /* Confirm Functions (End) */



    //File methods (Start)


    /* File methods --> Select file methods (Start)*/

    selectFile(event: any) {
        this.fileName = event.files[0].name;
        this.file.append('file', event.files[0]);
    }

    selectPolicyFile(event: any) {
        this.filePolicy = event.files[0].name;
        this.file.append('file', event.files[0]);
        this.selectFileVisible = false;
    }

    async selectDiningHallMenuFile(event: any) {
        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.diningHallMenuAdd.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.diningHallMenuAdd.fileBase64 = event.target.result
            this.diningHallMenuAdd.fileName = this.diningHallMenuAdd.file.name
        } 
        this.selectFileVisibleDiningHall = false;
    }

    async selectFileForRoomAdd(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.room.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.room.fileBase64 = event.target.result
            this.room.fileName = this.room.file.name.substring(0,this.room.file.name.lastIndexOf("."))
        } 
    }

    async selectFileForRoomEdit(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.roomEdit.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.roomEdit.fileBase64 = event.target.result
            this.roomEdit.fileName = this.roomEdit.file.name.substring(0,this.roomEdit.file.name.lastIndexOf("."))
        } 
    }

    async selectFileForFloorAdd(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.floor.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.floor.fileBase64 = event.target.result
            this.floor.fileName = this.floor.file.name.substring(0,this.floor.file.name.lastIndexOf("."))
        } 
    }

    async selectFileForFloorEdit(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.floorEdit.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.floorEdit.fileBase64 = event.target.result
            this.floorEdit.fileName = this.floorEdit.file.name.substring(0,this.floorEdit.file.name.lastIndexOf("."))

        } 
    }

    async selectFileForTempWorkingArea(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.workingArea.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

         reader.onload = (event:any) => {
            this.workingArea.fileBase64 = event.target.result
            this.workingArea.fileName = this.workingArea.file.name.substring(0,this.workingArea.file.name.lastIndexOf("."))
        }
    }

    async selectFileForTempWorkingAreaEdit(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.tempWorkingAreaEdit.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.tempWorkingAreaEdit.fileBase64 = event.target.result
            this.tempWorkingAreaEdit.fileName = this.tempWorkingAreaEdit.file.name.substring(0,this.tempWorkingAreaEdit.file.name.lastIndexOf("."))
        } 
    }

    async selectFileForWorkingAreaEdit(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.workingAreaEdit.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.workingAreaEdit.fileBase64 = event.target.result
            this.workingAreaEdit.fileName = this.workingAreaEdit.file.name.substring(0,this.workingAreaEdit.file.name.lastIndexOf("."))
        } 
    }

    async selectFileForWorkingAreaLaterAdd(event:any) {

        const file = event.files[0];
        const reader = new FileReader();
        const blob =  await fetch(file.objectURL).then((r:any) => r.blob()); //blob:url

        //----------
        const fileold = new FormData();
        fileold.append('file', event.files[0]);
        this.workingAreaLaterAdd.file= fileold.get('file')
        //-----------

        reader.readAsDataURL(blob);

        reader.onload = (event:any) => {
            this.workingAreaLaterAdd.fileBase64 = event.target.result
            this.workingAreaLaterAdd.fileName = this.workingAreaLaterAdd.file.name.substring(0,this.workingAreaLaterAdd.file.name.lastIndexOf("."))
        } 
    }

    /* File methods --> Select file methods (End)*/


    /* File methods --> Remove file methods (Start)*/


    async removeFileForForRoomAdd(){
        this.room.file = null
        this.room.fileBase64 = null
        this.room.fileName = null
    }

    async removeFileForForRoomEdit(){
        this.roomEdit.file = null
        this.roomEdit.fileBase64 = null
        this.roomEdit.fileName = null
    }

    async removeFileForForFloorAdd(){
        this.floor.file = null
        this.floor.fileBase64 = null
        this.floor.fileName = null
    }

    async removeFileForFloorEdit(){
        this.floorEdit.file = null
        this.floorEdit.fileBase64 = null
        this.floorEdit.fileName = null
    }

    async removeFileForTempWorkingArea(){
        this.workingArea.file = null
        this.workingArea.fileBase64 = null
        this.workingArea.fileName = null
    }

    async removeFileForTempWorkingAreaEdit(){
        this.tempWorkingAreaEdit.file = null
        this.tempWorkingAreaEdit.fileBase64 = null
        this.tempWorkingAreaEdit.fileName = null
    }

    async removeFileForWorkingAreaEdit(){
        this.workingAreaEdit.file = null
        this.workingAreaEdit.fileBase64 = null
        this.workingAreaEdit.fileName = null
    }

    async removeFileForWorkingAreaLaterAdd(){
        this.workingAreaLaterAdd.file = null
        this.workingAreaLaterAdd.fileBase64 = null
        this.workingAreaLaterAdd.fileName = null
    }

    /* File methods --> Remove file methods (End)*/


    //File methods (End)


        

    uploadPlanPng(){
        // ....
    }
    async uploadFile() {
        this.appStore.progressSpinnerShow = true

        await this.userApi.webapiUserAddUserlistPost(this.file.get('file'), this.apiHeaderForFile)
            .then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.removeUploadedFile();
                    this.listUsers();
                    this.listTitles();
                    this.listDepartments();
                }
                else {
                    this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: x.data.message, life: 3000 });
                }
            })
            .catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingUserList"), life: 3000 });
            });
        this.appStore.progressSpinnerShow = false

    }

    async uploadPolicyFile() {
        this.appStore.progressSpinnerShow = true

        await this.policyApi.webapiPolicyAddPolicyPost(this.file.get('file'), this.apiHeaderForFile)
            .then(x => {
                if (x.data.responseCode === ResponseCode.NUMBER_200) {
                    this.$toast.add({ severity: 'success', summary: this.$t("successful"), detail: x.data.message, life: 3000 });
                    this.removeUploadedPolicyFile();
                    this.listPolicyFile();
                }

            })
            .catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnAddingUserList"), life: 3000 });
            });
        this.appStore.progressSpinnerShow = false

    }

    removeUploadedFile() {
        this.file.delete('file');
        this.fileName = "";
    }

    removeUploadedPolicyFile() {
        this.file.delete('file');
        this.filePolicy =  "";
        this.selectFileVisible = true;
    }

    removeUploadedMenuFile() {
        this.file.delete('file');
        this.diningHallMenuFile =  "";
        this.diningHallMenuAdd.fileBase64 = null
        this.diningHallMenuAdd.fileName = null
        this.diningHallMenuAdd.file = null
        this.selectFileVisibleDiningHall = true;
    }

    removeUploadedPolicyFileAfterDeleting(){
        this.policy = null;
    }

    /* Change Functions */

    roomBuildingNameChange() {
        this.room.floor = null;
        this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.room.building.id, this.$apiHeader).then(x => this.roomFloors = x.data).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingFloors"), life: 3000 });
        });
    }

    roomEditBuildingNameChange() {
        const buildingId = this.roomEdit.floor.building.id
        //this.roomEdit.floor = null;
        this.floorApi.webapiFloorListByBuildingIdAsyncGet(buildingId, this.$apiHeader).then(x => {
            this.roomEditFloors = x.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingFloors"), life: 3000 });
        });
    }

    //
    // Calendar open and closed hours inputs change
    
    // calendarReservationOpenHoursInputChange(){
    //     this.calendar.openHours = this.calendar.openHours.toString().substring(16,21)
    // }

    // calendarReservationClosedHoursInputChange(){
    //     this.calendar.closedHours = this.calendar.closedHours.toString().substring(16,21)
    // }


    //
    //

    calendarHasNoEndingDateInputChange(){
        if(this.calendar.hasNoEndingDate == true){
            this.calendar.reservationEndDate = null
        }
    
    }

    calendarWorkingDaysMondayInputChange(){
        if(this.calendarWorkingDays.monday == true){
            this.calendar.workingDays.splice(0,0,1)
        }
        else{
            const index = this.calendar.workingDays.indexOf(1)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysTuesdayInputChange(){
        if(this.calendarWorkingDays.tuesday == true){
            this.calendar.workingDays.splice(1,0,2)
        }
        else{
            const index = this.calendar.workingDays.indexOf(2)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysWednesdayInputChange(){
        if(this.calendarWorkingDays.wednesday == true){
            this.calendar.workingDays.splice(2,0,3)
        }
        else{
            const index = this.calendar.workingDays.indexOf(3)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysThursdayInputChange(){
        if(this.calendarWorkingDays.thursday == true){
            this.calendar.workingDays.splice(3,0,4)
        }
        else{
            const index = this.calendar.workingDays.indexOf(4)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysFridayInputChange(){
        if(this.calendarWorkingDays.friday == true){
            this.calendar.workingDays.splice(4,0,5)
        }
        else{
            const index = this.calendar.workingDays.indexOf(5)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysSaturdayInputChange(){
        if(this.calendarWorkingDays.saturday == true){
            this.calendar.workingDays.splice(5,0,6)
        }
        else{
            const index = this.calendar.workingDays.indexOf(6)
            this.calendar.workingDays.splice(index,1)
        }
    }

    calendarWorkingDaysSundayInputChange(){
        if(this.calendarWorkingDays.sunday == true){
            this.calendar.workingDays.splice(6,0,7)
        }
        else{
            const index = this.calendar.workingDays.indexOf(7)
            this.calendar.workingDays.splice(index,1)
        }
    }

    carParkReportCheckboxValueChange(){
        if (this.carParkReport.sendTime) {
            this.carParkReport.isActive = !this.carParkReport.isActive
            this.saveCarParkReportRule()
        }
    }

    diningHallReportCheckboxValueChange(){
        if (this.diningHallReport.sendTime) {
            this.diningHallReport.isActive = !this.diningHallReport.isActive
            this.saveDiningHallReportRule()
        }
    }

    sendOnComingReservationCheckboxValueChange(){
        if (this.sendOnComingReservation.sendTime) {
            this.sendOnComingReservation.isActive = !this.sendOnComingReservation.isActive
            this.saveSendOnComingReservation()
        }else{
            this.sendOnComingReservation.isActive = !this.sendOnComingReservation.isActive
            this.saveSendOnComingReservation()
        }
    }

    floorAndWorkingAreaDeskFilterBuildingNameChange(){
        this.floorAndWorkingAreaDeskFilterFloors = []
        this.floorAndWorkingAreaDeskFilterWorkingAreas = []
        this.floorApi.webapiFloorListByBuildingIdAsyncGet(this.floorAndWorkingAreaDesksFilterDto.buildingId, this.$apiHeader).then(x => {
            this.floorAndWorkingAreaDeskFilterFloors = x.data;
        }).catch(() => {
            this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingFloors"), life: 3000 });
        });

        this.listFloorAndWorkingAreaDesks()
    }

    floorAndWorkingAreaDeskFilterFloorNameChange(){
        this.floorAndWorkingAreaDeskFilterWorkingAreas = []
        if(this.floorAndWorkingAreaDesksFilterDto.floorId != null){
            this.workingAreaApi.webapiWorkingAreaGetListByFloorIdAsyncGet(this.floorAndWorkingAreaDesksFilterDto.floorId, this.$apiHeader).then(x => {
                this.floorAndWorkingAreaDeskFilterWorkingAreas = x.data.data;
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: this.$t("error"), detail: this.$t("errorOnListingWorkingAreas"), life: 3000 });
            });
        }

        this.listFloorAndWorkingAreaDesks()
    }

    floorAndWorkingAreaDeskFilterWorkingAreaNameChange(){
        this.listFloorAndWorkingAreaDesks()
    }

    onUpload() {
        this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
    }
    /* Change Functions (End) */
    
    data() {
        return {
            value: null,
            value1: null,
            separatorExp:/,| /,
        }
        
    }
    
    addReceiverEmail(email:any) {
        //eslint-disable-next-line
        const regex =  /^(([^<>()[\]@\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(email.value.some((str: string) => regex.test(str) == false)){
            this.addReceiverEmailInvalid=true
        }
        else{
            this.addReceiverEmailInvalid=false
        }
    }
   
    addReceiverEmailDiningHall(email:any) {
        //eslint-disable-next-line
        const regex =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(email.value.some((str: string) => regex.test(str) == false)){
            this.addReceiverEmailInvalidDiningHall=true
        }
        else{
            this.addReceiverEmailInvalidDiningHall=false
        }
    }
    userListExporttoExcel() {
       if(this.users.length != 0){
        const tempUsers = this.users.map((a: any) => ({...a}));
        tempUsers.forEach((element: any) => {
            element.titleName = element.title.name
            element.departmantName = element.department.name
            delete element["title"]
            delete element["department"]
            delete element["id"]
        });

        if(this.$i18n.locale == 'en')
            {
                const headers = [['Name','Surname','Email','Phone Number','Employee Id','Is Admin','Title','Department']]
                this.forceFileDownload("User List", tempUsers, headers)
              
            }else{
                const headers = [['Ad','Soyad','Email','Telefon Numarası','Personel Id','Admin Durumu','Başlık','Departman']]
                this.forceFileDownload("Kullanıcı Listesi", tempUsers, headers)
            }  
       
       } else{
        this.$toast.add({severity:'error', summary: 'Error', detail: "Excel cannot be downloaded because the users is not generated.", life: 4000})
    }
     
    }
    
    forceFileDownload(name:string, dataParameter:any, excelHeaders:any) {
        const tempExcel = XLSX.utils.book_new()

        tempExcel.Props = {
            Title: name,
            Subject: "Report",
            Author: "Doğuş Teknoloji",
            CreatedDate: new Date() 
        };
        tempExcel.SheetNames.push(name);

        const stringData = JSON.stringify(dataParameter);   
        const data = JSON.parse(stringData);
        const dataSheet = XLSX.utils.json_to_sheet(data);

        XLSX.utils.sheet_add_aoa(dataSheet, excelHeaders);

        tempExcel.Sheets[name] = dataSheet;

        const excel = XLSX.write(tempExcel, {bookType:'xlsx', type:'binary'})
        const excelBlob = new Blob([this.charConverterToOctet(excel)],{type:"application/octet-stream"})

        const url = URL.createObjectURL(excelBlob);
        const link = document.createElement("a");
        const createdDate = new Date
        //const createdDateForExcelName = createdDate.getDay()+"."+createdDate.getMonth()+"."+createdDate.getFullYear()+" "+ createdDate.getHours()+":"+createdDate.getMinutes()+":"+createdDate.getSeconds()
        const createdDateForExcelName = this.customDateString(createdDate) + ":" + createdDate.getSeconds()

        link.setAttribute("href", url);
        link.setAttribute("download", name+" - "+createdDateForExcelName+".xlsx");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    charConverterToOctet(s:any) { 
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;    
      }
      getTranslatedMultipleReservationDaySettings() {
        const language = this.getCurrentLanguage();
        return language === 'en' ? this.multipleReservationDaySettingsEn : this.multipleReservationDaySettingsTr;
    }

   /* 
    validateEmailRules() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
            this.email = 'Please enter a valid email address';
        } else {
            this.email = '';
        }
    }
     */
    /*findIndexById(id: any) {
        let index = -1;
        for (let i = 0; i < this.buildings.length; i++) {
            if (this.buildings[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }*/
    
    // upload image for room and floor
}
